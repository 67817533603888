export interface routerType {
    title: string;
    path: string;
    element: JSX.Element;
}

export enum FetchState {
    DEFAULT = "DEFAULT",
    LOADING = "LOADING",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
}

export type RecallData = {
    recallId: {
        recallId: string;
        vin: string;
    };
    claim: {
        claimId: number;
        dealer: {
            dealerId: string;
            dealerName: string;
            address: string;
        };
        vehicle: {
            vin: string;
            mileage: number;
        };
        creationDate: string;
        lastModifiedDate: string;
        claimStatus: string;
        order: {
            orderNo: number;
            orderDescription: string;
            creationDate: string;
            lastModifiedDate: string;
            orderStatus: string;
            orderPart: {
                id: number;
                part: {
                    partNo: string;
                    partDescription: string;
                };
                count: number;
            }[];
        };
    };
    issueDescription: string;
    repairSteps: string[]; // Update the type to string[]
    instructionLink: string;
    softwareUpdateDesc: string;
    creationDate: string;
    lastModifiedDate: string;
    recallStatus: string;
    recallPart: {
        id: number;
        part: {
            partNo: string;
            partDescription: string;
        };
        count: number;
    }[];
};

export interface StatusFilterProps {
    filterStatus: string;
    handleStatusFilter: (status: string) => void;
    showStatusOptions: boolean;
    setShowStatusOptions: (show: boolean) => void;
}

export interface DateFilterProps {
    filterDates: (Date | null)[];
    handleDateFilter: (ranges: any) => void;
    clearDateFilter: () => void;
    openCalendar: () => void;
    closeCalendar: () => void;
    showCalendar: boolean;
    addMargin?: boolean;

}

export type CreateClaimRequest = {
    dealer: Dealer;
    recalls: { recallId: string }[];
    claimStatus: string;
    order?: Order;
    payment?: Payment;
    communication: string;
    repairSteps: any;
};

export type PartInput = {
    partNo: string;
    count: string;
    partDescription: string;
};

export type RecallInput = {
    dealerName: string;
    address: string;
    vin: string;
    modelYear: string;
    mileage: string;
    recallId: string;
    parts: PartInput[];
};

export interface Reimbursement {
    payment: Payment;
    communication: Communication | null;
}

export interface Payment {
    paymentId:string;
    lop: string;
    amountToReclaim: number;
    workOrderNumber: string;
    billableHours: number;
    paintCost:number;
    additionalInfo: string;
    requireInvoiceRefund: boolean;
    attachment: File[]; // Updated type
    labourCharged:string;
    invoiceNumber:string;
    dateOfCompletion:string;
    usedPart:UsedPart[];
}
export type PaymentOfRecall = {
    paymentId: number;
    lop: string;
    amountToReclaim: number;
    labourCharged: number;
};


export type Dealer = {
    dealerId: string;
    dealerName: string;
    address: string;
    city: string;
    countryCode: string;
    hourlyRate:string;
};

export type RecallId = {
    recallId: string;
    vin: string;
};

export type Vehicle = {
    vin: string;
    mileage: number; // You may want to consider using a number type for mileage
    model: string;
    modelYear: string;
    brand: string | null;
    vehicleDesc: string;
};
export type Recall = {
    dealerName: string;
    address: string;
    recallId: RecallId;
    modelYear: string;
    mileage: string;
    model: string;
    brand: string;
    recallDescription: string;
    vin: string;
    vehicle: {
        vin: string;
        mileage: number;
        model: string;
        modelYear: string;
        brand: string;
        vehicleDesc: string;
    };
    issueDescription: string;
    repairSteps?: string; // Add repairSteps field, making it optional with '?'
    instructionLink: string;
    softwareUpdateDesc: string;
    creationDate: string;
    lastModifiedDate: string;
    recallStatus: string;
    recallPart: any[]; // Consider replacing 'any' with a more specific type if known
    payment:PaymentOfRecall;
    order: Order;
};

export type OrderPart = {
    id: number;
    part: {
        partNo: string;
        partDescription: string;
    };
    count: number;
};

export type Order = {
    orderNo: number;
    orderDescription: string;
    orderPart: OrderPart[];
};
export type Claim = {
    claimId: string;
    dealer: Dealer;
    recalls: Recall[];
    creationDate: string;
    lastModifiedDate: string;
    claimStatus: string;
    order: Order2;
    payment: Payment;
    customClaimId:string;
    agentInformation: agentInformation;
    communication: Communication[];

};
export type Order2 = {
    orderNo: number;
    orderDescription: string;
    orderPart: OrderPart[];
    creationDate: string;
    lastModifiedDate: string;
    orderStatus: string;
    sapOrderNumber: string;
    sapOrderEnteredDate: string;
    partsOrderRemarks: string;
};

export type Part = {
    partNo: string;
    partDescription: string;
};

export interface UsedPart {
    part: {
        partNo: string;
        partDescription: string;
    };
    count: number;
}

export type Communication = {
    messageId: number;
    message: string;
    messageBy: string;
};

export type agentInformation = {
    "infoId": 0,
    "claimReceivedDate": string;
    "dateClaimEntered": string;
    "totalPartCost": number;
    "hourlyRate": string;
    "amountBilled": number;
    "returnFca": number;
    "returnFcaWoPart": number;
    "remarks": string;
    "underReview":boolean;
}


export type recallByVINData = {
    recallId: {
        recallId: string;
        vin: string;
    };
    claim: {
        claimId: number;
        dealer: {
            dealerId: string;
            dealerName: string;
            address: string;
        };
        vehicle: {
            vin: string;
            mileage: number;
        };
        creationDate: string;
        lastModifiedDate: string;
        claimStatus: string;
        order: {
            orderNo: number;
            orderDescription: string;
            creationDate: string;
            lastModifiedDate: string;
            orderStatus: string;
            orderPart: {
                id: number;
                part: {
                    partNo: string;
                    partDescription: string;
                };
                count: number;
            }[];
        };
    };
    issueDescription: string;
    repairSteps: string;
    instructionLink: string;
    softwareUpdateDesc: string;
    creationDate: string;
    lastModifiedDate: string;
    recallStatus: string;
    recallPart: {
        id: number;
        part: {
            partNo: string;
            partDescription: string;
        };
        count: number;
    }[];
};


export interface ClaimSubmitData {
    dealer: {
        dealerId: string;
        dealerName: string;
        address: string;
        city: string;
        countryCode: string;
    };
    recalls: {
        recallId?: { recallId: string; vin: string };
        vehicle: {
            vin: string;
            mileage: string;
            model: string;
            modelYear: string;
            brand: string | null;
            vehicleDesc: string;
        };
        recallDescription: string;
    }[];
    order?: {
        orderDescription: string;
        orderPart: {
            part: {
                partNo: string;
                partDescription: string;
            };
            count: number;
        }[];
    };
}

// Maybe in a types.ts or similar file
export interface ApiResponse {
    data: any; // Or be more specific if you know the structure
    status: number;
}



export type CreditMemosClaims = {
    dealerId: string;
    dealerName: string;
    totalReimbursementAmount: string;

};
export type CreditMemosDetails = {
    claimId: number;
    vin: string;
    recallId: string;
    dealerName: string;
    reimbursementAmount: number;
    dateOfCreation: string;

};