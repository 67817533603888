import React from "react";
import { useParams } from "react-router-dom";
import TSOCreditMemosTab from "../TSOCreditMemosTab";
import TSOReportTab from "../TSOReportTab";
const TSOCreditMemosPage = () => {
    const { claimId } = useParams();
    const [openTab, setOpenTab] = React.useState(1);

    return (
        <>
            <div className="container dark:bg-gray-800 flex flex-wrap">
                <div className="w-full">
                    <ul
                        className=" w-2/5 flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
                        role="tablist"
                    >
                        <button
                            className="-mb-px mr-2 last:mr-0 flex-auto text-center"
                            onClick={() => {
                                setOpenTab(1);
                            }}
                            role="tablist"
                        >
                            <div
                                className={
                                    " w-full text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                                    (openTab === 1
                                            ? "inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                                            : "inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                                    )
                                }
                            >

                                Credit Memos
                            </div>
                        </button>
                    </ul>
                    <div className=" relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                        <div className="px-4 py-5 flex-auto dark:bg-gray-800">
                            <div className=" tab-content tab-space">
                                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                                    <TSOCreditMemosTab/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default function TabsRender() {
    return (
        <>
            <TSOCreditMemosPage />
        </>
    );
}
