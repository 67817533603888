// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import "../../../App.css";
// import api from "../../../api";
//
// type UserData = {
//     username: string;
//     name: string;
//     companyName: string;
//     role: string;
//     city:string;
//     address:string;
// }
//
// function Home() {
//     const [userData, setUserData] = useState<UserData | null>(null);
//
//     useEffect(() => {
//         // Directly specify the token
//         // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImQud2lqZWd1bmFyYXRobmFAYWVjc29sdXRpb25zLmNvbSIsInR5cGUiOiJhdXRvcG9ydCIsImNvbXBhbnlOYW1lIjoiQW1lZXJpa2EgQXV0b3RlZW5pbmR1c2UgT1UiLCJleHBpcmVzIjoiMzAuOC4yMDIzIDEzOjE4OjE1IiwibmFtZSI6ImRpbXV0aHUiLCJjdXN0b21lckFkZHJlc3MiOiJTb3BydXNlIFBzdC4gMjcsVGFsbGlubiwxMDYxNSIsImNvdW50cnlDb2RlIjoiRVNUIiwiaWF0IjoxNzkzMzA3ODk1LCJleHAiOjE3OTMzOTQyOTV9.mbplbBugWufSqeYzGAawYhdU_Z1p-vd7mYcZC-e3KVc";  // Your token
//         const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InRlc3RkZWFsZXJAYWVjc29sdXRpb25zLmNvbSIsInR5cGUiOiJkZWFsZXJwb3J0IiwiY29tcGFueU5hbWUiOiJHbGFzbWFuIiwiZXhwaXJlcyI6IjMxLjguMjAyMyAxNDoyODowNyIsIm5hbWUiOiJ0ZXN0ZGVhbGVyIiwiY3VzdG9tZXJBZGRyZXNzIjoiRW50ZXIgYmlsbGluZyBzdHJlZXQsRW50ZXIgYmlsbGluZyBjaXR5LEVudGVyIGJpbGxpbmcgemlwIiwiY291bnRyeUNvZGUiOiJEIiwiaWF0IjoxNzkzMzk4NDg3LCJleHAiOjE3OTM0ODQ4ODd9.OGGDHV3JoxyLijLlnXP8HPUo3Tq9KMejhQ-0h6tqQWs";  // Your token
//         // Save the token to local storage
//         localStorage.setItem("bearerToken", token);
//
//         api.post(
//             "/auth/authorizeUser",
//             {},
//             {
//                 headers: {
//                     "Authorization": `Bearer ${token}`,
//                     "Content-Type": "application/json"
//                 }
//             }
//         )
//             .then(response => {
//                 console.log("Response from API:", response.data);
//                 setUserData(response.data); // Assuming the response data contains user details
//
//                 // Save user data to local storage
//                 localStorage.setItem("userData", JSON.stringify(response.data));
//
//             })
//             .catch(error => {
//                 console.error("Error fetching user data:", error);
//
//                 // Log the complete error object
//                 console.error("Complete error object:", error);
//             });
//
//     }, []);


// import React, { useState, useEffect } from "react";
// import "../../../App.css";
// import api from "../../../api";
//
//     type UserData = {
//         username: string;
//         name: string;
//         companyName: string;
//         role: string;
//         city: string;
//         address: string;
//     };
//
//     function Home() {
//         const [userData, setUserData] = useState<UserData | null>(null);
//
//         useEffect(() => {
//             // Retrieve the token from the URL parameter
//             const urlParams = new URLSearchParams(window.location.search);
//             const token = urlParams.get("token");
//
//             if (token) {
//                 // Save the token to local storage
//                 localStorage.setItem("bearerToken", token);
//
//                 api
//                     .post(
//                         "/auth/authorizeUser",
//                         {},
//                         {
//                             headers: {
//                                 "Authorization": `Bearer ${token}`,
//                                 "Content-Type": "application/json",
//                             },
//                         }
//                     )
//                     .then((response) => {
//                         console.log("Response from API:", response.data);
//                         setUserData(response.data);
//
//                         // Save user data to local storage
//                         localStorage.setItem("userData", JSON.stringify(response.data));
//                     })
//                     .catch((error) => {
//                         console.error("Error fetching user data:", error);
//                         console.error("Complete error object:", error);
//                     });
//             }
//         }, []);
//
//
//     return (
//         <div className="bg-white-900 h-screen flex items-center justify-center">
//             <header className="text-center">
//                 <p className="text-red-600 text-4xl font-bold mb-8">
//                     Welcome to <code className="text-black dark:text-white">TSO Recall Project </code> {userData?.name}.
//                 </p>
//                 <div className="mt-8 space-y-3">
//                     <p className="text-black text-xl dark:text-white">
//                         User Name: <span className="font-semibold">{userData?.username}</span>
//                     </p>
//                     <p className="text-black text-xl dark:text-white">
//                         Name: <span className="font-semibold">{userData?.name}</span>
//                     </p>
//                     <p className="text-black text-xl dark:text-white">
//                         Company Name: <span className="font-semibold">{userData?.companyName}</span>
//                     </p>
//                     <p className="text-black text-xl dark:text-white">
//                         Address: <span className="font-semibold">{userData?.address}</span>
//                     </p>
//                     <p className="text-black text-xl dark:text-white">
//                         City: <span className="font-semibold">{userData?.city}</span>
//                     </p>
//                 </div>
//             </header>
//         </div>
//
//     );
// }
//
//
// export default Home;

import React, { useState, useEffect } from "react";
import "../../../App.css";
import api from "../../../api";


type UserData = {
    username: string;
    name: string;
    companyName: string;
    role: string;
    city: string;
    address: string;
};

function Home() {
    const [userData, setUserData] = useState<UserData | null>(null);
    const [sessionExpired, setSessionExpired] = useState(false);

    useEffect(() => {
        // Check if the token is already stored in localStorage
        const storedToken = localStorage.getItem("bearerToken");

        if (storedToken) {
            // Token exists in localStorage, fetch user data directly
            fetchUserData(storedToken);
        } else {
            // Token doesn't exist in localStorage, retrieve it from the URL parameter
            const urlParams = new URLSearchParams(window.location.search);
            const jwt = urlParams.get("jwt");

            if (jwt) {
                // Save the token to local storage
                localStorage.setItem("bearerToken", jwt);
                // Remove the token from the URL
                urlParams.delete("jwt");
                const newUrl = `${window.location.pathname}${urlParams.toString()}`;
                window.history.replaceState({}, document.title, newUrl);
                // Fetch user data using the token
                fetchUserData(jwt);
                // Fetch user data using the token
                // Refresh the page

            } else {
                // Handle the case where there's no token
                handleNoToken();
            }
        }
    }, []);

    const fetchUserData = (jwt: string) => {
        api
            .post(
                "/auth/authorizeUser",
                {},
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((response) => {
                console.log("Response from API:", response.data);
                setUserData(response.data);
                const previousUserData = JSON.parse(localStorage.getItem("userData") || "{}");

                // Save user data to local storage
                localStorage.setItem("userData", JSON.stringify(response.data));

                // Update userData state
                setUserData(response.data);
                // Refresh the page
                // Check if user data has changed before refreshing
                if (JSON.stringify(response.data) !== JSON.stringify(previousUserData)) {
                    // Refresh the page only when user data has changed
                    window.location.reload();
                }
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
                console.error("Complete error object:", error);

                // Set sessionExpired to true when there's an error
                setSessionExpired(true);

                // Handle the error and redirect after 3 seconds
                handleNoToken();
            });


    };

    const handleNoToken = () => {
        // After 3 seconds, redirect to the login page
        // Clear local storage
        localStorage.removeItem("bearerToken");
        localStorage.removeItem("userData");
        setTimeout(() => {
            window.location.href = "https://autoport24.net/";
        }, 3000);
    };
    if (sessionExpired) {
        return (
            <div className="overlay">
                <div className="bg-white-900 h-screen flex items-center justify-center">
                    <header className="text-center">
                        <p className="text-red-600 text-4xl font-bold mb-8">
                            Your session has ended.
                        </p>
                        <p className="text-black text-xl dark:text-white">
                            Redirecting to the login page...
                        </p>
                    </header>
                </div>
            </div>
        );
    }

    return (
        <div className="bg-white-900 h-screen flex items-center justify-center">
            <header className="text-center">
                <p className="text-red-600 text-4xl font-bold mb-8">
                    Welcome to <code className="text-black dark:text-white">TSO Recall Portal </code> {userData?.name}.
                </p>
                <div className="mt-8 space-y-3">
                    <p className="text-black text-xl dark:text-white">
                        User Name: <span className="font-semibold">{userData?.username}</span>
                    </p>
                    <p className="text-black text-xl dark:text-white">
                        Name: <span className="font-semibold">{userData?.name}</span>
                    </p>
                </div>
            </header>
        </div>
    );
}

export default Home;