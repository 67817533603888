import React, {useEffect, useRef, useState} from "react";
import { useGetClaimInfo } from "../../../lib/api-hooks";
import {  FetchState } from "../../../types";
import api from "../../../api";
import {t} from "i18next";
import "../../../App.css";
import JSZip from "jszip";

interface Message {
    messageId?: number;
    messageBy: string;
    message: string;
}

interface UserData {
    username: string;
    role: string;
    companyName:string;
}
const ClaimPageInfoTabComponent = () => {
    const [claimInfo, fetchState] = useGetClaimInfo();
    const [supportInput, setSupportInput] = useState("");
    const isDarkMode = false;
    const [messages, setMessages] = useState<Message[]>([]);
    const [newMessage, setNewMessage] = useState("");
    const [userData, setUserData] = useState<UserData | null>(null);
    const chatContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        // Simulate loading user data from local storage (replace with actual implementation)
        const userDataString = localStorage.getItem("userData");
        const parsedUserData = userDataString ? JSON.parse(userDataString) : null;
        setUserData(parsedUserData);
    }, []);

    const sortMessagesByMessageId = (messages: Message[]) => {
        return messages.slice().sort((a, b) => (a.messageId ?? 0) - (b.messageId ?? 0));
    };


    useEffect(() => {
        // Whenever messages change, clear the input field
        setNewMessage("");
    }, [messages]);

    // Initialize messages from claimInfo.communication when claimInfo changes
    useEffect(() => {
        if (claimInfo && claimInfo.communication) {
            const formattedMessages: Message[] = claimInfo.communication.map((communication) => ({
                messageId: communication.messageId,
                messageBy: communication.messageBy,
                message: communication.message,
            }));
            const sortedMessages = sortMessagesByMessageId(formattedMessages);
            setMessages(sortedMessages);
        }
    }, [claimInfo]);

    // Function to scroll to the bottom of the chat container
    const scrollToBottom = () => {
        // Check if chatContainerRef.current is defined
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTo(0, chatContainerRef.current.scrollHeight);
        }
    };

    // useEffect to scroll to the bottom whenever a new message is added
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const sendMessage = () => {
        if (!userData || !claimInfo || !newMessage) {
            // Handle the case where user data, claimInfo, or newMessage is not available
            return;
        }

        // Create a message object using the userData and newMessage
        const newMessageData = {
            messageBy: userData.role,
            message: newMessage,
        };

        // Update the messages state immediately with the new message
        setMessages((prevMessages) => [...prevMessages, newMessageData]);

        // Make a PUT request to post the message with the specific claimId
        api
            .put(`/claim/message/${claimInfo?.claimId}`, newMessageData)
            .then((response) => {
                console.log("API response:", response); // Log the API response
                setNewMessage("");

                // Check if the API response contains updated message data and update the state accordingly
                if (response.data && response.data.messageId) {
                    // Find the index of the newly added message based on its content
                    const newMessageIndex = messages.findIndex(
                        (message) => message.message === newMessageData.message
                    );

                    if (newMessageIndex !== -1) {
                        // Create the updated message object with the actual messageId
                        const updatedMessage = {
                            messageId: response.data.messageId,
                            messageBy: newMessageData.messageBy,
                            message: newMessageData.message,
                        };

                        // Replace the temporary message with the actual messageId in the messages state
                        const updatedMessages = [...messages];
                        updatedMessages[newMessageIndex] = updatedMessage;

                        // Update the messages state with the updated message
                        setMessages(updatedMessages);
                    }
                }
            })
            .catch((error) => {
                console.error("API error:", error);
                // Handle the error if needed
            });
    };
    const handleSupportSubmit = () => {
        console.log("Support field submitted:", supportInput);
        setSupportInput("");
    };    const getStatusBadge = (status: any) => {
        if (status === "OPEN") {
            return (
                <span className="inline-flex items-center justify-center bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300 w-22">
          <span className="w-2 h-2 mr-2 bg-green-500 rounded-full"></span>
          Open
        </span>
            );
        } else if (status === "CLOSED") {
            return (
                <span className="inline-flex items-center justify-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300 w-22">
          <span className="w-2 h-2 mr-2 bg-red-500 rounded-full"></span>
          Closed
        </span>
            );
        } else if (status === "DRAFT") {
            return (
                <span className="inline-flex items-center justify-center bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300 w-22">
          <span className="w-2 h-2 mr-2 bg-blue-500 rounded-full"></span>
          Draft
        </span>
            );
        } else if (status === "PARTSREQUIRED") {
            return (
                <span className="inline-flex items-center justify-center bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300 w-22">
          <span className="w-2 h-2 mr-2 bg-yellow-500 rounded-full"></span>
          Parts Required
        </span>
            );
        } else if (status === "PARTSORDERED") {
            return (
                <span className="inline-flex items-center justify-center bg-orange-100 text-orange-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300 w-22">
          <span className="w-2 h-2 mr-2 bg-yellow-500 rounded-full"></span>
          Parts Ordered
        </span>
            );
        } else if (status === "INPROGRESS") {
            return (
                <span className="inline-flex items-center justify-center bg-purple-100 text-purple-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-purple-300 w-22">
          <span className="w-2 h-2 mr-2 bg-purple-500 rounded-full"></span>
          In Progress
        </span>
            );
        }

        return null;
    };

    return (
        <>
            <div className="lg:container container mx-auto mt-10 dark:bg-gray-800 flex flex-wrap">
                {fetchState === FetchState.LOADING && <p>Loading...</p>}
                {fetchState === FetchState.ERROR && <p>Error fetching claim information.</p>}
                {fetchState === FetchState.SUCCESS && (
                    <div className="w-full">
                        <div className="lg:container mx-auto dark:bg-gray-800 flex justify-end flex-col">
                            <div className="flex mb-8">
                                <h2 className="mr-5 font-bold">Claim#{claimInfo?.claimId}</h2>
                                <div className="ml-auto">{getStatusBadge(claimInfo?.claimStatus)}</div>
                            </div>
                            <div className="bg-white dark:bg-gray-800">
                                <div className="flex mt-4 mb-8">
                                    <h2 className="text-xl  mr-2"> {t("claim-info.general-info")} </h2>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="blue" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                                    </svg>
                                </div>
                                <div className="flex flex-wrap justify-center w-full">
                                    <div className="w-full px-3 mb-8 ">
                                        <div className="bg-white dark:bg-gray-800 rounded-lg p-4">
                                            <div className="flex flex-wrap ">
                                                <div className="w-full text-end pr-6 md:w-1/2">
                                                    <h3 className="text-l dark:text-white text-gray-500 mb-2"> {t("claim-info.brand-name")} :</h3>
                                                </div>
                                                <div className="w-full md:w-1/2">
                                                    <p className="text-black dark:text-white">{claimInfo?.recalls[0]?.vehicle?.brand}</p>
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap">
                                                <div className="w-full md:w-1/2">
                                                    <h3 className="text-l text-end pr-6 dark:text-white text-gray-500 mb-2">{t("order-parts.dealer-name")}:</h3>
                                                </div>
                                                <div className="w-full md:w-1/2">
                                                    <p className="text-black dark:text-white">{claimInfo?.dealer.dealerName}</p>
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap">
                                                <div className="w-full md:w-1/2">
                                                    <h3 className="text-l text-end pr-6 dark:text-white text-gray-500 mb-2">  {t("order-parts.dealer-address")}:</h3>
                                                </div>
                                                <div className="w-full md:w-1/2">
                                                    <p className="text-black dark:text-white">{claimInfo?.dealer.address}</p>
                                                </div>
                                            </div>

                                            {claimInfo?.recalls?.length === 0 ? (
                                                <div className="flex justify-center items-center mt-2 mb-8">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="lightblue" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                                    </svg>
                                                    <p className="ml-4 dark:text-white text-center"> {t("claim-info.recall-not-found")} </p>
                                                </div>
                                            ) : (
                                                <>
                                                    <div>
                                                        <div className="flex flex-wrap">
                                                            <div className="w-full md:w-1/2">
                                                                <h3 className="dark:text-white text-l text-end pr-6 text-gray-500 mb-2">VIN:</h3>
                                                            </div>
                                                            <div className="w-full md:w-1/2">
                                                                <p className="text-black dark:text-white">{claimInfo?.recalls[0]?.recallId?.vin}</p>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-wrap">
                                                            <div className="w-full text-end pr-6 md:w-1/2">
                                                                <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.model")}:</h3>
                                                            </div>
                                                            <div className="w-full md:w-1/2">
                                                                <p className="dark:text-white text-black">{claimInfo?.recalls[0]?.vehicle?.model}</p>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-wrap">
                                                            <div className="w-full md:w-1/2">
                                                                <h3 className="dark:text-white text-l text-end pr-6  text-gray-500 mb-2">{t("claim-info.model-year")}:</h3>
                                                            </div>
                                                            <div className="w-full md:w-1/2">
                                                                <p className="dark:text-white text-black ">{claimInfo?.recalls[0]?.vehicle?.modelYear}</p>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-wrap">
                                                            <div className="w-full md:w-1/2">
                                                                <h3 className="dark:text-white text-l text-end pr-6  text-gray-500 mb-2">{t("claim-info.millage")}:</h3>
                                                            </div>
                                                            <div className="w-full md:w-1/2">
                                                                <p className="dark:text-white text-black ">{claimInfo?.recalls[0]?.vehicle?.mileage} km</p>
                                                            </div>
                                                        </div>
                                                        {claimInfo?.recalls?.map((recall, index) => (
                                                            <div className="" key={index}>
                                                                <div className="flex flex-wrap" >
                                                                    <div className="w-full md:w-1/2">
                                                                        <h3 className="dark:text-white text-l text-end pr-6 text-gray-500 mb-2">{t("table.recall")} ID:</h3>
                                                                    </div>
                                                                    <div className="w-full md:w-1/2">
                                                                        <p className="text-black dark:text-white">{recall?.recallId?.recallId}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-wrap" key={index}>
                                                                    <div className="w-full md:w-1/2">
                                                                        <h3 className="dark:text-white text-l text-end pr-6 text-gray-500 mb-2">{t("claim-info.recall-desc")}:</h3>
                                                                    </div>
                                                                    <div className="w-full md:w-1/2">
                                                                        <p className="text-black dark:text-white">{recall?.recallDescription}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div className="flex flex-wrap mb-2">
                                                            <div className="w-full md:w-1/2">
                                                                <h3 className="text-l text-end pr-6 text-gray-500 mb-2 dark:text-white">{t("claim-info.attachment")}:</h3>
                                                            </div>
                                                            <div className="w-full md:w-1/2">
                                                                {claimInfo?.payment?.paymentId ? (
                                                                    claimInfo?.payment?.attachment.length > 0 ? (
                                                                        <button
                                                                            className={`flex mr-4 items-center w-fit px-4 py-1  ${
                                                                                isDarkMode
                                                                                    ? "bg-gray-800 border-white text-white"
                                                                                    : "bg-white border-gray-300 text-black dark:bg-gray-800 dark:border-white dark:text-white"
                                                                            } border rounded-md text-sm font-medium hover:bg-black hover:text-white`}
                                                                            onClick={async () => {
                                                                                try {
                                                                                    const response = await api.get(`/payment/${claimInfo.payment.paymentId}/download`, {
                                                                                        responseType: "blob",
                                                                                    });

                                                                                    // Check if the blob is empty
                                                                                    if (response.data.size === 0) {
                                                                                        console.error("Received an empty attachment.");
                                                                                        return;
                                                                                    }
                                                                                    const contentType = response.headers["content-type"];

                                                                                    if (contentType === "application/zip") {
                                                                                        JSZip.loadAsync(response.data).then(async (zip) => {
                                                                                            const zipEntries = Object.keys(zip.files);

                                                                                            if (zipEntries.length === 1) {
                                                                                                const firstFile = zip.file(zipEntries[0]);
                                                                                                if (firstFile !== null) { // Null check
                                                                                                    const content = await firstFile.async("blob");
                                                                                                    const url = URL.createObjectURL(content);
                                                                                                    const a = document.createElement("a");
                                                                                                    a.href = url;
                                                                                                    a.download = zipEntries[0];
                                                                                                    document.body.appendChild(a);
                                                                                                    a.click();
                                                                                                    document.body.removeChild(a);
                                                                                                    URL.revokeObjectURL(url);
                                                                                                }
                                                                                            } else {
                                                                                                const blob = new Blob([response.data], { type: contentType });
                                                                                                const url = URL.createObjectURL(blob);
                                                                                                const a = document.createElement("a");
                                                                                                a.href = url;
                                                                                                a.download = `attachments_${claimInfo.payment.paymentId}.zip`;
                                                                                                document.body.appendChild(a);
                                                                                                a.click();
                                                                                                document.body.removeChild(a);
                                                                                                URL.revokeObjectURL(url);
                                                                                            }
                                                                                        });
                                                                                    } else {
                                                                                        const blob = new Blob([response.data], { type: contentType });
                                                                                        const url = URL.createObjectURL(blob);
                                                                                        const a = document.createElement("a");
                                                                                        a.href = url;
                                                                                        // Remove the comment from the template string
                                                                                        a.download = `attachment_${claimInfo.payment.paymentId}`;
                                                                                        document.body.appendChild(a);
                                                                                        a.click();
                                                                                        document.body.removeChild(a);
                                                                                        URL.revokeObjectURL(url);
                                                                                    }
                                                                                } catch (error) {
                                                                                    console.error("Error downloading attachment:", error);
                                                                                }
                                                                            }}
                                                                        >
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                fill="none"
                                                                                viewBox="0 0 24 24"
                                                                                strokeWidth={1.5}
                                                                                stroke={isDarkMode ? "white" : "currentColor"}
                                                                                className="w-6 h-6 mr-2"
                                                                            >
                                                                                <path
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                    d="M12 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                                                                />
                                                                            </svg>
                                                                            <p>{t("claim-info.download-attachment")}</p>
                                                                        </button>

                                                                    ) : (
                                                                        <p>{t("claim-info.not-available")}</p>
                                                                    )
                                                                ) : (
                                                                    <p>{t("claim-info.not-available")}</p>
                                                                )}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white dark:bg-gray-800 ">
                                <div className="flex mt-4 mb-8">
                                    <h2 className="text-xl  mr-2">{t("claim-info.reimbursement-info")}</h2>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="orange" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                                    </svg>
                                </div>
                                <div className="flex flex-wrap justify-center w-full">
                                    <div className="w-full md:w-1/2 px-3 mb-8">
                                        <div className="bg-white dark:bg-gray-800 rounded-lg p-4">
                                            <div className="flex flex-wrap">

                                            </div>
                                            {claimInfo?.payment==null ? (
                                                <div className="flex flex-start mt-4 mb-8 items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="lightblue" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                                    </svg>
                                                    <p className="ml-4 text-left">{t("claim-info.reimbursement-not-found")}</p>
                                                </div>
                                            ) : (
                                                <>
                                                    <div>
                                                        <div className="flex flex-wrap">
                                                            <div className="w-full text-end pr-6 md:w-1/2">
                                                                <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.workorder-number")}:</h3>
                                                            </div>
                                                            <div className="w-full md:w-1/2">
                                                                <p className="text-black dark:text-white">{claimInfo?.payment?.workOrderNumber}</p>
                                                            </div>
                                                            <div className="w-full text-end pr-6 md:w-1/2">
                                                                <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.labor-charged")}:</h3>
                                                            </div>
                                                            <div className="w-full md:w-1/2">
                                                                <p className="text-black dark:text-white">{claimInfo?.payment?.labourCharged}</p>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-wrap">
                                                            <div className="w-full text-end pr-6 md:w-1/2">
                                                                <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.lop-code")}:</h3>
                                                            </div>
                                                            <div className="w-full md:w-1/2">
                                                                <p className="text-black dark:text-white">{claimInfo?.payment?.lop}</p>
                                                            </div>
                                                        </div>
                                                        {claimInfo?.payment?.paintCost ? (
                                                            <div className="flex flex-wrap">
                                                                <div className="w-full text-end pr-6 md:w-1/2">
                                                                    <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.cost-part-paiting")}:</h3>
                                                                </div>
                                                                <div className="w-full md:w-1/2">
                                                                    <p className="text-black dark:text-white">{claimInfo?.payment?.paintCost}</p>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            // You can optionally include a message or element here to indicate that the cost is not available.
                                                            <div className="flex flex-wrap">
                                                                <div className="w-full text-end pr-6 md:w-1/2">
                                                                    <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.cost-part-paiting")}:</h3>
                                                                </div>
                                                                <div className="w-full md:w-1/2">
                                                                    <p className="text-black dark:text-white">{t("claim-info.not-available")}</p>
                                                                </div>
                                                            </div>
                                                        )}

                                                        <div className="flex flex-wrap">
                                                            <div className="w-full text-end pr-6 md:w-1/2">
                                                                <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.date-of-recall-completion")}:</h3>
                                                            </div>
                                                            <div className="w-full md:w-1/2">
                                                                <p className="text-black dark:text-white">{claimInfo?.payment.dateOfCompletion}</p>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-wrap" >
                                                            <div className="w-full text-end pr-6 md:w-1/2">
                                                                <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.invoice-number")}:</h3>
                                                            </div>
                                                            <div className="w-full md:w-1/2">
                                                                <p className="text-black dark:text-white">{claimInfo?.payment?.invoiceNumber} </p>
                                                            </div>
                                                        </div>
                                                        {claimInfo?.payment?.usedPart?.map((usedPart:any, index) => (
                                                            <div className="" key={index}>
                                                                <div className="flex flex-wrap">
                                                                    <div className="w-full text-end pr-6 md:w-1/2">
                                                                        <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.part-used")}:</h3>
                                                                    </div>
                                                                    <div className="w-full md:w-1/2">
                                                                        <p className="text-black dark:text-white">{usedPart?.part.partNo}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-wrap" >
                                                                    <div className="w-full text-end pr-6 md:w-1/2">
                                                                        <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.part-number")}:</h3>
                                                                    </div>
                                                                    <div className="w-full md:w-1/2">
                                                                        <p className="text-black dark:text-white">{usedPart?.count}</p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white dark:bg-gray-800">
                                <div className="flex mt-4 mb-8">
                                    <h2 className="text-xl dark:text-white mr-2">{t("claim-info.parts")} </h2>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="green" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                                    </svg>
                                </div>
                                <div className="flex flex-wrap justify-center w-full">
                                    <div className="w-full md:w-1/2 px-3 mb-8">
                                        <div className="bg-white dark:bg-gray-800 rounded-lg p-4">
                                            {claimInfo?.order === null ? (
                                                <div className="flex mt-4 mb-8">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="lightblue" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                                    </svg>
                                                    <p className="ml-4 dark:text-white">{t("claim-info.part-not-found")}</p>
                                                </div>

                                            ) : (
                                                <>
                                                    <div>
                                                        {claimInfo?.order?.orderPart?.map((part, index) => (
                                                            <div className="" key={index}>
                                                                <div className="flex flex-wrap" >
                                                                    <div className="w-full text-end pr-6 md:w-1/2">
                                                                        <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.part-number")}:</h3>
                                                                    </div>
                                                                    <div className="w-full md:w-1/2">
                                                                        <p className="text-black dark:text-white">{part?.part.partNo}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-wrap" key={index}>
                                                                    <div className="w-full text-end pr-6 md:w-1/2">
                                                                        <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.part-quantity")}:</h3>
                                                                    </div>
                                                                    <div className="w-full md:w-1/2">
                                                                        <p className="text-black dark:text-white">{part?.count} </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                claimInfo?.claimStatus !== "CLOSED" && (
                                    <div className="bg-gray-50 p-4 pt-12 mt-8 mb-20 border dark:bg-gray-800 dark:border-white dark:text-white">
                                <h2 className="text-xl mr-2 pl-12">{t("claim-info.support")}</h2>
                                <div ref={chatContainerRef} className="mt-8 ml-8 mr-4 message-content" style={{ overflowY: "auto", overflowX: "auto" }}>
                                    {messages.map((message, index) => (
                                        <div
                                            key={message.messageId}
                                            className={`message shadow-lg  flex-col ${
                                                message.messageBy === "dealerport" ? "message-right" : "message-left"
                                            }`}
                                        >
                                            <div className="pr-2 pl-2">
                                                {/* Conditionally render different images */}
                                                {message.messageBy === "dealerport" ? (
                                                    <img
                                                        alt="Dealer-logo"
                                                        src="/assets/images/supportdealer.png"
                                                        className="pl-10 float-right ml-4 supportimg"
                                                        width={50} // Adjust the width as needed
                                                        height={50} // Adjust the height as needed
                                                    />
                                                ) : (
                                                    <img
                                                        alt="Agent-logo"
                                                        src="/assets/images/supportagent.png"
                                                        className="pl-10 mr-4 float-left supportimg"
                                                        width={50} // Adjust the width as needed
                                                        height={50} // Adjust the height as needed
                                                    />
                                                )}
                                                <div className="text-lg dark:text-black">
                                                    {message.messageBy === "dealerport"
                                                        ? userData?.companyName
                                                        : message.messageBy === userData?.username
                                                            ? userData?.companyName
                                                            : "TSO Agent"}
                                                </div>
                                                <div className="dark:text-black">{message.message}</div>
                                            </div>
                                        </div>

                                    ))}
                                </div>

                                <div className="ml-8 mr-18 mt-12">
        <textarea
            className="border dark:text-black rounded p-2 w-full border-gray-300 mt-4 dark:bg-gray-800 dark:border-white dark:text-white"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder= {String(t("claim-info.type-your-msg"))}

        />
                                    <button
                                        className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded mt-2"
                                        onClick={sendMessage}
                                    >
                                        {t("claim-info.send-msg")}
                                    </button>
                                </div>
                            </div>
                            )}
                        </div>
                    </div>
                )}


            </div>

        </>
    );
};

export default ClaimPageInfoTabComponent;


