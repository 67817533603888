import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en.json";
import translationDE from "./locales/de.json";

const resources = {
    en: {
        translation: translationEN,
    },
    de: {
        translation: translationDE,
    },
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resources,
        lng: localStorage.getItem("lang") || "en",
        fallbackLng: ["en", "de"],
        detection: {
            order: ["querystring", "cookie"],
            cache: ["cookie"],
        },
        debug: true,
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        react: {
            wait: true,
        },
    });

export default i18n;