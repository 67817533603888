import React, {useState, useEffect} from "react";
import {Table} from "flowbite-react";
import "../../../App.css";
import {useFetchClosedClaims, useDownloadMonthReport, useSendReportToFinance} from "../../../lib/api-hooks";
import {CreditMemosClaims, FetchState} from "../../../types";
import {useNavigate} from "react-router-dom";
import {t} from "i18next";
import api from "../../../api";

export default function TSOCreditMemosTab() {
    const [filterDates, setFilterDates] = useState<(Date | null)[]>([null, null]);
    const {claims, fetchState: componentFetchState} = useFetchClosedClaims();
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const isDarkMode = false;
    const [selectedClaimIds, setSelectedClaimIds] = useState<string[]>([]);
    const [initialSelectedClaimIds, setInitialSelectedClaimIds] = useState<string[]>([]);
    // const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedMonth, setSelectedMonth] = useState(() => {
        // Initialize with the current month and year
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        return `${year}-${month}`;
    });
    const {downloadMonthRaport, fetchState} = useDownloadMonthReport();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {sendReportToFinance, fetchState: sendReportFetchState} = useSendReportToFinance(); // Use the custom hook
    const [note, setNote] = useState("");
    const [isReportSent, setIsReportSent] = useState(false);
    const [isMessageVisible, setIsMessageVisible] = useState(true);
    const closeMessage = () => {
        setIsMessageVisible(false);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const [year, month] = selectedMonth.split("-");
    useEffect(() => {
        if (fetchState === FetchState.ERROR) {
            console.error("Error fetching claims.");
        } else {
            const defaultSelectedClaimIds = claims.map((claim) => claim.dealerId);
            setInitialSelectedClaimIds(defaultSelectedClaimIds);
            setSelectedClaimIds(defaultSelectedClaimIds);
        }
    }, [fetchState, claims]);

    const handleCellClick = (claim: CreditMemosClaims) => {
        const queryParams = new URLSearchParams();
        queryParams.append("dealerName", claim.dealerName);
        queryParams.append("totalReimbursementAmount", claim.totalReimbursementAmount.toString());
        const url = `/tso-claim-details/${claim.dealerId}?${queryParams.toString()}`;
        navigate(url);
    };

    const filteredCreditMemosClaims = claims.filter((claim: CreditMemosClaims) => {
        const dealerNameMatch = claim.dealerName.toLowerCase().includes(searchTerm.toLowerCase());

        if (searchTerm && !dealerNameMatch) {
            return false;
        }
        return true;
    });

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, claimId: string) => {
        e.stopPropagation();
        const isChecked = e.target.checked;

        setSelectedClaimIds((prevSelectedClaimIds) => {
            if (isChecked) {
                const newSelectedClaimIds = [...prevSelectedClaimIds, claimId];
                console.log("Selected Claim IDs:", newSelectedClaimIds);
                return newSelectedClaimIds;
            } else {
                const newSelectedClaimIds = prevSelectedClaimIds.filter((id) => id !== claimId);
                console.log("Selected Claim IDs:", newSelectedClaimIds);
                return newSelectedClaimIds;
            }
        });
    };
    const handleDownloadClick = async () => {
        try {
            if (!selectedMonth || selectedClaimIds.length === 0) {
                console.error("Please select a month and at least one claim.");
                return;
            }
            const [year, month] = selectedMonth.split("-");
            const requestBody = {
                dealerIds: selectedClaimIds,
                month: month,
                year: year,
                description: "Month Report",
            };

            const apiUrl = "/reimbursement/export"; // Use a relative URL
            const response = await api.post(apiUrl, requestBody);

            // Manually create a blob from the response data
            const blob = new Blob([response.data], {type: response.headers["content-type"]});

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            document.body.appendChild(a);
            a.click();

            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

            console.log("Month report downloaded successfully!");
        } catch (error) {
            console.error("Error downloading month report:", error);
        }
    };

    useEffect(() => {
        // Update selectedMonth to the current month and year when the component mounts
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        setSelectedMonth(`${year}-${month}`);
    }, []);

    const handleMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedMonth = event.target.value;
        setSelectedMonth(selectedMonth);
    };
    const handleSendReportClick = async () => {
        try {
            if (!selectedMonth || selectedClaimIds.length === 0) {
                console.error("Please select a month and at least one claim.");
                return;
            }

            const [year, month] = selectedMonth.split("-");

            // Create the request body
            const requestBody = {
                dealerIds: selectedClaimIds,
                month: parseInt(month, 10),
                year: parseInt(year, 10),
                text: note,
            };
            const apiUrl = "/email/send";
            const response = await api.post(apiUrl, requestBody);
            closeModal();
            setIsReportSent(true);
            setNote("");
            setTimeout(() => {
                setIsReportSent(false);
            }, 3000);
        } catch (error) {
            console.error("Error sending report:", error);
        }
    };

    return (
        <div className="lg:container mx-auto mt-10">
            <div className="flex justify-between mb-4">
                <div className="flex items-center">
                    <button
                        className={`flex mr-4 items-center w-fit px-4 py-2 ${
                            isDarkMode
                                ? "bg-gray-800 border-white text-white"
                                : "bg-white border-gray-300 text-black dark:bg-gray-800 dark:border-white dark:text-white"
                        } border rounded-md text-sm font-medium hover:bg-black hover:text-white`}
                        onClick={handleDownloadClick}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke={isDarkMode ? "white" : "currentColor"}
                            className="w-6 h-6 mr-2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                            />
                        </svg>
                        {t("credit-memos.download")}
                    </button>
                    <button
                        onClick={openModal}
                        data-modal-target="authentication-modal"
                        type="button"
                        data-modal-toggle="authentication-modal"
                        className={`flex mr-4 items-center w-fit px-4 py-2 ${
                            isDarkMode
                                ? "bg-gray-800 border-white text-white "
                                : "bg-white border-gray-300 text-black dark:bg-gray-800 dark:border-white dark:text-white"
                        } border rounded-md text-sm font-medium hover:bg-black hover:text-white `}
                        // disabled={!selectedMonth || selectedClaimIds.length === 0}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                             stroke="currentColor" className="w-6 h-6 mr-2">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"/>
                        </svg>
                        {t("credit-memos.send-report")}
                    </button>
                </div>
                <div className="flex items-start  w-1/2">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder= {String(t("credit-memos.search-by-dealer"))}
                        className={`flex mr-4 items-center w-fit px-4 py-2 ${
                            isDarkMode
                                ? "w-full bg-gray-800 border-white text-white"
                                : "w-full bg-white border-gray-300 text-black dark:bg-gray-800 dark:border-white dark:text-white"
                        } border rounded-md text-sm font-medium `}   />
                </div>
                <div className="relative month flex-end">
                    <input
                        type="month"
                        value={selectedMonth}
                        onChange={handleMonthChange}
                        className={`flex mr-4 items-center w-fit px-4 py-2 ${
                            isDarkMode
                                ? "bg-gray-800 border-white text-white hover:bg-black hover:text-white"
                                : "bg-white border-gray-300 text-black dark:bg-gray-800 dark:border-white dark:text-white"
                        } border rounded-md text-sm font-medium `}
                    />
                </div>

            </div>
            {isMessageVisible && (
                <div className="dark:text-white dark:bg-gray-900 dark:focus:bg-gray-800 dark:border-gray-600 dark:ring-gray-600 mt-7 mb-7 bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative"
                     role="alert">
                    <button
                        className="absolute top-0 right-0 px-2 py-1"
                        onClick={closeMessage}
                    >
                        <svg className="fill-current h-6 w-6 text-blue-500" role="button"
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 20 20"><title>  {t("buttons.cancel")}</title>
                            <path
                                d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
                        </svg>
                    </button>
                    <p className="font-bold">  {t("credit-memos.info-msg")}</p>
                    <p className="text-sm">  {t("credit-memos.info-msg-long")}</p>
                </div>
            )}
            <div className="overflow-x-auto">
                <Table hoverable className="border-0 shadow-sm">
                    <Table.Head>
                        <Table.HeadCell className="!p-4"></Table.HeadCell>
                        <Table.HeadCell className="text-center"></Table.HeadCell>
                        <Table.HeadCell className="text-center">{t("credit-memos.dealer")}</Table.HeadCell>
                        <Table.HeadCell className="text-center">{t("credit-memos.amount-of-reimbursemenet")}</Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {filterDates[0] && filteredCreditMemosClaims.length === 0 || claims.length ===0 ? (
                            <Table.Row>
                                <Table.Cell className="p-4 text-center" colSpan={9}>{t("credit-memos.no-claims-found")}</Table.Cell>
                            </Table.Row>
                        ) : (
                            filteredCreditMemosClaims.map((claim: CreditMemosClaims) => (
                                <Table.Row
                                    key={claim.dealerId}
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                    <Table.Cell className="!p-4"></Table.Cell>
                                    <Table.Cell>
                                        <div className="flex items-center pl-3">
                                            <input
                                                id="vue-checkbox"
                                                type="checkbox"
                                                value=""
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                                checked={selectedClaimIds.includes(claim.dealerId)}
                                                onChange={(e) => handleCheckboxChange(e, claim.dealerId)}
                                            />
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell className="p-0 text-center">
                                        <div onClick={() => handleCellClick(claim)}>{claim.dealerName}</div>
                                    </Table.Cell>
                                    <Table.Cell className="p-0 text-center">
                                        <div
                                            onClick={() => handleCellClick(claim)}>{claim.totalReimbursementAmount}</div>
                                    </Table.Cell>
                                </Table.Row>
                            ))
                        )}
                    </Table.Body>
                </Table>
            </div>

            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    {/* Modal */}
                    <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg p-8 w-2/5">
                        <button
                            onClick={closeModal}
                            className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                            <svg
                                className="w-4 h-4"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                        </button>
                        {/* Modal content */}
                        <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                            {t("credit-memos.add-note")}
                        </h3>
                        <form className="grid grid-cols-1 gap-6" action="#">
                            <div>
                                <label
                                    htmlFor="Description"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    {t("credit-memos.description")}
                                </label>
                                <textarea
                                    id="message"
                                    rows={4}
                                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 overflow-y-auto"
                                    placeholder={String(t("credit-memos.write-note"))}
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                ></textarea>
                            </div>
                            <div className="p-6 text-center">
                                <button
                                    onClick={handleSendReportClick}
                                    type="button"
                                    className={`mr-3 text-white bg-black hover:bg-black-800 focus:ring-4 focus:outline-none focus:ring-black dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center ml-2 ${
                                        (!selectedMonth || selectedClaimIds.length === 0) ? "opacity-50 cursor-not-allowed" : ""
                                    }`}
                                    disabled={!selectedMonth || selectedClaimIds.length === 0} // Disable the button if conditions are met
                                >
                                    {t("credit-memos.send-report")}
                                </button>
                                <button
                                    onClick={closeModal}
                                    type="button"
                                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                                >
                                    {t("buttons.cancel")}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {isReportSent && (
                <div className="overlay">
                    <div
                        className="mb-4 bg-white border-l-4 border-green-500 text-green-700 p-4 text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        <p className="font-bold text-xl"> {t("credit-memos.report-sent")}</p>
                        <p className="text-lg">
                            {t("credit-memos.report-sent-msg")}
                        </p>
                        {/* Add any additional information or buttons as needed */}
                    </div>
                </div>
            )}
        </div>
    );
}
