import "./App.css";
import {BrowserRouter} from "react-router-dom";
import Router from "./router";
import Navbar from "./components/Layout/Navbar";
import React, { useEffect } from "react";
import TopNavbar from "./components/Layout/TopNavbar";
import { ClaimProvider } from "./Context/ClaimContext";

const App = () => {
    // Initialize darkMode to false by default
    const [darkMode, setDarkMode] = React.useState(localStorage.theme === "dark" ? true : false);
    const element =  document.documentElement;

    const toggleDarkMode = () => {
        setDarkMode((prevDarkMode) => !prevDarkMode);
    };

    useEffect(() => {
        switch (darkMode) {
            case true:
                element.classList.add("dark");
                localStorage.setItem("theme", "dark");
                break;
            case false:
                element.classList.remove("dark");
                localStorage.setItem("theme", "light");
                break;
            default:
                localStorage.removeItem("theme");
                break;
        }
    }, [darkMode, element]);

    return (
        <ClaimProvider> {/* Wrap your application with the ClaimProvider */}
            <div className={`h-screen w-full mx-auto ${darkMode ? "dark" : ""} main-div`}>
                <BrowserRouter>
                    <TopNavbar />
                    <Navbar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
                    <Router />
                </BrowserRouter>
            </div>
        </ClaimProvider>
    );
};

export default App;