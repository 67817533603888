import React, { createContext, Dispatch, SetStateAction, useState } from "react";
import { Recall } from "../types";  // Adjust path according to your directory structure

interface IClaimContext {
    recalls: Recall[];  // Or whatever the type of 'recalls' is
    setRecalls: (recalls: Recall[]) => void;  // Or whatever the type of 'setRecalls' is
    // include other properties if any
}



export const ClaimContext = createContext<IClaimContext | undefined>(undefined);

export const ClaimProvider = ({ children }: { children?: React.ReactNode }) => {
    const [recalls, setRecalls] = useState<Recall[]>([]);

    return (
        <ClaimContext.Provider value={{ recalls, setRecalls }}>
            {children}
        </ClaimContext.Provider>
    );
};