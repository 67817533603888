import React, {useState, useEffect, ChangeEvent, useContext} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {Claim, Communication, Payment, UsedPart} from "../../../types";
import { UsedPartComponent } from "../../Layout/UsedPartComponent";
import "../../../App.css";
import { ClaimContext } from "../../../Context/ClaimContext";
import {Tooltip} from "flowbite-react";
import {InformationCircleIcon} from "@heroicons/react/20/solid";
import api from "../../../api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


interface Reimbursement {
    payment: Payment;
    communication: Communication | null;

}

interface ApiError {
    response: {
        status: number;
    };
}

const ReimbursementComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isReimbursementSubmitted, setIsReimbursementSubmitted] = useState(false);
    const claimData = (location.state as unknown as { claimData: Claim; recalls: any[] })?.claimData;
    const recalls = (location.state as unknown as { claimData: Claim; recalls: any[] })?.recalls || [];
    type DatePickerOnChange = (date: Date | null, event: React.SyntheticEvent<any> | undefined) => void;
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    const handleDateChange: DatePickerOnChange = (date, event) => {
        setSelectedDate(date);
    };

    const [reimbursement, setReimbursement] = useState<Reimbursement>({
        payment: {
            paymentId:"",
            lop: "",
            amountToReclaim: 0,
            workOrderNumber: "",
            billableHours: 0,
            paintCost:0,
            additionalInfo: "",
            requireInvoiceRefund: false,
            attachment: [],
            invoiceNumber:"",
            labourCharged:"",
            dateOfCompletion:"",
            usedPart: [],

        },
        communication: null,

    });

    const [usedParts, setUsedParts] = useState<UsedPart[]>([]);
    const context = useContext(ClaimContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [files, setFiles] = useState<File[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

    if (!context) {
        throw new Error("ReimbursementComponent must be used within a ClaimProvider");
    }

    const { recalls: initialRecalls } = context;

    const { recalls: claim } = context;


    const handleReimbursementInputChange = (
        e: ChangeEvent<HTMLInputElement>
    ) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === "number" ? Number(value) : value;

        setReimbursement((prevReimbursement) => ({
            ...prevReimbursement,
            payment: {
                ...prevReimbursement.payment,
                [name]: type === "checkbox" ? checked : inputValue,
            },
        }));
    };

    const handleReimbursementTextareaChange = (
        e: ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setReimbursement((prevReimbursement) => ({
            ...prevReimbursement,
            payment: {
                ...prevReimbursement.payment,
                [name]: value,
            },
        }));
    };

    const handlePartNoChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        partIndex: number
    ) => {
        const { value } = e.target;
        setUsedParts((prevUsedParts) => {
            const updatedUsedParts = [...prevUsedParts];
            updatedUsedParts[partIndex] = {
                ...updatedUsedParts[partIndex],
                part: {
                    ...updatedUsedParts[partIndex].part,
                    partNo: value,
                },
            };
            return updatedUsedParts;
        });
    };

    const handleCountChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        partIndex: number
    ) => {
        const { value } = e.target;
        setUsedParts((prevUsedParts) => {
            const updatedUsedParts = [...prevUsedParts];
            updatedUsedParts[partIndex] = {
                ...updatedUsedParts[partIndex],
                count: parseInt(value, 10),
            };
            return updatedUsedParts;
        });
    };

    const handleAddUsedPart = () => {
        setUsedParts((prevUsedParts) => [
            ...prevUsedParts,
            {
                part: {
                    partNo: "",
                    partDescription: "",
                },
                count: 0,
            },
        ]);
    };

    const handleRemoveUsedPart = (partIndex: number) => {
        setUsedParts((prevUsedParts) => {
            const updatedUsedParts = [...prevUsedParts];
            updatedUsedParts.splice(partIndex, 1);
            return updatedUsedParts;
        });
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files?.length ? Array.from(event.target.files) : [];
        console.log("Selected Files:", files);
        setSelectedFiles(files);
    };

    const handleFileUpload = async (paymentId: number, files: File[]) => {
        const formData = new FormData();
        selectedFiles.forEach((file, index) => {
            formData.append("multipartFiles", file, file.name);
        });

        try {
            const response = await api.put(
                `/payment/${paymentId}/upload`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            console.log("File upload response:", response.data);
        } catch (error) {
            console.error("An error occurred while uploading the file:", error);
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const userDataString = localStorage.getItem("userData");
        const userData = userDataString ? JSON.parse(userDataString) : null;
        const username = userData?.username || "Default Dealer Name";
        const address = userData?.address || "Default Dealer Address";
        const companyName = userData?.companyName || "Default Dealer Company Name";
        const city = userData?.city || "Default Dealer City";
        const countryCode = userData?.countryCode || "Default Dealer CountryCode";

        const selectedDateUTC = new Date(selectedDate ?? new Date());
        selectedDateUTC.setMinutes(selectedDateUTC.getMinutes() - selectedDateUTC.getTimezoneOffset());

        try {
            const recallPaymentsData = recalls.map((recall) => ({
                recallId: {
                    recallId: recall.recallId.recallId,
                    vin: recall.vin,
                },
                vehicle: {
                    vin: recall.vin,
                    mileage: recall.mileage,
                    model: recall.model,
                    modelYear: recall.modelYear,
                    brand: recall.vehicle.brand,
                    vehicleDesc: recall.vehicle.vehicleDesc,
                },
                recallDescription: recall.recallDescription,
                issueDescription: recall.issueDescription,
                repairSteps: recall.repairSteps,
                instructionLink: recall.instructionLink,
                softwareUpdateDesc: recall.softwareUpdateDesc,
                payment: {
                    lop: recall.payment.lop || reimbursement.payment.lop,
                    amountToReclaim: recall.payment.amountToReclaim || reimbursement.payment.amountToReclaim,
                    labourCharged: recall.payment.labourCharged || reimbursement.payment.labourCharged,
                    additionalInfo: recall.payment.additionalInfo || reimbursement.payment.additionalInfo,
                    requireInvoiceRefund: recall.payment.requireInvoiceRefund || reimbursement.payment.requireInvoiceRefund,
                    invoiceNumber: recall.payment.invoiceNumber || reimbursement.payment.invoiceNumber,
                    dateOfCompletion: recall.payment.dateOfCompletion || reimbursement.payment.dateOfCompletion,

                },
            }));

            const combinedData = {
                dealer: {
                    dealerId: username,
                    dealerName: companyName,
                    address: address,
                    city: city,
                    countryCode: countryCode,
                },
                recalls: recallPaymentsData,
                payment: {
                    ...reimbursement.payment,
                    usedPart: usedParts,
                    dateOfCompletion: selectedDateUTC.toISOString(),
                },
            };

            let response;
            if (claimData && claimData.claimId) {
                response = await api.put(`/claim/payment/${claimData.claimId}`, combinedData);
            } else {
                response = await api.post("/claim/create", combinedData);
            }
            console.log("API response received:", response.data);
            setIsReimbursementSubmitted(true);

            // New part: Handle file upload after successful submission
            const paymentId = response.data?.payment?.paymentId;
            if (selectedFiles.length > 0 && paymentId) {
                await handleFileUpload(paymentId, selectedFiles);
            }

            setTimeout(() => {
                setIsReimbursementSubmitted(false);
                context.setRecalls([]);
                navigate("/claims");
            }, 5000);
        } catch (error) {
            console.error("An error occurred while submitting the claim:", error);

            const apiError = error as ApiError;
            if (apiError?.response?.status === 400) {
                setErrorMessage("The VIN number or Recall ID are already claimed.");
            } else if (apiError?.response?.status === 404) {
                setErrorMessage("The VIN number or Recall ID are invalid.");
            } else {
                setErrorMessage("An unexpected error occurred. Please try again.");
            }

            setTimeout(() => {
                setErrorMessage("");
                navigate("/create-claim");
            }, 3000);
        }
    };

    return (
        <div className="container lg:container mx-auto p-4">
            <h1 className="text-3xl mb-4">Reimbursement Information</h1>
            {errorMessage &&
                <div className="overlay">
                    <div className="mb-4 bg-white border-l-4 border-red-500 text-red-700 p-4 text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        <p className="font-bold text-xl">Claim Failed to Submit</p>

                        <p className="text-sm">
                            <p className="error">{errorMessage}</p>
                        </p>
                    </div>
                </div>
            }
            <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-1">
                        <label
                            htmlFor="invoiceNumber"
                            className="block mb-1 text-gray-700 text-xs dark:text-white"
                        >
                            Invoice Number (Optional)
                        </label>
                        <input
                            type="text"
                            id="invoiceNumber"
                            name="invoiceNumber"
                            value={reimbursement.payment.invoiceNumber}
                            onChange={handleReimbursementInputChange}
                            className="w-full border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md dark:text-white dark:bg-gray-900"
                        />
                    </div>
                    <div className="col-span-1 relative">
                        <label
                            htmlFor="workOrderNumber"
                            className="block mb-1 text-gray-700 text-xs dark:text-white"
                        >
                            Cost of Part Painting(Optional)
                        </label>
                        <input
                            type="number"
                            id="paintCost"
                            name="paintCost"
                            // required
                            value={reimbursement.payment.paintCost}
                            onChange={handleReimbursementInputChange}
                            className="w-full border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md dark:text-white dark:bg-gray-900"
                        />
                        <div className="text-gray-500 top-7 right-2 absolute">
                            <Tooltip className="text-center tooltip-content-wide"  content="Cost of Part Painting">
                                <InformationCircleIcon className="w-5 h-5 mr-1" />
                            </Tooltip>
                        </div>

                    </div>
                    <div className="col-span-1">
                        <label htmlFor="dateOfCompletion" className="block mb-1 text-gray-700 text-xs dark:text-white">
                            Date of Completion
                        </label>
                        <DatePicker
                            id="dateOfCompletion"
                            name="dateOfCompletion"
                            selected={selectedDate}
                            onChange={handleDateChange}
                            className="w-full border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md dark:text-white dark:bg-gray-900"
                        />
                    </div>
                    <div className="col-span-2">
                        <label
                            htmlFor="additionalInfo"
                            className="block mb-1 text-gray-700 text-xs dark:text-white"
                        >
                            Additional Info
                        </label>
                        <textarea
                            id="additionalInfo"
                            name="additionalInfo"
                            value={reimbursement.payment.additionalInfo}
                            onChange={handleReimbursementTextareaChange}
                            className="w-full border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md dark:text-white dark:bg-gray-900"
                        />
                    </div>
                    <div className="col-span-2">
                        {usedParts.map((part, index) => (
                            <UsedPartComponent
                                key={index}
                                part={part}
                                partIndex={index}
                                handlePartNoChange={(e, partIndex) => handlePartNoChange(e, partIndex, )}
                                handleCountChange={(e, partIndex) => handleCountChange(e, partIndex, )}
                                handleRemovePart={handleRemoveUsedPart}
                            />
                        ))}
                        <button type="button" onClick={handleAddUsedPart}
                                className="text-white mt-4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                        >
                            Add Used Part
                        </button>
                    </div>
                    <div className="col-span-1 flex items-center relative mt-2 mb-2">
                        <input
                            type="checkbox"
                            id="requireInvoiceRefund"
                            name="requireInvoiceRefund"
                            checked={reimbursement.payment.requireInvoiceRefund}
                            onChange={handleReimbursementInputChange}
                            className="form-checkbox h-4 w-4 text-blue-500"
                        />
                        <label
                            htmlFor="requireInvoiceRefund"
                            className="ml-2 text-gray-700 text-xs dark:text-white"
                        >
                            Require Invoice Refund
                        </label>
                        <div className="text-gray-500 top-0 right-96 absolute">
                            <Tooltip className="text-center tooltip-content-wide"  content="If applicable">
                                <InformationCircleIcon className="w-5 h-5 mr-1" />
                            </Tooltip>
                        </div>
                    </div>
                    <div className="col-span-2">
                        <label
                            htmlFor="attachment"
                            className="block mb-1 text-gray-700 text-xs dark:text-white"
                        >
                            Attachment
                        </label>
                        <div id="alert-1" className="w-1/2 flex items-center p-4 mb-4 text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
                            <svg className="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                            </svg>
                            <span className="sr-only">Info</span>
                            <div className="ms-3 text-sm font-medium">
                                It is mandatory to upload wiTECH Scan Report!
                            </div>
                            <button type="button" className="ms-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-blue-400 dark:hover:bg-gray-700" data-dismiss-target="#alert-1" aria-label="Close">
                                <span className="sr-only">Close</span>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                            </button>
                        </div>
                        <input
                            type="file"
                            id="attachment"
                            name="attachment"
                            onChange={handleFileChange}
                            required
                            multiple
                            className="w-full border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md dark:text-white dark:bg-gray-900"
                        />
                    </div>

                </div>
                {isReimbursementSubmitted && (
                    <div className="overlay">
                        <div className="mb-4 bg-white border-l-4 border-green-500 text-green-700 p-4 text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <p className="font-bold text-xl">Claim and Reimbursement are Submitted</p>
                            <p className="text-lg">
                                Your claim and reimbursement have been submitted successfully!
                            </p>
                            <p className="text-sm">
                                You can track your claim status in the claims page.
                            </p>
                        </div>
                    </div>
                )}

                <div className="flex justify-end mt-4">
                    <button
                        type="submit"
                        className="text-white bg-black hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 px-5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 mr-2"
                    >
                        Submit Reimbursement
                    </button>
                    <button
                        onClick={() => navigate(-1)}
                        className="text-white bg-gray-700 hover:bg-gray-800 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 px-5 mr-2 mb-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 mr-2"
                    >
                        Go Back
                    </button>
                </div>
            </form>

        </div>
    );
};

export default ReimbursementComponent;
