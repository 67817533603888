import React, {useState, useEffect, ChangeEvent, useContext} from "react";
import { useNavigate, useLocation} from "react-router-dom";
import {Recall, OrderPart, Vehicle, Claim, Order} from "../../../types";
import { RecallComponent } from "../../Layout/RecallComponent";
import "../../../App.css";
import { PlusIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { ClaimContext } from "../../../Context/ClaimContext";
import api from "../../../api";
import queryString from "query-string";

interface ApiError {
    response: {
        status: number;
    };
}

const CreateClaimComponent = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isDraft, setIsDraft] = useState(false);
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const location = useLocation();
    const [claimData, setClaimData] = useState<Claim | null>(null);
    const queryParams = queryString.parse(location.search);
    const [vin, setVin] = useState(() => {
        const initialVin = Array.isArray(queryParams.vin) ? queryParams.vin[0] : queryParams.vin || "";
        return initialVin;
    });
    const brand = Array.isArray(queryParams.brand) ? queryParams.brand[0] : queryParams.brand;
    const brandString = brand !== null ? brand : "";
    const model = Array.isArray(queryParams.model) ? queryParams.model[0] : queryParams.model;
    const modelString = model !== null ? model : "";
    const modelYear = Array.isArray(queryParams.modelYear) ? queryParams.modelYear[0] : queryParams.modelYear;
    const modelYearString = modelYear !== null ? modelYear : "";
    const mileage = Array.isArray(queryParams.mileage) ? queryParams.mileage[0] : queryParams.mileage;
    const mileageString = mileage !== null ? mileage : "";
    const recallDescription = Array.isArray(queryParams.recallDescription) ? queryParams.recallDescription[0] : queryParams.recallDescription;
    const recallDescriptionString = recallDescription !== null ? recallDescription : "";
    const lop = Array.isArray(queryParams.lop) ? queryParams.lop[0] : queryParams.lop;
    const lopString = lop !== null ? lop : "";
    const labourCharged = Array.isArray(queryParams.labourCharged) ? queryParams.labourCharged[0] : queryParams.labourCharged;
    const labourChargedString = labourCharged !== null ? labourCharged : "";
    const [lopListsValidity, setLopListsValidity] = useState({});
    const [lopValidity, setLopValidity] = useState({});

    const handleLopAndLabourChargedChange = (recallIndex: number, lop: string, labourCharged: string) => {
        const updatedRecalls = [...recalls];
        updatedRecalls[recallIndex] = {
            ...updatedRecalls[recallIndex],
            payment: {
                ...updatedRecalls[recallIndex].payment,
                lop: lop, // Assuming this is correct
                labourCharged: Number(labourCharged),
            },
        };
        setRecalls(updatedRecalls);
    };


    const handleLopValidityChange = (recallIndex: number, isValid: boolean) => {
        setLopValidity(prev => ({ ...prev, [recallIndex]: isValid }));
    };

    const handleLopHourlyListChange = (recallIndex: any, isValid: any) => {
        setLopListsValidity(prev => ({ ...prev, [recallIndex]: isValid }));
    };
    const claimContext = useContext(ClaimContext);
    if (!claimContext) {
        throw new Error(
            "ClaimContext is undefined, please verify the context provider."
        );
    }
    const { recalls, setRecalls } = claimContext;
    const [isMounted, setIsMounted] = useState(false);

    const [isPreFilled, setIsPreFilled] = useState(false);

    useEffect(() => {
        const newVin = Array.isArray(queryParams.vin) ? queryParams.vin[0] : queryParams.vin || "";
        if (newVin !== vin) {
            setVin(newVin);
        }
    }, [location.search, vin]);
    useEffect(() => {
        setIsMounted(true);
        if (recalls.length > 0) {
            setIsPreFilled(true);
        }
        return () => {
            setIsMounted(false);
        };
    }, [recalls]);

    useEffect(() => {
        const data = location.state?.claimData as Claim;

        if (data) {
            setClaimData(data);
            setIsPreFilled(true);
        }
    }, [location.state]);

    useEffect(() => {
        const userDataString = localStorage.getItem("userData");
        const userData = userDataString ? JSON.parse(userDataString) : null;
        const address = userData?.address || "Default Dealer Name";
        const companyName = userData?.companyName || "Default Dealer Name";
        if (!isPreFilled && isMounted) {
            setRecalls([
                // Initialize recalls with an empty recall object
                {
                    dealerName: companyName ,
                    address: address,
                    recallId: { recallId: "", vin: "" },
                    modelYear: modelYear || "",
                    mileage: mileage || "",
                    model: model || "",
                    brand: brand || "",
                    recallDescription: recallDescription||"",
                    vin: vin || "",
                    vehicle: {
                        vin: "",
                        mileage: 0,
                        model: "",
                        modelYear: modelYear || "",
                        vehicleDesc: "",
                        brand: brand ||"",
                    },
                    payment:{
                        paymentId: 0,
                        lop: "",
                        amountToReclaim: 0,
                        labourCharged: 0,
                    },
                    order:{
                        orderNo:0,
                        orderDescription:"",
                        orderPart:[],
                    },
                    issueDescription: "",
                    instructionLink: "",
                    softwareUpdateDesc: "",
                    creationDate: "",
                    lastModifiedDate: "",
                    recallStatus: "",
                    recallPart: [],
                },
            ]);
        }
    }, [isPreFilled, isMounted, setRecalls, vin, brand]);


    useEffect(() => {
        const data = location.state?.claimData as Claim;

        if (data) {
            setClaimData(data);

            const userDataString = localStorage.getItem("userData");
            const userData = userDataString ? JSON.parse(userDataString) : null;
            const address = userData?.address || "Default Dealer Name";
            const companyName = userData?.companyName || "Default Dealer Address";

            const recallsData = data.recalls.map((recall) => {
                // Create a new recall object with the required properties from the claim data
                const newRecall: Recall = {
                    dealerName: companyName || "Default Dealer Name",
                    address: address,
                    recallId: { recallId: recall.recallId.recallId, vin: recall.recallId.vin },
                    modelYear: recall.vehicle.modelYear,
                    mileage: recall.vehicle.mileage.toString(),
                    model:recall.vehicle.model || "",
                    brand: recall.vehicle.brand || "",
                    recallDescription: recall.recallDescription,
                    vin: vin || recall.recallId.vin,
                    vehicle: {
                        vin: recall.recallId.vin,
                        mileage: recall.vehicle.mileage,
                        model: recall.vehicle.model,
                        modelYear: recall.vehicle.modelYear,
                        vehicleDesc: recall.vehicle.vehicleDesc,
                        brand: recall.vehicle.brand,
                    },
                    payment:{
                        paymentId: recall.payment.paymentId,
                        lop: recall.payment.lop,
                        amountToReclaim: recall.payment.amountToReclaim,
                        labourCharged: recall.payment.labourCharged,
                    },
                    order: {
                        orderNo: recall.order.orderNo,
                        orderDescription: recall.order.orderDescription,
                        orderPart: recall.order.orderPart.map(part => ({
                            id: part.id,
                            part: {
                                partNo: part.part.partNo,
                                partDescription: part.part.partDescription,
                            },
                            count: part.count,
                        })),
                    },
                    issueDescription: recall.issueDescription,
                    instructionLink: recall.instructionLink,
                    softwareUpdateDesc: recall.softwareUpdateDesc,
                    creationDate: recall.creationDate,
                    lastModifiedDate: recall.lastModifiedDate,
                    recallStatus: recall.recallStatus,
                    recallPart: [],
                };

                return newRecall;
            });

            setRecalls(recallsData);
            setIsPreFilled(true);
        }
    }, [location.state, setRecalls,vin,brand]);


    const handleAddRecall = () => {
        const userDataString = localStorage.getItem("userData");
        const userData = userDataString ? JSON.parse(userDataString) : null;
        const address = userData?.address || "Default Dealer Name";
        const companyName = userData?.companyName || "Default Dealer CompanyName";

        // Create a new recall with default values
        const newRecall: Recall = {
            dealerName: companyName,
            address: address,
            recallId: { recallId: "", vin: "" },
            modelYear: modelYear|| "",
            mileage: mileage || "",
            model: model || "",
            brand: brand || "",
            recallDescription: recallDescription||"",
            vin: vin || "",
            vehicle: {
                vin: "",
                mileage: 0,
                model: "",
                modelYear: "",
                vehicleDesc: "",
                brand: "",
            },
            payment:{
                paymentId: 0,
                lop: "",
                amountToReclaim: 0,
                labourCharged: 0,
            },
            order:{
                orderNo:0,
                orderDescription:"",
                orderPart:[],
            },
            issueDescription: "",
            instructionLink: "",
            softwareUpdateDesc: "",
            creationDate: "",
            lastModifiedDate: "",
            recallStatus: "",
            recallPart: [],
        };

        if (recalls.length > 0) {
            // Copy everything else except for recallDescription and recallId
            const lastRecall = recalls[recalls.length - 1];
            newRecall.dealerName = lastRecall.dealerName;
            newRecall.address = lastRecall.address;
            newRecall.modelYear = lastRecall.modelYear;
            newRecall.mileage = lastRecall.mileage;
            newRecall.model = lastRecall.model;
            newRecall.vin = lastRecall.vin;
            newRecall.vehicle = lastRecall.vehicle;
            newRecall.issueDescription = lastRecall.issueDescription;
            newRecall.instructionLink = lastRecall.instructionLink;
            newRecall.softwareUpdateDesc = lastRecall.softwareUpdateDesc;
            newRecall.creationDate = lastRecall.creationDate;
            newRecall.lastModifiedDate = lastRecall.lastModifiedDate;
            newRecall.recallStatus = lastRecall.recallStatus;
            newRecall.brand = lastRecall.brand;
        }

        setRecalls([...recalls, newRecall]);
    };

    const transformDataForApi = (recalls: Recall[], includeOrderedParts: boolean): any => {
        // Explicitly declare the shape of the apiData object
        const userDataString = localStorage.getItem("userData");
        const userData = userDataString ? JSON.parse(userDataString) : null;
        const username = userData?.username || "Default Dealer Name";
        const address = userData?.address || "Default Dealer Address";
        const companyName = userData?.companyName || "Default Dealer CompanyName";
        const city = userData?.city || "Default Dealer City";
        const countryCode = userData?.countryCode || "Default Dealer CountryCode";

        const apiData: {
            dealer: {
                dealerId: number;
                dealerName: string;
                address: string;
                city: string;
                countryCode: string;
            };
            recalls: {
                recallId: {
                    recallId: string;
                    vin: string;
                };
                vehicle: {
                    vin: string;
                    mileage: string;
                    model: string;
                    modelYear: string;
                    brand: string | null;
                    vehicleDesc: string;
                };
            }[];
            order?: {
                orderDescription: string;
                orderPart: {
                    part: {
                        partNo: string;
                        partDescription: string;
                    };
                    count: number;
                }[];
            };
        } = {
            dealer: {
                dealerId: username,
                dealerName: companyName,
                address: address,
                city: city,
                countryCode: countryCode,
            },
            recalls: recalls.map((recall) => ({
                recallId: {
                    recallId: recall.recallId.recallId,
                    vin: recall.vin,
                },
                vehicle: {
                    vin: recall.vehicle.vin,
                    mileage: recall.mileage,
                    model: recall.model,
                    modelYear: recall.modelYear,
                    brand: recall.vehicle.brand,
                    vehicleDesc: recall.vehicle.vehicleDesc,
                },
                payment:{
                    paymentId: recall.payment.paymentId,
                    lop: recall.payment.lop,
                    amountToReclaim: recall.payment.amountToReclaim,
                    labourCharged: recall.payment.labourCharged,
                },
                order: {
                    orderNo: recall.order.orderNo,
                    orderDescription: recall.order.orderDescription,
                    orderPart: recall.order.orderPart.map(part => ({
                        id: part.id,
                        part: {
                            partNo: part.part.partNo,
                            partDescription: part.part.partDescription,
                        },
                        count: part.count,
                    })),
                },
                recallDescription: recall.recallDescription
            })),
        };

        if (includeOrderedParts) {
            apiData.order = {
                orderDescription: "order desc",
                orderPart: recalls.flatMap((recall) =>
                    recall.order.orderPart.map((part) => ({
                        part: {
                            partNo: part.part.partNo,
                            partDescription: part.part.partDescription,
                        },
                        count: part.count,
                    }))
                ),
            };
        }

        return apiData;
    };

    const transformDataForPutApi = (recalls: Recall[], includeOrderedParts: boolean): any => {
        const apiData: {
            recalls: {
                vehicle: {
                    mileage: string;
                    model: string;
                    modelYear: string;
                    brand: string;
                    vehicleDesc: string;
                };
            }[];
            order?: {
                orderDescription: string;
                orderPart: {
                    part: {
                        partNo: string;
                        partDescription: string;
                    };
                    count: number;
                }[];
            };
        } = {
            recalls: recalls.map((recall) => ({
                recallId: {
                    recallId: recall.recallId.recallId,
                    vin: recall.vin,
                },
                vehicle: {
                    vin: recall.vehicle.vin,
                    mileage: recall.mileage,
                    model: recall.model,
                    modelYear: recall.modelYear,
                    brand: recall.brand,
                    vehicleDesc: recall.vehicle.vehicleDesc,
                },
                payment:{
                    paymentId: recall.payment.paymentId,
                    lop: recall.payment.lop,
                    amountToReclaim: recall.payment.amountToReclaim,
                    labourCharged: recall.payment.labourCharged,
                },
                order: {
                    orderNo: recall.order.orderNo,
                    orderDescription: recall.order.orderDescription,
                    orderPart: recall.order.orderPart.map(part => ({
                        id: part.id,
                        part: {
                            partNo: part.part.partNo,
                            partDescription: part.part.partDescription,
                        },
                        count: part.count,
                    })),
                },
                recallDescription: recall.recallDescription,
            })),
        };

        if (includeOrderedParts) {
            apiData.order = {
                orderDescription: "order desc",
                orderPart: recalls.flatMap((recall) =>
                    recall.order.orderPart.map((part) => ({
                        part: {
                            partNo: part.part.partNo,
                            partDescription: part.part.partDescription,
                        },
                        count: part.count,
                    }))
                ),
            };
        }

        return apiData;
    };


    const handleOrderPart = (recallIndex: number) => {
        const updatedRecalls = [...recalls];
        const recallToUpdate = updatedRecalls[recallIndex];

        if (!recallToUpdate.order) {
            recallToUpdate.order = {
                orderNo: 0,
                orderDescription: "",
                orderPart: [],
            };
        }

        recallToUpdate.order.orderPart.push({
            id: recallToUpdate.order.orderPart.length,
            part: {
                partNo: "",
                partDescription: "",
            },
            count: 0,
        });

        setRecalls(updatedRecalls);
    };




    const scrollToBottom = () => {
        const element = document.getElementById("bottomAnchor");
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "end" });
        }
    };

    const handleRecallInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
        recallIndex: number
    ) => {
        const { name, value } = e.target;
        const updatedRecalls = [...recalls];

        if (name === "vin") {
            updatedRecalls[recallIndex].vin = value;
            updatedRecalls[recallIndex].vehicle.vin = value;
        }
        else if (name.startsWith("vehicle.")) {
            const vehicleProp = name.split(".")[1];
            (updatedRecalls[recallIndex].vehicle[vehicleProp as keyof Vehicle] as unknown as string) = value;
        }
        else if (name === "brand") {
            updatedRecalls[recallIndex].brand = value;
            updatedRecalls[recallIndex].vehicle.brand = value;
        }
        else if (name === "recallId.recallId") {
            updatedRecalls[recallIndex].recallId.recallId = value;
        }
        else if (name === "mileage") {
            updatedRecalls[recallIndex].mileage = value;
            updatedRecalls[recallIndex].vehicle.mileage = parseInt(value);
        }
        else if (name === "model") {
            updatedRecalls[recallIndex].model = value;
            updatedRecalls[recallIndex].vehicle.model = value;
        }
        else  if (name === "lop") {
            updatedRecalls[recallIndex].payment.lop = value;
        }
        else if (name === "labourCharged") {
            updatedRecalls[recallIndex].payment.labourCharged = parseInt(value);
        }
        else {
// Update other properties
            (updatedRecalls[recallIndex][name as Exclude<keyof Recall, "recallPart" | "vehicle">] as unknown as string) = value;
        }

        setRecalls(updatedRecalls);
    };


    const handlePartInputChange = (e: { target: { name: any; value: any; }; }, recallIndex: string | number, partIndex: string | number) => {
        const { name, value } = e.target;
        const updatedRecalls = [...recalls];

        if (name === "partNo") {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            updatedRecalls[recallIndex].order.orderPart[partIndex].part.partNo = value;
        } else if (name === "count") {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            updatedRecalls[recallIndex].order.orderPart[partIndex].count = parseInt(value, 10);
        }

        setRecalls(updatedRecalls);
    };

    const handleRemoveRecall = (recallIndex: number) => {
        const updatedRecalls = recalls.filter((_, index) => index !== recallIndex);
        setRecalls(updatedRecalls);
    };

    const handleRemovePart = (recallIndex: number, partIndex: number) => {
        const updatedRecalls = [...recalls];
        updatedRecalls[recallIndex].order.orderPart = updatedRecalls[
            recallIndex
            ].order.orderPart.filter((_, index) => index !== partIndex);
        setRecalls(updatedRecalls);
    };

    const handleSaveDraft = async () => {
        if (recalls.every((recall) => recall.order.orderPart.length === 0)) {
            try {
                console.log("Saving draft...");
                const apiData = transformDataForApi(recalls, false);

                // Make an HTTP POST request to your API endpoint to save the draft
                const response = await api.post(
                    "/claim/create",
                    apiData
                );

                setIsDraft(true); // Set the state directly here
                setTimeout(() => {
                    setIsDraft(false);
                    setRecalls([]); // Clear recalls after saving as a draft

                    // Save the recalls as a draft before navigating back to the CreateClaimComponent
                    navigate("/claims", { state: { recallData: recalls[0] } });
                }, 5000);
            } catch (error) {
                const apiError = error as ApiError;

                if (apiError?.response?.status === 400) {
                    setErrorMessage("The VIN number or Recall ID are already claimed.");
                } else if (apiError?.response?.status === 404) {
                    setErrorMessage("The VIN number or Recall ID are invalid.");
                } else {
                    setErrorMessage("An unexpected error occurred. Please try again.");
                }

                setTimeout(() => {
                    setErrorMessage("");
                    navigate("/create-claim");
                }, 3000);
            }
        } else {
            console.log("Cannot save draft as there are ordered parts.");
        }
    };


    const handleSubmit = async () => {
        const transformedRecalls = recalls.map(recall => ({
            ...recall,
            vin: recall.vin || recall.vehicle.vin,
            model: recall.model || recall.vehicle.model,
            brand: recall.brand || recall.vehicle.brand,
        }));
        console.log(lopString,"lop");
        console.log("Submitting the following data:", recalls);
        const isEveryLopListValid = Object.values(lopListsValidity).every(isValid => isValid);
        const prepareSubmissionData = (recalls: any[]) => {
            return recalls.map(recall => ({
                ...recall,
                lop: recall.lop,
                labourCharged: recall.labourCharged,
            }));
        };

        if (
            recalls.some(
                (recall) =>
                    !recall.order.orderPart ||
                    recall.recallId.recallId === "" ||
                    !recall.vin ||
                    !recall.dealerName ||
                    !recall.address ||
                    !recall.mileage ||
                    !recall.brand ||
                    !recall.recallDescription ||
                    !recall.modelYear ||
                    !recall.payment.lop ||
                    !recall.payment.labourCharged
            ) || !isEveryLopListValid
        ) {
            setErrorMessage("Please fill in all required fields and select a Recall ID.");
            setTimeout(() => {
                setErrorMessage("");
            }, 3000);

            return;
        }

        if (recalls.some((recall) => recall.order.orderPart.length > 0)) {
            try {
                const apiDataWithoutRecallIdAndVin = transformDataForPutApi(recalls, true);
                const apiData = transformDataForApi(recalls, true);
                const claimData = location.state?.claimData as Claim;

                if (claimData && claimData.claimId) {
                    const claimId = claimData.claimId;


                    const responseClaim = await api.put(
                        `/claim/${claimId}`,
                        apiDataWithoutRecallIdAndVin,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    );

                    console.log("Claim Updated:", responseClaim.data);

                    setIsSubmitted(true);
                    setTimeout(() => {
                        setIsSubmitted(false);
                        setRecalls([]);
                        navigate("/claims");
                    }, 3000);
                } else {
                    const response = await api.post(
                        "/claim/create",
                        apiData
                    );

                    setIsSubmitted(true);
                    setTimeout(() => {
                        setIsSubmitted(false);
                        setRecalls([]);
                        navigate("/claims");
                    }, 3000);
                }
            } catch (error) {
                console.error("An error occurred while submitting the claim:", error);
                const apiError = error as ApiError;

                if (apiError?.response?.status === 400) {
                    setErrorMessage("The VIN number or Recall ID are already claimed.");
                } else if (apiError?.response?.status === 404) {
                    setErrorMessage("The VIN number or Recall ID are invalid.");
                } else {
                    setErrorMessage("An unexpected error occurred. Please try again.");
                }

                setTimeout(() => {
                    setErrorMessage("");
                    navigate("/create-claim");
                }, 3000);
            }
        } else {
            navigate("/reimbursement", { state: { claimData: claimData, recalls: recalls } });
        }
    };


    return (
        <div className="parent">
            <div className="container lg:container mx-auto p-6 border border-gray-200 shadow-md dark:border-gray-700 mt-5 rounded-lg">
                <div className="flex justify-between items-center p-6">
                    <h5 className="inline text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        Create Claim
                    </h5>
                    {errorMessage &&
                        <div className="overlay">
                            <div className="mb-4 bg-white border-l-4 border-red-500 text-red-700 p-4 text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                <p className="font-bold text-xl">Claim Failed to Submit</p>

                                <p className="text-sm">
                                    <p className="error">{errorMessage}</p>
                                </p>
                            </div>
                        </div>
                    }
                    <button
                        onClick={handleAddRecall}
                        className="flex justify-between py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-blue-500 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    >
                        <PlusIcon className="w-5 h-5 mr-1"></PlusIcon>
                        Add another recall
                    </button>
                </div>

                {recalls.map((recall, recallIndex) => (
                    <RecallComponent
                        key={recallIndex}
                        recall={recall}
                        recallIndex={recallIndex}
                        isFirstRecall={recallIndex === 0}
                        handleRecallInputChange={handleRecallInputChange}
                        handleAddPart={handleOrderPart}
                        handleRemoveRecall={handleRemoveRecall}
                        handlePartInputChange={handlePartInputChange}
                        handleRemovePart={handleRemovePart}
                        vin={vin ?? ""}
                        mileage={mileageString ?? ""}
                        model={modelString ?? ""}
                        brand={brandString ?? ""}
                        modelYear={modelYearString ?? ""}
                        recallDescription={recallDescriptionString ?? ""}
                        lop={lopString ?? ""}
                        labourCharged={labourChargedString ?? ""}
                        claimData={claimData}
                        onLopHourlyListChange={handleLopValidityChange}
                        handleLopAndLabourChargedChange={handleLopAndLabourChargedChange}
                    />
                ))}
                {isDraft && (
                    <div className="overlay">
                        <div className="mb-4 bg-white border-l-4 border-yellow-500 text-yellow-700 p-4 text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <p className="font-bold text-xl">Draft Saved</p>
                            <p className="text-lg">
                                Your claim draft has been saved successfully!
                            </p>
                            <p className="text-sm">
                                You can continue editing and submit it later.
                            </p>
                        </div>
                    </div>
                )}
                {isSubmitted && (
                    <div className="overlay">
                        <div className="mb-4 bg-white border-l-4 border-green-500 text-green-700 p-4 text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <p className="font-bold text-xl">Claim Submitted</p>
                            <p className="text-lg">
                                Your claim has been submitted successfully!
                            </p>
                            <p className="text-sm">
                                You can track your claim status in the claims page.
                            </p>
                        </div>
                    </div>
                )}
                <div className="flex justify-end mt-4">
                    {!recalls.some((recall) => recall.order.orderPart.length > 0) && (
                        <button
                            onClick={handleSaveDraft}
                            className="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 px-5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 mr-2"
                        >
                            Save Draft
                        </button>
                    )}
                    <button
                        onClick={handleSubmit}
                        className="flex justify-between py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-green-700 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-green-700 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                    >
                        {recalls.some((recall) => recall.order.orderPart.length > 0)
                            ? "Submit Claim"
                            : "Next"}
                    </button>
                </div>
            </div>
            <div id="bottomAnchor" style={{ paddingBottom: "150px" }}></div>
        </div>
    );
};

export default CreateClaimComponent;
