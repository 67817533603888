import React, { useEffect } from "react";
import { Table, Checkbox, Badge } from "flowbite-react";
import { getRecallByVINData, getRecallAll } from "../../lib/api-hooks";
import { FetchState } from "../../types";
import { PencilIcon } from "@heroicons/react/24/outline";
import "../../App.css";
import { useTranslation } from "react-i18next";

export default function TableComponent({ vin }: { vin: string }) {
    const [tableData, fetchState, getTableData] = getRecallByVINData(vin);
    const userData = JSON.parse(localStorage.getItem("userData") || "{}");
    const userRole = userData.role || ""; // Get the user's role from user data
    const showInstructionLink = userRole !== "dealerport";

    useEffect(() => {
        if (vin) {
            getTableData();
        }
    }, [vin]);

    const { t } = useTranslation();

    if (fetchState === FetchState.LOADING) {
        return <p>Fetching recalls...</p>;
    }

    if (fetchState === FetchState.ERROR) {
        return <p>No Recalls Found</p>;
    }

    return (
        <div className="row-start-2 row-end-2">
            {fetchState === FetchState.DEFAULT && (
                <Table hoverable>
                    <Table.Head>
                        <Table.HeadCell>{t("table.recall")} ID</Table.HeadCell>
                        <Table.HeadCell>{t("table.description")}</Table.HeadCell>
                        {showInstructionLink && <Table.HeadCell>{t("table.instruction-link")}</Table.HeadCell>}
                        <Table.HeadCell>{t("table.status")}</Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white" />
                            <Table.Cell />
                            {showInstructionLink && <Table.Cell />}
                            <Table.Cell />
                        </Table.Row>
                    </Table.Body>
                </Table>
            )}

            {fetchState === FetchState.SUCCESS && (
                <div className="overflow-x-auto">
                    {tableData && tableData.length > 0 ? (
                        <Table hoverable><Table.Head>
                                <Table.HeadCell>{t("table.recall")} ID</Table.HeadCell>
                                <Table.HeadCell>{t("table.description")}</Table.HeadCell>
                            {showInstructionLink && <Table.HeadCell>{t("table.instruction-link")}</Table.HeadCell>}
                                <Table.HeadCell>{t("table.status")}</Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                                {tableData.map((recall: any, key: any) => (
                                    <Table.Row
                                        key={key}
                                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                            {recall.recallId.recallId}
                                        </Table.Cell>
                                        <Table.Cell>{recall.issueDescription}</Table.Cell>
                                        {showInstructionLink && (
                                            <Table.Cell>
                                                <a href={`http://${recall.instructionLink}`} target="_blank" rel="noopener noreferrer">
                                                    {recall.instructionLink.replace(/^.*vin-search\//, "")}
                                                </a>
                                            </Table.Cell>
                                        )}
                                        <Table.Cell>
                                            <Badge
                                                className="status-badge"
                                                style={{
                                                    justifyContent: "center",
                                                    border: recall.recallStatus === "COMPLETE"
                                                        ? "2px solid green"
                                                        : recall.recallStatus === "INCOMPLETE"
                                                            ? "2px solid red"
                                                            : "2px solid gray",
                                                    backgroundColor: "white",
                                                    color: recall.recallStatus === "COMPLETE"
                                                        ? "green"
                                                        : recall.recallStatus === "INCOMPLETE"
                                                            ? "red"
                                                            : "gray"
                                                }}
                                            >
                                                {recall.recallStatus}
                                            </Badge>
                                        </Table.Cell>






                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    ) : (
                        <p className="text-lg mt-1">No recalls Found</p>
                    )}
                </div>
            )}
        </div>
    );
}
