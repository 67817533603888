import React, {useEffect, useRef, useState} from "react";
import { useGetClaimInfo } from "../../../lib/api-hooks";
import {FetchState, Order} from "../../../types";
import axios from "axios";
import { ArrowLongDownIcon, ArrowLongUpIcon } from "@heroicons/react/20/solid";
import {useNavigate} from "react-router-dom";
import api from "../../../api";
import JSZip from "jszip";
import {t} from "i18next";
import "../../../App.css";

interface Message {
    messageId?: number;
    messageBy: string;
    message: string;
}

interface UserData {
    username: string;
    role: string;
    companyName:string;
    name:string;
}

const TSOClaimView = () => {
    const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
    const [claimInfo, fetchState] = useGetClaimInfo();
    const [showStatusOptions, setShowStatusOptions] = useState(false);
    const [filterStatus, setFilterStatus] = useState(""); // Initial value is an empty string, change it according to your requirements
    const [currentStatus, setCurrentStatus] = useState<string | null>(claimInfo?.claimStatus ?? null);
    const [isAgentInfoSubmitted, setIsAgentInfoSubmitted] = useState(false);
    // const [isPartInfotSubmitted, setIsPartInfotSubmitted] = useState(false);

    const navigate = useNavigate();
    const [isStatusUpdated, setIsStatusUpdated] = useState(false);
    const [underReview, setUnderReview] = useState(false);
    const isDarkMode = false;
    const [messages, setMessages] = useState<Message[]>([]);
    const [newMessage, setNewMessage] = useState("");
    const [userData, setUserData] = useState<UserData | null>(null);
    const chatContainerRef = useRef<HTMLDivElement | null>(null);
    const [isPartInfoSubmitted, setIsPartInfoSubmitted] = useState(false);

    const formatDecimalInput = (value:any) => {
        let nonNegativeValue = value.startsWith("-") ? value.substring(1) : value;
        if (nonNegativeValue.startsWith(".")) {
            nonNegativeValue = "0" + nonNegativeValue;
        }
        const regex = /^\d*\.?\d{0,2}/;
        let match = (nonNegativeValue.match(regex) || [])[0];
        match = match.replace(/^0+([1-9])/, "$1"); // For whole numbers
        if (match.startsWith("0") && match.includes(".")) {
            match = match.replace(/^(0+)\.(\d{0,2})/, "0.$2");
        }

        return match;
    };
    useEffect(() => {
        // Simulate loading user data from local storage (replace with actual implementation)
        const userDataString = localStorage.getItem("userData");
        const parsedUserData = userDataString ? JSON.parse(userDataString) : null;
        setUserData(parsedUserData);
    }, []);

    const sortMessagesByMessageId = (messages: Message[]) => {
        return messages.slice().sort((a, b) => (a.messageId ?? 0) - (b.messageId ?? 0));
    };


    useEffect(() => {
        // Whenever messages change, clear the input field
        setNewMessage("");
    }, [messages]);

    // Initialize messages from claimInfo.communication when claimInfo changes
    useEffect(() => {
        if (claimInfo && claimInfo.communication) {
            const formattedMessages: Message[] = claimInfo.communication.map((communication) => ({
                messageId: communication.messageId,
                messageBy: communication.messageBy,
                message: communication.message,
            }));
            const sortedMessages = sortMessagesByMessageId(formattedMessages);
            setMessages(sortedMessages);
        }
    }, [claimInfo]);

    const scrollToBottom = () => {
        // Check if chatContainerRef.current is defined
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTo(0, chatContainerRef.current.scrollHeight);
        }
    };


    // useEffect to scroll to the bottom whenever a new message is added
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const sendMessage = () => {
        if (!userData || !claimInfo || !newMessage) {
            // Handle the case where user data, claimInfo, or newMessage is not available
            return;
        }

        // Create a message object using the userData and newMessage
        const newMessageData = {
            messageBy: userData.role,
            message: newMessage,
        };

        // Update the messages state immediately with the new message
        setMessages((prevMessages) => [...prevMessages, newMessageData]);

        // Make a PUT request to post the message with the specific claimId
        api
            .put(`/claim/message/${claimInfo?.claimId}`, newMessageData)
            .then((response) => {
                console.log("API response:", response); // Log the API response
                setNewMessage("");

                // Check if the API response contains updated message data and update the state accordingly
                if (response.data && response.data.messageId) {
                    // Find the index of the newly added message based on its content
                    const newMessageIndex = messages.findIndex(
                        (message) => message.message === newMessageData.message
                    );

                    if (newMessageIndex !== -1) {
                        // Create the updated message object with the actual messageId
                        const updatedMessage = {
                            messageId: response.data.messageId,
                            messageBy: newMessageData.messageBy,
                            message: newMessageData.message,
                        };

                        // Replace the temporary message with the actual messageId in the messages state
                        const updatedMessages = [...messages];
                        updatedMessages[newMessageIndex] = updatedMessage;

                        // Update the messages state with the updated message
                        setMessages(updatedMessages);
                    }
                }
            })
            .catch((error) => {
                console.error("API error:", error);
                // Handle the error if needed
            });
    };


    const handleStatusFilter = (status: string) => {
        setSelectedStatus(status);
        setFilterStatus(status);
    };


    const [agentInfo, setAgentInfo] = useState({
        infoId: 0,
        claimReceivedDate: "",
        dateClaimEntered: "",
        totalPartCost: 0,
        hourlyRate: "",
        amountBilled: 0,
        returnFca: 0,
        returnFcaWoPart: 0,
        remarks: "",
        underReview: false,
    });

    const [hourlyRateManuallySet, setHourlyRateManuallySet] = useState(false);

    useEffect(() => {
        if (claimInfo) {
            // Common information assignment
            let updatedAgentInfo = {
                ...agentInfo,
                claimReceivedDate: claimInfo.creationDate,
            };

            // Check if the hourly rate has been manually set
            if (!hourlyRateManuallySet) {
                // Retrieve the dealerId from local storage
                const userDataString = localStorage.getItem("userData");
                const userData = userDataString ? JSON.parse(userDataString) : null;
                const dealerId = userData?.username || "Default Dealer User Name";

                // Use Axios to make the API request
                if (dealerId) {
                    // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint
                    api.get(`/dealer/hourlyRate/${dealerId}`)
                        .then((response) => {
                            const data = response.data;
                            // Update the hourly rate in updatedAgentInfo and mark it as manually set
                            updatedAgentInfo = { ...updatedAgentInfo, hourlyRate: data.hourlyRate };
                            setAgentInfo(updatedAgentInfo);
                            setHourlyRateManuallySet(true);
                        })
                        .catch((error) => {
                            console.error("Error fetching hourly rate:", error);
                        });
                }
            }

            // Prefill the form with existing data if the claimStatus is "INPROGRESS"
            if (claimInfo.claimStatus === "INPROGRESS" && claimInfo.agentInformation) {
                updatedAgentInfo = {
                    ...updatedAgentInfo,
                    dateClaimEntered: claimInfo.agentInformation.dateClaimEntered,
                    totalPartCost: claimInfo.agentInformation.totalPartCost,
                    amountBilled: claimInfo.agentInformation.amountBilled,
                    returnFca: claimInfo.agentInformation.returnFca,
                    returnFcaWoPart: claimInfo.agentInformation.returnFcaWoPart,
                    remarks: claimInfo.agentInformation.remarks,
                    hourlyRate: claimInfo.dealer.hourlyRate
                };
            }

            setAgentInfo(updatedAgentInfo);
        }
    }, [claimInfo, hourlyRateManuallySet]);




    const [partInfo, setPartInfo] = useState({
        orderReceivedDate: "",
        sapOrderEnteredDate: "",
        sapOrderNumber: "",
        partsOrderRemarks:"",

    });


    useEffect(() => {
        if (claimInfo) {
            // Common information assignment
            const updatedPartInfo = {
                ...partInfo,
                orderReceivedDate: claimInfo.order?.creationDate,
            };

            setPartInfo(updatedPartInfo);
        }
    }, [claimInfo]);




    const handlePartInfoSubmit = async () => {
        if (!claimInfo) {
            console.error("Claim information is missing");
            return;
        }
        console.log("Submitting agent info:", partInfo); // Log the agentInfo object before submitting

        try {
            const response = await api.put(
                `/claim/partsOrdered/${claimInfo.claimId}`,
                partInfo
            );

            console.log("Response:", response.data);


            setIsPartInfoSubmitted(true);
            setTimeout(() => {
                navigate("/tso-claims-table");
            }, 3000); // Delay navigation by 3 seconds
        } catch (error) {
            console.error("An error occurred while submitting agent information:", error);
            // Handle error and show a notification to the user
        }
    };


    const handleAgentInfoSubmit = async () => {
        if (!claimInfo) {
            console.error("Claim information is missing");
            return;
        }
        console.log("Submitting agent info:", agentInfo); // Log the agentInfo object before submitting

        try {
            const response = await api.put(
                `/claim/agent/${claimInfo.claimId}`,
                agentInfo
            );

            console.log("Response:", response.data);


            setIsAgentInfoSubmitted(true);
            setTimeout(() => {
                navigate("/tso-claims-table");
            }, 3000); // Delay navigation by 3 seconds
        } catch (error) {
            console.error("An error occurred while submitting agent information:", error);
            // Handle error and show a notification to the user
        }
    };



    const handleStatusChange = async (claimId: string) => {
        if (selectedStatus && claimInfo) {
            try {
                // Update the claim status using the PUT API endpoint
                const response = await api.put(
                    `/claim/status/${claimId}/${selectedStatus}`
                );

                console.log("Response:", response.data);

                // Set isStatusUpdated to true to show the success message
                setIsStatusUpdated(true);

                // Reset the selected status and hovered claim ID
                setSelectedStatus(null);

                // Update the claim status directly in the claimInfo object
                claimInfo.claimStatus = selectedStatus;

                // Hide the success message after 3 seconds
                setTimeout(() => {
                    navigate("/tso-claims-table");
                }, 3000);
            } catch (error) {
                console.error("An error occurred while updating the claim status:", error);
                // Handle any error, such as showing a notification to the user.
            }
        }
    };

    const handleStatusOptionClick = (status: string) => {
        setSelectedStatus(status);
        setShowStatusOptions(false);
    };

    useEffect(() => {
        if (claimInfo?.claimId && selectedStatus) {
            handleStatusChange(claimInfo.claimId);
        }
    }, [claimInfo, selectedStatus]);


    return (


        <>
            <div className="lg:container container mx-auto mt-10 dark:bg-gray-800 flex flex-wrap">
                {fetchState === FetchState.LOADING && <p>Loading...</p>}
                {fetchState === FetchState.ERROR && <p>Error fetching claim information.</p>}
                {fetchState === FetchState.SUCCESS && (
                    <div className="w-full">
                        <div className="lg:container mx-auto dark:bg-gray-800 flex justify-end flex-col">
                            <div className="flex lg:container mx-auto mb-4">
                                <div className="relative w-1/6 mr-auto">
                                    <div className="flex self-end mb-8">
                                        <h2 className="mr-5 font-bold dark:text-white">Claim#{claimInfo?.claimId}</h2>
                                    </div>
                                    <div className="flex mt-4 mb-8">
                                        <h2 className="text-xl mr-2">Status</h2>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="blue" className="w-6 h-6">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                                            />
                                        </svg>
                                    </div>
                                    {isStatusUpdated && (
                                        <div className="overlay">
                                            <div className="mb-4 bg-white border-l-4 border-green-500 text-green-700 p-4 text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                <p className="font-bold text-xl">Status is changed successfully</p>
                                            </div>
                                        </div>
                                    )}
                                    <button
                                        className={`w-full text-left flex justify-between items-center px-4 py-2 text-sm border ${
                                            selectedStatus || claimInfo?.claimStatus === "OPEN" ? "dark:text-green-500 dark:bg-gray-900  text-green-800" :
                                                selectedStatus || claimInfo?.claimStatus === "INPROGRESS" ? "dark:text-purple-500 dark:bg-gray-900  text-purple-800" :
                                                    selectedStatus || claimInfo?.claimStatus === "CLOSED" ? "dark:text-red-500 dark:bg-gray-900 0 text-red-800" :
                                                        selectedStatus || claimInfo?.claimStatus === "PARTSREQUIRED" ? "dark:text-yellow-500 dark:bg-gray-900  text-yellow-800" :
                                                            selectedStatus || claimInfo?.claimStatus === "PARTSORDERED" ? "dark:text-purple-500 dark:bg-gray-900  text-orange-800" :
                                                                "hover:bg-green-100 hover:text-green-800"
                                        }`}
                                        onClick={() => setShowStatusOptions(!showStatusOptions)}
                                    >
                                        {(selectedStatus || claimInfo?.claimStatus) === "OPEN" && (
                                            <span className="inline-flex items-center mr-2">
                                                <span className="w-2 h-2 mr-2 bg-green-500 rounded-full"></span>
                                             Open
                                             </span>
                                        )}
                                        {(selectedStatus || claimInfo?.claimStatus) === "CLOSED" && (
                                            <span className="inline-flex items-center mr-2">
                                                <span className="w-2 h-2 mr-2 bg-red-500 rounded-full"></span>
                                             Closed
                                             </span>
                                        )}
                                        {(selectedStatus || claimInfo?.claimStatus) === "PARTSREQUIRED" && (
                                            <span className="inline-flex items-center mr-2">
                                          <span className="w-2 h-2 mr-2 bg-yellow-500 rounded-full"></span>
                                          Parts Required
                                        </span>
                                        )}
                                        {(selectedStatus || claimInfo?.claimStatus) === "PARTSORDERED" && (
                                            <span className="inline-flex items-center mr-2">
                                          <span className="w-2 h-2 mr-2 bg-orange-500 rounded-full"></span>
                                          Parts Ordered
                                        </span>
                                        )}
                                        {(selectedStatus || claimInfo?.claimStatus) === "INPROGRESS" && (
                                            <span className="inline-flex items-center mr-2">
                                          <span className="w-2 h-2 mr-2 bg-purple-500 rounded-full"></span>
                                          In Progress
                                        </span>
                                        )}
                                        {!selectedStatus && !claimInfo?.claimStatus && "Status"}
                                        {showStatusOptions ? <ArrowLongUpIcon className="h-4 w-4 text-gray-600" /> : <ArrowLongDownIcon className="h-4 w-4 text-gray-600" />}

                                    </button>
                                    {showStatusOptions && (
                                        <div className="absolute w-full z-10 mt-2 py-1 bg-white rounded-md shadow-lg dark:bg-gray-800 dark:text-white">
                                            <button
                                                className={`w-full text-left block px-4 py-2 text-sm dark:text-red-500 dark:bg-gray-900 ${
                                                    selectedStatus === "CLOSED" ? "bg-red-100 text-red-800" : "hover:bg-red-100 hover:text-red-800"
                                                }`}
                                                onClick={() => handleStatusOptionClick("CLOSED")}
                                            >
                                        <span className="inline-flex items-center mr-2">
                                          <span className="w-2 h-2 mr-2 bg-red-500 rounded-full"></span>
                                          Closed
                                        </span>
                                            </button>
                                        </div>
                                    )}
                                </div>


                            </div>
                            <div className="bg-white dark:p-5  dark:bg-gray-900">
                                <div className="w-[977px] h-[41px] text-zinc-900 mb-2 mt-5 text-2xl dark:text-white"> {t("claim-info.data-sub-by-dealer")}</div>
                                <hr/>
                                <div className="flex mt-4 mb-8">
                                    <h2 className="text-xl dark:text-white  mr-2">{t("claim-info.general-info")} </h2>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="blue" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                                    </svg>
                                </div>
                                <div className="flex flex-wrap justify-center w-full">
                                    <div className="w-full px-3 mb-8 ">
                                        <div className="bg-white dark:bg-gray-900 rounded-lg p-4">
                                            <div className="flex flex-wrap ">
                                                <div className="w-full text-end pr-6 md:w-1/2">
                                                    <h3 className="dark:text-white text-l text-gray-500 mb-2"> {t("claim-info.brand-name")}:</h3>
                                                </div>
                                                <div className="w-full md:w-1/2">
                                                    <p className="text-black dark:text-white">{claimInfo?.recalls[0]?.vehicle?.brand}</p>
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap">
                                                <div className="w-full md:w-1/2">
                                                    <h3 className="dark:text-white text-l text-end pr-6 text-gray-500 mb-2">{t("order-parts.dealer-name")}:</h3>
                                                </div>
                                                <div className="w-full md:w-1/2">
                                                    <p className="text-black dark:text-white">{claimInfo?.dealer.dealerName}</p>
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap">
                                                <div className="w-full md:w-1/2">
                                                    <h3 className="dark:text-white text-l text-end pr-6 text-gray-500 mb-2">{t("order-parts.dealer-address")}:</h3>
                                                </div>
                                                <div className="w-full md:w-1/2">
                                                    <p className="text-black dark:text-white">{claimInfo?.dealer.address}</p>
                                                </div>
                                            </div>
                                            {claimInfo?.recalls?.length === 0 ? (
                                                <div className="flex justify-center items-center mt-4 mb-8">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="lightblue" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                                    </svg>
                                                    <p className="ml-4 text-center">{t("claim-info.recall-not-found")} .</p>
                                                </div>
                                            ) : (
                                                <>
                                                    <div>
                                                        <div className="flex flex-wrap">
                                                            <div className="w-full md:w-1/2">
                                                                <h3 className="dark:text-white text-l text-end pr-6 text-gray-500 mb-2">VIN:</h3>
                                                            </div>
                                                            <div className="w-full md:w-1/2">
                                                                <p className="text-black dark:text-white">{claimInfo?.recalls[0]?.recallId?.vin}</p>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-wrap">
                                                            <div className="w-full text-end pr-6 md:w-1/2">
                                                                <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.model")}:</h3>
                                                            </div>
                                                            <div className="w-full md:w-1/2">
                                                                <p className="text-black dark:text-white">{claimInfo?.recalls[0]?.vehicle?.model}</p>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-wrap">
                                                            <div className="w-full md:w-1/2">
                                                                <h3 className="dark:text-white text-l text-end pr-6  text-gray-500 mb-2">{t("claim-info.model-year")}:</h3>
                                                            </div>
                                                            <div className="w-full md:w-1/2">
                                                                <p className="text-black dark:text-white">{claimInfo?.recalls[0]?.vehicle?.modelYear}</p>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-wrap">
                                                            <div className="w-full md:w-1/2">
                                                                <h3 className="dark:text-white text-l text-end pr-6  text-gray-500 mb-2">{t("claim-info.millage")}:</h3>
                                                            </div>
                                                            <div className="w-full md:w-1/2">
                                                                <p className="text-black dark:text-white">{claimInfo?.recalls[0]?.vehicle?.mileage} km</p>
                                                            </div>
                                                        </div>
                                                        {claimInfo?.recalls?.map((recall: any, index: number) => (
                                                            <div className="" key={index}>
                                                                <div className="flex flex-wrap" >
                                                                    <div className="w-full md:w-1/2">
                                                                        <h3 className="dark:text-white text-l text-end pr-6 text-gray-500 mb-2">{t("table.recall")} ID:</h3>
                                                                    </div>
                                                                    <div className="w-full md:w-1/2">
                                                                        <p className="text-black dark:text-white">{recall?.recallId?.recallId}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-wrap" key={index}>
                                                                    <div className="w-full md:w-1/2">
                                                                        <h3 className="dark:text-white text-l text-end pr-6 text-gray-500 mb-2">{t("claim-info.recall-desc")}:</h3>
                                                                    </div>
                                                                    <div className="w-full md:w-1/2">
                                                                        <p className="text-black dark:text-white">{recall?.recallDescription}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div className="flex flex-wrap">
                                                            <div className="w-full md:w-1/2">
                                                                <h3 className="text-l text-end pr-6 text-gray-500 mb-2 dark:text-white">{t("claim-info.attachment")}:</h3>
                                                            </div>
                                                            <div className="w-full md:w-1/2">
                                                                {claimInfo?.payment?.paymentId ? (
                                                                    claimInfo?.payment?.attachment.length > 0 ? (
                                                                        <button
                                                                            className={`flex mr-4 items-center w-fit px-4 py-1 mb-2 ${
                                                                                isDarkMode
                                                                                    ? "bg-gray-800 border-white text-white"
                                                                                    : "bg-white border-gray-300 text-black dark:bg-gray-800 dark:border-white dark:text-white"
                                                                            } border rounded-md text-sm font-medium hover:bg-black hover:text-white`}
                                                                            onClick={async () => {
                                                                                try {
                                                                                    const response = await api.get(`/payment/${claimInfo.payment.paymentId}/download`, {
                                                                                        responseType: "blob",
                                                                                    });

                                                                                    // Check if the blob is empty
                                                                                    if (response.data.size === 0) {
                                                                                        console.error("Received an empty attachment.");
                                                                                        return;
                                                                                    }
                                                                                    const contentType = response.headers["content-type"];

                                                                                    if (contentType === "application/zip") {
                                                                                        JSZip.loadAsync(response.data).then(async (zip) => {
                                                                                            const zipEntries = Object.keys(zip.files);

                                                                                            if (zipEntries.length === 1) {
                                                                                                const firstFile = zip.file(zipEntries[0]);
                                                                                                if (firstFile !== null) { // Null check
                                                                                                    const content = await firstFile.async("blob");
                                                                                                    const url = URL.createObjectURL(content);
                                                                                                    const a = document.createElement("a");
                                                                                                    a.href = url;
                                                                                                    a.download = zipEntries[0];
                                                                                                    document.body.appendChild(a);
                                                                                                    a.click();
                                                                                                    document.body.removeChild(a);
                                                                                                    URL.revokeObjectURL(url);
                                                                                                }
                                                                                            } else {
                                                                                                const blob = new Blob([response.data], { type: contentType });
                                                                                                const url = URL.createObjectURL(blob);
                                                                                                const a = document.createElement("a");
                                                                                                a.href = url;
                                                                                                a.download = `attachments_${claimInfo.payment.paymentId}.zip`;
                                                                                                document.body.appendChild(a);
                                                                                                a.click();
                                                                                                document.body.removeChild(a);
                                                                                                URL.revokeObjectURL(url);
                                                                                            }
                                                                                        });
                                                                                    } else {
                                                                                        const blob = new Blob([response.data], { type: contentType });
                                                                                        const url = URL.createObjectURL(blob);
                                                                                        const a = document.createElement("a");
                                                                                        a.href = url;
                                                                                        // Remove the comment from the template string
                                                                                        a.download = `attachment_${claimInfo.payment.paymentId}`;
                                                                                        document.body.appendChild(a);
                                                                                        a.click();
                                                                                        document.body.removeChild(a);
                                                                                        URL.revokeObjectURL(url);
                                                                                    }
                                                                                } catch (error) {
                                                                                    console.error("Error downloading attachment:", error);
                                                                                }
                                                                            }}
                                                                        >
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                fill="none"
                                                                                viewBox="0 0 24 24"
                                                                                strokeWidth={1.5}
                                                                                stroke={isDarkMode ? "white" : "currentColor"}
                                                                                className="w-6 h-6 mr-2"
                                                                            >
                                                                                <path
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                    d="M12 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                                                                />
                                                                            </svg>
                                                                            <p>{t("claim-info.download-attachment")}</p>
                                                                        </button>

                                                                    ) : (
                                                                        <p>{t("claim-info.not-available")}</p>
                                                                    )
                                                                ) : (
                                                                    <p>{t("claim-info.not-available")}</p>
                                                                )}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white dark:p-5 dark:bg-gray-900">
                                <div className="flex mt-4 mb-8">
                                    <h2 className="text-xl dark:text-white mr-2">{t("claim-info.reimbursement-info")}</h2>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="orange" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                                    </svg>
                                </div>
                                <div className="flex flex-wrap justify-center w-full">
                                    <div className="w-full md:w-1/2 px-3 mb-8">
                                        <div className="bg-white dark:bg-gray-900 rounded-lg p-4">
                                            <div className="flex flex-wrap">

                                            </div>
                                            {claimInfo?.payment==null ? (
                                                <div className="flex flex-start mt-4 mb-8 items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="lightblue" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                                    </svg>
                                                    <p className="ml-4 text-left">{t("claim-info.reimbursement-not-found")}</p>
                                                </div>
                                            ) : (
                                                <>
                                                    <div>
                                                        <div className="flex flex-wrap">
                                                            <div className="w-full text-end pr-6 md:w-1/2">
                                                                <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.workorder-number")}</h3>
                                                            </div>
                                                            <div className="w-full md:w-1/2">
                                                                <p className="text-black dark:text-white">{claimInfo?.payment?.workOrderNumber}</p>
                                                            </div>
                                                            <div className="w-full text-end pr-6 md:w-1/2">
                                                                <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.labor-charged")}:</h3>
                                                            </div>
                                                            <div className="w-full md:w-1/2">
                                                                <p className="text-black dark:text-white">{claimInfo?.payment?.labourCharged}</p>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-wrap">
                                                            <div className="w-full text-end pr-6 md:w-1/2">
                                                                <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.lop-code")}:</h3>
                                                            </div>
                                                            <div className="w-full md:w-1/2">
                                                                <p className="text-black dark:text-white">{claimInfo?.payment?.lop}</p>
                                                            </div>
                                                        </div>
                                                        {claimInfo?.payment?.paintCost ? (
                                                            <div className="flex flex-wrap">
                                                                <div className="w-full text-end pr-6 md:w-1/2">
                                                                    <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.cost-part-paiting")}:</h3>
                                                                </div>
                                                                <div className="w-full md:w-1/2">
                                                                    <p className="text-black dark:text-white">{claimInfo?.payment?.paintCost}</p>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="flex flex-wrap">
                                                                <div className="w-full text-end pr-6 md:w-1/2">
                                                                    <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.cost-part-paiting")}:</h3>
                                                                </div>
                                                                <div className="w-full md:w-1/2">
                                                                    <p className="text-black dark:text-white">{t("claim-info.not-available")}</p>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="flex flex-wrap">
                                                            <div className="w-full text-end pr-6 md:w-1/2">
                                                                <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.date-of-recall-completion")}:</h3>
                                                            </div>
                                                            <div className="w-full md:w-1/2">
                                                                <p className="text-black dark:text-white">{claimInfo?.payment.dateOfCompletion}</p>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-wrap" >
                                                            <div className="w-full text-end pr-6 md:w-1/2">
                                                                <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.invoice-number")}:</h3>
                                                            </div>
                                                            <div className="w-full md:w-1/2">
                                                                <p className="text-black dark:text-white">{claimInfo?.payment?.invoiceNumber} </p>
                                                            </div>
                                                        </div>
                                                        {claimInfo?.payment?.usedPart?.map((usedPart: any, index: number) => (
                                                            <div className="" key={index}>
                                                                <div className="flex flex-wrap">
                                                                    <div className="w-full text-end pr-6 md:w-1/2">
                                                                        <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.part-used")}:</h3>
                                                                    </div>
                                                                    <div className="w-full md:w-1/2">
                                                                        <p className="text-black dark:text-white">{usedPart?.part.partNo}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-wrap" >
                                                                    <div className="w-full text-end pr-6 md:w-1/2">
                                                                        <h3 className=" dark:text-whitetext-l text-gray-500 mb-2">{t("claim-info.part-quantity")}:</h3>
                                                                    </div>
                                                                    <div className="w-full md:w-1/2">
                                                                        <p className="text-black dark:text-white">{usedPart?.count}</p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white dark:p-5 dark:bg-gray-900">
                                <div className="flex mt-4 mb-8">
                                    <h2 className="text-xl dark:text-white mr-2">{t("claim-info.parts")} </h2>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="green" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                                    </svg>
                                </div>
                                <div className="flex flex-wrap justify-center w-full">
                                    <div className="w-full md:w-1/2 px-3 mb-8">
                                        <div className="bg-white dark:bg-gray-900 rounded-lg p-4">
                                            {claimInfo?.order === null ? (
                                                <div className="flex mt-4 mb-8">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="lightblue" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                                    </svg>
                                                    <p className="ml-4 dark:text-white">{t("claim-info.part-not-found")}</p>
                                                </div>

                                            ) : (
                                                <>
                                                    <div>
                                                        {claimInfo?.order?.orderPart?.map((part: any, index: number) => (
                                                            <div className="" key={index}>
                                                                <div className="flex flex-wrap" >
                                                                    <div className="w-full text-end pr-6 md:w-1/2">
                                                                        <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.part-number")}:</h3>
                                                                    </div>
                                                                    <div className="w-full md:w-1/2">
                                                                        <p className="text-black dark:text-white">{part?.part.partNo}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-wrap" key={index}>
                                                                    <div className="w-full text-end pr-6 md:w-1/2">
                                                                        <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.part-quantity")}:</h3>
                                                                    </div>
                                                                    <div className="w-full md:w-1/2">
                                                                        <p className="text-black dark:text-white">{part?.count} </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                (claimInfo?.claimStatus === "INPROGRESS" || claimInfo?.claimStatus === "CLOSED" || claimInfo?.claimStatus === "PARTSORDERED" || claimInfo?.claimStatus === "OPEN") && (
                                    <div className="bg-white dark:p-5 dark:bg-gray-900">
                                        <div className="w-[977px] h-[41px] text-zinc-900 mb-2 text-2xl ">{t("claim-info.data-submit-by-agent")}</div>
                                        <hr/>
                                        <div className="flex mt-4 mb-8">
                                            <h2 className="text-xl dark:text-white mr-2">{t("claim-info.agent-part-info")} </h2>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="green" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                                            </svg>
                                        </div>
                                        <div className="flex flex-wrap justify-center w-full">
                                            <div className="w-full md:w-1/2 px-3 mb-8">
                                                <div className="bg-white dark:bg-gray-900 rounded-lg p-4">
                                                    {claimInfo?.order === null ? (
                                                        <div className="flex mt-4 mb-8">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="lightblue" className="w-6 h-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                                            </svg>
                                                            <p className="ml-4">{t("claim-info.no-part-ordered")}</p>
                                                        </div>

                                                    ) : (
                                                        <>
                                                            <div className="flex flex-wrap" >
                                                                <div className="w-full text-end pr-6 md:w-1/2">
                                                                    <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.order-creation-date")}:</h3>
                                                                </div>
                                                                <div className="w-full md:w-1/2">
                                                                    <p className="text-black dark:text-white">{claimInfo?.order?.creationDate} </p>
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-wrap" >
                                                                <div className="w-full text-end pr-6 md:w-1/2">
                                                                    <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.date-order")}:</h3>
                                                                </div>
                                                                <div className="w-full md:w-1/2">
                                                                    <p className="text-black dark:text-white">{claimInfo?.order?.sapOrderEnteredDate} </p>
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-wrap" >
                                                                <div className="w-full text-end pr-6 md:w-1/2">
                                                                    <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.sap-number")}:</h3>
                                                                </div>
                                                                <div className="w-full md:w-1/2">
                                                                    <p className="text-black dark:text-white">{claimInfo?.order?.sapOrderNumber} </p>
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-wrap" >
                                                                <div className="w-full text-end pr-6 md:w-1/2">
                                                                    <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.order-parts-remarks")}:</h3>
                                                                </div>
                                                                <div className="w-full md:w-1/2">
                                                                    <p className="text-black dark:text-white">{claimInfo?.order?.partsOrderRemarks} </p>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                            {
                                (claimInfo?.claimStatus === "INPROGRESS" || claimInfo?.claimStatus === "CLOSED") && (
                                    <div className="bg-white dark:p-5 dark:bg-gray-900">
                                        <div className="flex mt-4 mb-8">
                                            <h2 className="dark:text-white text-xl  mr-2">{t("claim-info.agent-reimbursement-info")}</h2>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="orange" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                                            </svg>
                                        </div>
                                        <div className="flex flex-wrap justify-center w-full">
                                            <div className="w-full md:w-1/2 px-3 mb-8">
                                                <div className="bg-white dark:bg-gray-900 rounded-lg p-4">
                                                    <div className="flex flex-wrap">

                                                    </div>
                                                    {claimInfo?.agentInformation==null ? (
                                                        <div className="flex flex-start mt-4 mb-8 items-center">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="lightblue" className="w-6 h-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                                            </svg>
                                                            <p className="ml-4 dark:text-white text-left">{t("claim-info.reimbursement-not-found")}.</p>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div>
                                                                <div className="flex flex-wrap">
                                                                    <div className="w-full text-end pr-6 md:w-1/2">
                                                                        <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.claim-receive-date")}:</h3>
                                                                    </div>
                                                                    <div className="w-full md:w-1/2">
                                                                        <p className="text-black dark:text-white ">{claimInfo?.creationDate}</p>
                                                                    </div>
                                                                    <div className="w-full text-end pr-6 md:w-1/2">
                                                                        <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.date-claim")}:</h3>
                                                                    </div>
                                                                    <div className="w-full md:w-1/2">
                                                                        <p className="text-black dark:text-white">{claimInfo?.agentInformation?.dateClaimEntered}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-wrap">
                                                                    <div className="w-full text-end pr-6 md:w-1/2">
                                                                        <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.total-part-cost")}:</h3>
                                                                    </div>
                                                                    <div className="w-full md:w-1/2">
                                                                        <p className="text-black dark:text-white">{claimInfo?.agentInformation?.totalPartCost}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-wrap">
                                                                    <div className="w-full text-end pr-6 md:w-1/2">
                                                                        <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.hourly-rate")}:</h3>
                                                                    </div>
                                                                    <div className="w-full md:w-1/2">
                                                                        {/* Display the hourly rate from claimInfo if available */}
                                                                        {claimInfo && claimInfo.dealer?.hourlyRate && (
                                                                            <div className="col-span-1">
                                                                                <p className="text-black dark:text-white">{claimInfo.dealer.hourlyRate}</p>
                                                                            </div>
                                                                        )}
                                                                        {/*<p className="text-black dark:text-white">{claimInfo.dealer?.countryCode}</p>*/}
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-wrap" >
                                                                    <div className="w-full text-end pr-6 md:w-1/2">
                                                                        <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.amount-billed")}:</h3>
                                                                    </div>
                                                                    <div className="w-full md:w-1/2">
                                                                        <p className="text-black dark:text-white">{claimInfo?.agentInformation?.amountBilled} </p>
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-wrap" >
                                                                    <div className="w-full text-end pr-6 md:w-1/2">
                                                                        <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.return-fca")}:</h3>
                                                                    </div>
                                                                    <div className="w-full md:w-1/2">
                                                                        <p className="text-black dark:text-white">{claimInfo?.agentInformation?.returnFca} </p>
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-wrap" >
                                                                    <div className="w-full text-end pr-6 md:w-1/2">
                                                                        <h3 className="dark:text-white text-l text-gray-500 mb-2">{t("claim-info.return-fca-without-part")}:</h3>
                                                                    </div>
                                                                    <div className="w-full md:w-1/2">
                                                                        <p className="text-black dark:text-white">{claimInfo?.agentInformation?.returnFcaWoPart} </p>
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-wrap" >
                                                                    <div className="w-full text-end pr-6 md:w-1/2">
                                                                        <h3 className=" dark:text-white text-l text-gray-500 mb-2">{t("claim-info.remarks")}:</h3>
                                                                    </div>
                                                                    <div className="w-full md:w-1/2">
                                                                        <p className="text-black dark:text-white">{claimInfo?.agentInformation.remarks} </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}


                        </div>
                    </div>
                )}
            </div>


            {
                claimInfo?.claimStatus === "PARTSREQUIRED" &&  (
                    <div className="lg:container mx-auto mb-4 mt-4 px-4 dark:bg-gray-900">
                        <div className="grid grid-cols-1 mt-2">

                            <form className="grid grid-cols-1 gap-4 lg:grid-cols-2 md:grid-cols-2">
                                <h2 className="text-2xl mb-6 dark:text-white">TSO: Part required Information</h2>
                                <h2></h2>
                                <div className="col-span-1">
                                    <label className="block mb-1 text-gray-700 text-xs dark:text-white">
                                        Order Receive Date
                                    </label>
                                    <input
                                        type="date"
                                        value={claimInfo?.order?.creationDate ?? ""}
                                        className="w-full border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md bg-blue-50"
                                        readOnly={true}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <label className="block mb-1 text-gray-700 text-xs dark:text-white">
                                        Date Order Entered (into SAP)
                                    </label>
                                    <input
                                        type="date"
                                        value={partInfo.sapOrderEnteredDate ?? ""}
                                        onChange={(e) =>
                                            setPartInfo({ ...partInfo, sapOrderEnteredDate: e.target.value })
                                        }
                                        className="w-full border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md bg-blue-50"
                                    />
                                </div>
                                <div className="col-span-1">
                                    <label className="block mb-1 text-gray-700 text-xs dark:text-white">
                                        SAP sales Order Number
                                    </label>
                                    <input
                                        type="text"
                                        value={partInfo.sapOrderNumber}
                                        onChange={(e) =>
                                            setPartInfo({ ...partInfo, sapOrderNumber: e.target.value })
                                        }
                                        className="w-full border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md bg-blue-50"
                                    />
                                </div>
                                <div className="col-span-1">
                                    <label className="block mb-1 text-gray-700 text-xs dark:text-white">
                                        Order Parts Remarks
                                    </label>
                                    <input
                                        type="text"
                                        value={partInfo.partsOrderRemarks ?? ""}
                                        className="w-full border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md bg-blue-50"
                                        onChange={(e) =>
                                            setPartInfo({ ...partInfo, partsOrderRemarks: e.target.value })
                                        }
                                    />
                                </div>

                                {isPartInfoSubmitted && (
                                    <div className="overlay">
                                        <div className="mb-4 bg-white border-l-4 border-green-500 text-green-700 p-4 text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                            <p className="font-bold text-xl"> {t("claim-info.save")}</p>
                                        </div>
                                    </div>
                                )}
                                <button                         className="text-white w-1/6 bg-gray-700 hover:bg-gray-800 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 px-5 mr-2 mb-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 mr-2 "
                                                                type="button" onClick={handlePartInfoSubmit}> {t("claim-info.save")}</button>
                            </form>

                        </div>
                    </div>
                )}
            {
                (claimInfo?.claimStatus === "INPROGRESS" || claimInfo?.claimStatus === "OPEN") &&  (
                    <div className="lg:container mx-auto p-4  mt-4 mb-4 ">
                        <div className="bg-white p-4  dark:bg-gray-900 dark:text-white">
                            <form className="grid grid-cols-1 gap-4 lg:grid-cols-2 md:grid-cols-2">
                            <h2 className="text-2xl mb-6 dark:text-white">{t("claim-info.tso-reimbursement-req-info")}</h2>
                                <h2></h2>
                                <div className="col-span-1">
                                    <label className="block mb-1 text-gray-700 text-xs dark:text-white">
                                        {t("claim-info.claim-receive-date")}:
                                    </label>
                                    <input
                                        type="date"
                                        value={claimInfo?.creationDate ?? ""}
                                        className={`flex mr-4 items-center w-full px-4 py-2 ${
                                            isDarkMode
                                                ? "bg-gray-800 border-white text-white hover:bg-black hover:text-white"
                                                : "border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md bg-blue-50 dark:bg-gray-800 dark:border-white dark:text-white "
                                        } border rounded-md text-sm `}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <label className="block mb-1 text-gray-700 text-xs dark:text-white">
                                        {t("claim-info.date-claim-entered")}:
                                    </label>
                                    <input
                                        type="date"
                                        required
                                        value={agentInfo.dateClaimEntered ?? ""}
                                        onChange={(e) =>
                                            setAgentInfo({ ...agentInfo, dateClaimEntered: e.target.value })
                                        }
                                        className={`flex mr-4 items-center w-full px-4 py-2 ${
                                            isDarkMode
                                                ? "bg-gray-800 border-white text-white hover:bg-black hover:text-white"
                                                : "border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md bg-blue-50 dark:bg-gray-800 dark:border-white dark:text-white "
                                        } border rounded-md text-sm `}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <label className="block mb-1 text-gray-700 text-xs dark:text-white">
                                        {t("claim-info.total-part-cost")}:
                                    </label>
                                    <input
                                        type="text"
                                        value={agentInfo.totalPartCost ?? ""}
                                        onChange={(e) => {
                                            const formattedValue = formatDecimalInput(e.target.value);
                                            setAgentInfo({ ...agentInfo, totalPartCost: formattedValue });
                                        }}
                                        className={`flex mr-4 items-center w-full px-4 py-2 ${
                                            isDarkMode
                                                ? "bg-gray-800 border-white text-white hover:bg-black hover:text-white"
                                                : "border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md bg-blue-50 dark:bg-gray-800 dark:border-white dark:text-white "
                                        } border rounded-md text-sm `}                                    />
                                </div>
                                <div className="col-span-1">
                                    <label className="block mb-1 text-gray-700 text-xs dark:text-white">
                                        {t("claim-info.hourly-rate")}:
                                    </label>
                                    <input
                                        type="text"
                                        value={hourlyRateManuallySet ? agentInfo.hourlyRate : claimInfo.dealer.hourlyRate}
                                        onChange={(e) => {
                                            // Use formatDecimalInput to control the input format
                                            const formattedValue = formatDecimalInput(e.target.value);
                                            setAgentInfo({ ...agentInfo, hourlyRate: formattedValue });
                                            setHourlyRateManuallySet(true);
                                        }}
                                        className={`flex mr-4 items-center w-full px-4 py-2 ${
                                            isDarkMode
                                                ? "bg-gray-800 border-white text-white hover:bg-black hover:text-white"
                                                : "border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md bg-blue-50 dark:bg-gray-800 dark:border-white dark:text-white "
                                        } border rounded-md text-sm `}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <label className="block mb-1 text-gray-700 text-xs dark:text-white">
                                        {t("claim-info.amount-billed")}:
                                    </label>
                                    <input
                                        type="text"
                                        value={agentInfo.amountBilled ?? ""}
                                        onChange={(e) => {
                                            const formattedValue = formatDecimalInput(e.target.value);
                                            setAgentInfo({ ...agentInfo, amountBilled: formattedValue });
                                        }}
                                        className={`flex mr-4 items-center w-full px-4 py-2 ${
                                            isDarkMode
                                                ? "bg-gray-800 border-white text-white hover:bg-black hover:text-white"
                                                : "border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md bg-blue-50 dark:bg-gray-800 dark:border-white dark:text-white "
                                        } border rounded-md text-sm `}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <label className="block mb-1 text-gray-700 text-xs dark:text-white">
                                        {t("claim-info.return-fca")}:
                                    </label>
                                    <input
                                        type="text"
                                        value={agentInfo.returnFca ?? ""}
                                        onChange={(e) => {
                                            const formattedValue = formatDecimalInput(e.target.value);
                                            setAgentInfo({ ...agentInfo, returnFca: formattedValue });
                                        }}
                                        className={`flex mr-4 items-center w-full px-4 py-2 ${
                                            isDarkMode
                                                ? "bg-gray-800 border-white text-white hover:bg-black hover:text-white"
                                                : "border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md bg-blue-50 dark:bg-gray-800 dark:border-white dark:text-white "
                                        } border rounded-md text-sm `}
                                    />
                                </div>

                                <div className="col-span-1">
                                    <label className="block mb-1 text-gray-700 text-xs dark:text-white">
                                        {t("claim-info.return-fca-without-part")}:
                                    </label>
                                    <input
                                        type="text"
                                        value={agentInfo.returnFcaWoPart ?? ""}
                                        onChange={(e) => {
                                            const formattedValue = formatDecimalInput(e.target.value);
                                            setAgentInfo({ ...agentInfo, returnFcaWoPart: formattedValue });
                                        }}
                                        className={`flex mr-4 items-center w-full px-4 py-2 ${
                                            isDarkMode
                                                ? "bg-gray-800 border-white text-white hover:bg-black hover:text-white"
                                                : "border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md bg-blue-50 dark:bg-gray-800 dark:border-white dark:text-white "
                                        } border rounded-md text-sm `}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <label className="block mb-1 text-gray-700 text-xs dark:text-white">
                                        {t("claim-info.remarks")}:
                                    </label>
                                    <input
                                    type="text"
                                    value={agentInfo.remarks ?? ""}
                                    onChange={(e) => {
                                    const formattedValue = formatDecimalInput(e.target.value);
                                    setAgentInfo({ ...agentInfo, remarks: formattedValue });
                                    }}
                                        className={`flex mr-4 items-center w-full px-4 py-2 ${
                                            isDarkMode
                                                ? "bg-gray-800 border-white text-white hover:bg-black hover:text-white"
                                                : "border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md bg-blue-50 dark:bg-gray-800 dark:border-white dark:text-white "
                                        } border rounded-md text-sm `}
                                    />
                                </div>
                                <div className="col-span-2 flex">
                                    <label className="block mb-1 text-gray-700 text-xs mr-2 dark:text-white">
                                        {t("claim-info.under-review")}:
                                    </label>
                                    <input
                                        type="checkbox"
                                        checked={!!agentInfo?.underReview}
                                        onChange={(e) => setAgentInfo({ ...agentInfo, underReview: e.target.checked })}
                                        className="form-checkbox h-5 w-5 text-blue-600"
                                    />
                                </div>
                                {isAgentInfoSubmitted && (
                                    <div className="overlay">
                                        <div className="mb-4 bg-white border-l-4 border-green-500 text-green-700 p-4 text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                            <p className="font-bold text-xl">{t("claim-info.successfully-saved")}</p>
                                        </div>
                                    </div>
                                )}
                                <button className="text-white w-1/6 bg-gray-700 hover:bg-gray-800 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 px-5 mr-2 mb-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 mr-2 " type="button" onClick={handleAgentInfoSubmit}>{t("claim-info.save")}</button>
                            </form>

                        </div>
                    </div>
                )}
            {
                claimInfo?.claimStatus !== "CLOSED" && (
                     <div className="lg:container mx-auto p-4 pt-12 mt-8 mb-20  dark:bg-gray-800 ">
                        <div className="bg-gray-50 p-4 pt-12 mt-8 mb-20 border dark:bg-gray-800 dark:border-white dark:text-white">
                            <h2 className="text-xl mr-2 pl-12">{t("claim-info.support")}</h2>
                            <div ref={chatContainerRef} className="mt-8 ml-8 mr-4 message-content dark:text-black" style={{ overflowY: "auto", overflowX: "auto" }}>
                                {messages.map((message, index) => (
                                    <div
                                        key={message.messageId}
                                        className={`message shadow-lg flex-col ${
                                            message.messageBy === "autoport" ? "message-right" : "message-left"
                                        }`}
                                        style={{ display: "flex", flexDirection: "column" }}
                                    >
                                        <div className="pr-2 pl-2 ">
                                            {/* Conditionally render different images */}
                                            {message.messageBy === "autoport" ? (
                                                <img
                                                    alt="Dealer-logo"
                                                    src="/assets/images/supportdealer.png"
                                                    className="pl-10 float-right ml-4 supportimg"
                                                    width={50} // Adjust the width as needed
                                                    height={50} // Adjust the height as needed
                                                />
                                            ) : (
                                                <img
                                                    alt="Agent-logo"
                                                    src="/assets/images/supportagent.png"
                                                    className="pl-10 mr-4 float-left supportimg"
                                                    width={50} // Adjust the width as needed
                                                    height={50} // Adjust the height as needed
                                                />
                                            )}
                                            <div className="text-lg ">
                                                {message.messageBy === "autoport"
                                                    ? userData?.name
                                                    : message.messageBy === claimInfo?.dealer.dealerName
                                                        ? claimInfo?.dealer.dealerName
                                                        : claimInfo?.dealer.dealerName}
                                            </div>
                                            <div>{message.message}</div>
                                        </div>
                                    </div>

                                ))}
                            </div>

                            <div className="ml-8 mr-88 mt-12 mb-12 ">
        <textarea
            className="border rounded p-2 w-full border-gray-300 mt-4 dark:bg-gray-800 dark:border-white dark:text-white"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder= {String(t("claim-info.type-your-msg"))}
        />
                                <button
                                    className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded mt-2"
                                    onClick={sendMessage}
                                >
                                    {t("claim-info.send-msg")}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
        </>
    );
};

export default TSOClaimView;