import { Route, Routes } from "react-router-dom";
import { routerType } from "./types";
import pagesData from "./pagesData";

const Router = () => {
    const pageRoutes = pagesData.map(({ path, title, element }: routerType) => {
        // eslint-disable-next-line react/react-in-jsx-scope
        return <Route key={title} path={`/${path}`} element={element} />;
    });

    // eslint-disable-next-line react/react-in-jsx-scope
    return <Routes>{pageRoutes}</Routes>;
};

export default Router;