import {useCallback, useEffect, useState} from "react";
import {FetchState, CreditMemosDetails, RecallData, Claim, Recall, RecallInput,CreditMemosClaims} from "../types";
import {useParams} from "react-router-dom";
import api from "../api";
import JSZip from "jszip";

export function getRecallAll() {
    const [fetchState, setFetchState] = useState(FetchState.DEFAULT);
    const [tableData, setTableData] = useState<Array<RecallData>>([]);
    const getTableData = async () => {
        try {
            setFetchState(FetchState.LOADING);
            const res = await api.get("/recall/getAll", { timeout: 20000 });
            const resData = res.data as Array<RecallData>;
            setTableData(resData);
            setFetchState(FetchState.SUCCESS);
        } catch (err) {
            setFetchState(FetchState.ERROR);
        }
    };

    return [tableData, fetchState, getTableData, setTableData] as const;
}
export const getRecallByVINData = (vin: string): [Recall[], FetchState, () => void] => {
    const [tableData, setTableData] = useState<Recall[]>([]);
    const [fetchState, setFetchState] = useState<FetchState>(FetchState.DEFAULT);

    const getTableData = useCallback(async () => {
        try {
            setFetchState(FetchState.LOADING);
            const response = await api.get(`/recall/getByVIN/${vin}`);
            const data = response.data;
            setTableData(data);
            setFetchState(FetchState.SUCCESS);
        } catch (error) {
            console.error("Error fetching recall data:", error);
            setFetchState(FetchState.ERROR);
        }
    }, [vin]);

    return [tableData, fetchState, getTableData];
};
export const getNewRecallByVINData = (vin: string): [Recall[], FetchState, () => void] => {
    const [tableData, setTableData] = useState<Recall[]>([]);
    const [fetchState, setFetchState] = useState<FetchState>(FetchState.DEFAULT);

    const getTableData = useCallback(async () => {
        try {
            setFetchState(FetchState.LOADING);
            const response = await api.get(`/recall/getNewByVIN/${vin}`);
            const data = response.data;
            setTableData(data);
            setFetchState(FetchState.SUCCESS);
        } catch (error) {
            console.error("Error fetching recall data:", error);
            setFetchState(FetchState.ERROR);
        }
    }, [vin]);

    return [tableData, fetchState, getTableData];
};
export function useFetchClaims(dealerId?: string) {
    const [fetchState, setFetchState] = useState(FetchState.DEFAULT);
    const [claims, setClaims] = useState<Claim[]>([]);

    useEffect(() => {
        const fetchClaims = async () => {
            try {
                setFetchState(FetchState.LOADING);

                // Use your 'api' instance to make the API request
                const apiUrl = dealerId ? `/claim/getByDealer/${dealerId}` : "/claim/getAll";
                const res = await api.get(apiUrl, { timeout: 20000 });

                const data = res.data as Claim[];
                setClaims(data);
                setFetchState(FetchState.SUCCESS);
            } catch (error) {
                console.error("Error fetching claims:", error);
                setFetchState(FetchState.ERROR);
            }
        };

        fetchClaims();
    }, [dealerId]);

    return { claims, fetchState };
}
export const addNewRecall = async (recallData: RecallInput) => {
    try {
        // Construct the API endpoint by substituting the recallId and vin path parameters
        const endpoint = `/recall/update/${recallData.recallId}/${recallData.vin}`;

        // Make an HTTP PUT request to the API endpoint for updating recalls
        const response = await api.put(endpoint, recallData);
        const newRecall = response.data;

        // Handle the response or update the state as needed
        console.log("New recall added:", newRecall);
    } catch (error) {
        console.error("Error adding new recall:", error);
        // Handle error state or display an error message to the user
    }
};
export const useGetClaimInfo = (): [Claim | null, FetchState] => {
    const { claimId } = useParams();

    const [claimInfo, setClaimInfo] = useState<Claim | null>(null);
    const [fetchState, setFetchState] = useState<FetchState>(FetchState.DEFAULT);

    const getClaimInfoData = async () => {
        try {
            setFetchState(FetchState.LOADING);
            const res = await api.get(`/claim/${claimId}`);
            const claimData = res.data as Claim;
            setClaimInfo(claimData);
            setFetchState(FetchState.SUCCESS);
        } catch (error) {
            console.error("Error fetching claim info:", error);
            setFetchState(FetchState.ERROR);
        }
    };
    useEffect(() => {
        getClaimInfoData();
    }, []);

    return [claimInfo, fetchState];
};
export const useGetCreditMemos = (): [any[], FetchState] => {
    const [fetchState, setFetchState] = useState(FetchState.DEFAULT);
    const [creditMemos, setCreditMemos] = useState<any[]>([]);

    useEffect(() => {
        const fetchCreditMemos = async () => {
            try {
                setFetchState(FetchState.LOADING);
                const res = await api.get("/report/getAll");
                const data = res.data;
                setCreditMemos(data);
                setFetchState(FetchState.SUCCESS);
            } catch (error) {
                setFetchState(FetchState.ERROR);
            }
        };

        fetchCreditMemos();
    }, []);

    return [creditMemos, fetchState];
};
export const useDownloadSingleReport = () => {
    const downloadSingleReport = async (reportId:any) => {
        try {
            const reportResponse = await api.get(`/report/${reportId}`);
            const reportData = reportResponse.data;
            const fileName = reportData.reportName || `report_${reportId}`;
            const contentType = reportData.fileType;
            const reportFileContent = atob(reportData.reportFileContent);

            let fileExtension = ".dat";
            if (contentType.includes("pdf")) {
                fileExtension = ".pdf";
            } else if (contentType.includes("csv")) {
                fileExtension = ".csv";
            }

            return {
                fileName: fileName + fileExtension,
                content: reportFileContent,
                mimeType: contentType,
            };
        } catch (error) {
            console.error("Error downloading report:", error);
        }
    };

    return downloadSingleReport;
};
export const useDownloadAllReports = async (filteredCreditMemos:any) => {
    try {
        const zip = new JSZip();
        const downloadSingleReport = useDownloadSingleReport();

        for (const memo of filteredCreditMemos) {
            const downloadResult = await downloadSingleReport(memo.reportId);
            if (downloadResult) {
                const { content, fileName } = downloadResult;
                zip.file(fileName, content, { binary: true });
            } else {
                console.error("Error downloading report: No download result");
            }
        }

        const zipContent = await zip.generateAsync({ type: "blob" });
        const url = URL.createObjectURL(zipContent);
        const a = document.createElement("a");
        a.href = url;
        a.download = "credit-memos.zip";
        a.click();
        URL.revokeObjectURL(url);
    } catch (error) {
        console.error("Error downloading reports:", error);
    }
};
type Report = {
    content: string;
    mimeType: string;
    fileName: string;
};
export function useFetchClosedClaims() {
    const [fetchState, setFetchState] = useState(FetchState.DEFAULT);
    const [claims, setClaims] = useState<CreditMemosClaims[]>([]);

    useEffect(() => {
        const fetchClaims = async () => {
            try {
                setFetchState(FetchState.LOADING);
                const apiUrl = "/recall/vinsByDealer";
                const res = await api.get(apiUrl);
                const data = res.data as CreditMemosClaims[];
                setClaims(data);
                setFetchState(FetchState.SUCCESS);
            } catch (error) {
                console.error("Error fetching claims:", error);
                setFetchState(FetchState.ERROR);
            }
        };

        fetchClaims();
    }, []);

    return { claims, fetchState };
}
export function TSOCreditMemoDetails() {
    const [fetchState, setFetchState] = useState(FetchState.DEFAULT);
    const [creditMemoDetails, setCreditMemoDetails] = useState<CreditMemosDetails[] | null>(null);
    const { dealerId } = useParams();

    useEffect(() => {
        const fetchCreditMemoDetails = async () => {
            try {
                setFetchState(FetchState.LOADING);
                const res = await api.get(`/claim/closed/${dealerId}`);
                const data = res.data as CreditMemosDetails[];
                setCreditMemoDetails(data);
                setFetchState(FetchState.SUCCESS);
            } catch (error) {
                console.error("Error fetching credit memo details:", error);
                setFetchState(FetchState.ERROR);
            }
        };
        fetchCreditMemoDetails();
    }, [dealerId]);

    return { creditMemoDetails, fetchState };
}
export function useDownloadMonthReport() {
    const [fetchState, setFetchState] = useState(FetchState.DEFAULT);

    const downloadMonthRaport = (month:any, dealerIds:any) => {
        try {
            setFetchState(FetchState.LOADING);

            const xhr = new XMLHttpRequest();
            xhr.open("POST", "/reimbursement/export", true);
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.responseType = "blob";

            xhr.onreadystatechange = function () {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    if (xhr.status === 200) {
                        const blob = xhr.response;
                        setFetchState(FetchState.SUCCESS);
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.href = url;
                        a.download = "month_report.csv";
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                    } else {
                        setFetchState(FetchState.ERROR);
                        console.error("Network response was not ok");
                    }
                }
            };

            xhr.send(JSON.stringify({
                dealerIds,
                month,
                description: "Month Report",
            }));
        } catch (error) {
            setFetchState(FetchState.ERROR);
            console.error("Error downloading month report:", error);
        }
    };

    return { downloadMonthRaport, fetchState };
}
export function useSendReportToFinance() {
    const [fetchState, setFetchState] = useState(FetchState.DEFAULT);

    const sendReportToFinance = async (month:any, year:any, dealerIds:any, note:any) => {
        try {
            setFetchState(FetchState.LOADING);
            const requestBody = {
                month,
                year,
                dealerIds,
                note,
            };

            const apiUrl = "/email/send";
            const response = await api.post(apiUrl, requestBody);

            setFetchState(FetchState.SUCCESS);
            console.log("Report sent successfully!");
        } catch (error) {
            setFetchState(FetchState.ERROR);
            console.error("Error sending report:", error);
        }
    };
    return { sendReportToFinance, fetchState };
}
export const downloadClaims = async () => {
    try {
        const response = await api.get("/reports/downloadClaims", { responseType: "blob" });

        let fileName = "Claims Report.csv"; // Default CSV file name
        const contentDisposition = response.headers["content-disposition"];
        if (contentDisposition) {
            const matches = /filename="?(.+)"?/.exec(contentDisposition);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (matches.length === 2) {
                if (matches) {
                    fileName = matches[1];
                }
            }
        }

        // Ensure the file extension is .csv
        if (!fileName.endsWith(".csv")) {
            fileName += ".csv";
        }

        const url = window.URL.createObjectURL(new Blob([response.data], { type: "text/csv" }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error("Error downloading report:", error);
    }
};


export const downloadReportTSO = async () => {
    try {
        const response = await api.get("/reports/downloadRecalls", { responseType: "blob" });

        let fileName = "Recalls Report.csv"; // Default CSV file name
        const contentDisposition = response.headers["content-disposition"];
        if (contentDisposition) {
            const matches = /filename="?(.+)"?/.exec(contentDisposition);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (matches.length === 2) {
                if (matches) {
                    fileName = matches[1];
                }
            }
        }

        // Ensure the file extension is .csv
        if (!fileName.endsWith(".csv")) {
            fileName += ".csv";
        }

        const url = window.URL.createObjectURL(new Blob([response.data], { type: "text/csv" }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error("Error downloading report:", error);
    }
};
