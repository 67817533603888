import React from "react";
import { DateRangePicker } from "react-date-range";
import { createPortal } from "react-dom";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import "../../../App.css";

import { DateFilterProps } from "../../../types";
import {t} from "i18next";

const DateFilter: React.FC<DateFilterProps> = ({
                                                   filterDates,
                                                   handleDateFilter,
                                                   clearDateFilter,
                                                   openCalendar,
                                                   closeCalendar,
                                                   showCalendar,
                                                   addMargin,
                                               }) => {
    const handleClick = () => {
        if (showCalendar) {
            closeCalendar();
        } else {
            openCalendar();
        }
    };

    const handleClear = () => {
        clearDateFilter();
    };

    const renderCalendar = () => {
        if (showCalendar) {
            // Conditionally add margin-top if addMargin is true
            const calendarStyle = addMargin
                ? "claim-calendar mt-8 absolute w-72 bg-white border border-gray-200 rounded-lg p-4 shadow-lg z-30 mt-28" // Add the margin-top here
                : "claim-calendar mt-8 absolute w-72 bg-white border border-gray-200 rounded-lg p-4 shadow-lg z-30";
            return createPortal(
                <div className={calendarStyle}>
                    <DateRangePicker
                        ranges={[
                            {
                                startDate: filterDates[0] || undefined,
                                endDate: filterDates[1] || undefined,
                                key: "selection",
                            },
                        ]}
                        onChange={handleDateFilter}
                    />
                </div>,
                document.body
            );
        }
        return null;
    };

    return (
        <div className="relative inline-block text-left">
            <button
                className="text-white bg-black hover:bg-red-700 focus:ring-1 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={handleClick}
            >
                {filterDates[0] && filterDates[1]
                    ? `${filterDates[0].toLocaleDateString()} - ${filterDates[1].toLocaleDateString()}`
                    : t("credit-memos.select-dates")
                }
                <CalendarDaysIcon className="w-5 h-5 ml-2 -mr-1"></CalendarDaysIcon>
            </button>
            {filterDates[0] && filterDates[1] && (
                <button
                    className="text-red-500 hover:text-gray-700 focus:outline-none ml-1"
                    onClick={handleClear}
                >
                </button>
            )}
            {renderCalendar()}
        </div>
    );
};

export default DateFilter;
