import { routerType } from "./types";
import Home from "./components/pages/Home";
import VINSearch from "./components/pages/VINSearch";
import React from "react";
import ClaimsComponent from "./components/pages/ClaimsTable";
import CreateClaimComponent from "./components/pages/CreateClaim";
import ReimbursementComponent from "./components/pages/Reimbursement";
import ClaimInfo from "./components/pages/ClaimInfo";
import CreditMemos from "./components/pages/CreditMemos";
import TSOClaimsTable from "./components/pages/TSOClaimsTable";
import TSOClaimView from "./components/Layout/TSOClaimView";
import TSOCreditMemosPage from "./components/pages/TSOCreditMemosPage";
import TSOClaimDetails from "./components/pages/TSOClaimDetails";


const pagesData: routerType[] = [
    {
        path: "/",
        element: <Home />,
        title: "home",
    },
    {
        path: "/vin-search",
        element: <VINSearch />,
        title: "VINSearch",
    },
    {
        path: "/claims",
        element: <ClaimsComponent />,
        title: "Claims",
    },
    {
        path: "/create-claim",
        element: <CreateClaimComponent />,
        title: "CreateClaim",
    },
    {
        path: "/reimbursement",
        element: <ReimbursementComponent />,
        title: "Reimbursement",
    },
    {
        path: "/claim-info/:claimId",
        element: <ClaimInfo />,
        title: "ClaimInfo",
    },
    {
        path: "/credit-memos",
        element: <CreditMemos />,
        title: "Credit Memos",
    },
    {
        path: "/tso-claim-view/:claimId",
        element: <TSOClaimView />,
        title: "TSO claim View",
    },
    {
        path: "/tso-claims-table",
        element: <TSOClaimsTable />,
        title: "TSO Claims Table",
    },
    {
        path: "/tso-credit-memos-page",
        element: <TSOCreditMemosPage />,
        title: "TSO Credit Memos Page",
    },
    {
        path: "/tso-claim-details/:dealerId",
        element: <TSOClaimDetails />,
        title: "TSO claim details",
    },
];

export default pagesData;
