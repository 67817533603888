import React, {useEffect, useState} from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Flags from "country-flag-icons/react/3x2";
import {withTranslation} from "react-i18next";
import i18n from "../../../i18n";

function TopNavbar() {
    const [localStorageLang] = useState(localStorage.getItem("lang"));
    const [isOpen, setIsOpen] = useState(false);
    const handleOptionClick = () => setIsOpen(false);
    const toggleDropdownMenu = () => {
        setIsOpen(!isOpen);
    };
    const [flag, setFlag] = useState(<Flags.GB title="English" className="flag"/>);

    useEffect(() => {
        switch (localStorageLang) {
            case "de":
                setFlag(<Flags.DE title="Deutsch" className="flag"/>);
                break;
            case "en":
                setFlag(<Flags.GB title="English" className="flag"/>);
                break;
            default:
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                i18n.changeLanguage(localStorageLang).then();
                break;
        }
    }, [localStorageLang]);

    return (
        <nav
            className="p-2 flex justify-evenly title-primary w-full grid grid-cols-2 bg-black">
            <div className="flex float-left items-center col-start-1 col-end-2">
                <div className="relative">
                    <button
                        className="rounded-lg px-3 text-sm py-2 text-black hover:text-red-500 font-medium mr-4 transition duration-500 ease-in-out flex items-center"
                        onClick={toggleDropdownMenu}
                    >
                        {flag}
                    </button>
                    {isOpen && (
                        <div className="fixed top-15 left-0 z-50 bg-black rounded-lg p-3">
                            <button
                                className="block px-4 py-2"
                                onClick={() => {
                                    i18n.changeLanguage("en", () => {
                                        localStorage.setItem("lang", "en");
                                    }).then();
                                    setFlag(<Flags.GB title="English" className="flag"/>);
                                    handleOptionClick();
                                }}
                            >
                                <Flags.GB title="English" className="flag"/>
                            </button>
                            <button
                                className="block px-4 py-2"
                                onClick={() => {
                                    i18n.changeLanguage("de", () => {
                                        localStorage.setItem("lang", "de");
                                    }).then();
                                    setFlag(<Flags.DE title="Deutsch" className="flag"/>);
                                    handleOptionClick();
                                }}
                            >
                                <Flags.DE title="Deutsch" className="flag"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
}

export default withTranslation()(TopNavbar);