import React, { useState } from "react";
import { FetchState } from "../../../types";
import { Table } from "flowbite-react";
import { useGetCreditMemos,useDownloadSingleReport,useDownloadAllReports } from "../../../lib/api-hooks";
import DateFilter from "../../Layout/DateFilterComponent";
import { XCircleIcon } from "@heroicons/react/20/solid";
import {t} from "i18next";
import JSZip from "jszip";

const CreditMemos = () => {
    const [creditMemos, fetchState] = useGetCreditMemos();
    const downloadSingleReport = useDownloadSingleReport();
    const [filterDates, setFilterDates] = useState<[Date | null, Date | null]>([null, null]);
    const [showCalendar, setShowCalendar] = useState(false);
    const isDarkMode = false;
    const addMargin = true;

    const handleDateFilter = (ranges: any) => {
        const startDate = ranges.selection.startDate;
        const endDate = ranges.selection.endDate;

        if (startDate.toDateString() === endDate.toDateString()) {
            setFilterDates([startDate, null]);
        } else {
            setFilterDates([startDate, endDate]);
        }
    };

    const clearDateFilter = () => {
        setFilterDates([null, null]);
    };

    const openCalendar = () => {
        setShowCalendar(true);
    };

    const closeCalendar = () => {
        setShowCalendar(false);
    };

    const filteredCreditMemos = Array.isArray(creditMemos)
        ? creditMemos.filter((memo: any) => {
            const startDate = filterDates[0];
            const endDate = filterDates[1];

            const memoUploadDate = new Date(memo.dateOfUpload);

            if (startDate) {
                const startOfDay = new Date(startDate);
                startOfDay.setHours(0, 0, 0, 0);

                if (memoUploadDate < startOfDay) {
                    return false;
                }
            }

            if (endDate) {
                const nextDay = new Date(endDate);
                nextDay.setDate(nextDay.getDate() + 1);
                nextDay.setHours(0, 0, 0, 0);

                if (memoUploadDate >= nextDay) {
                    return false;
                }
            }

            return true;
        })
        : [];

    const handleDownloadReport = async (reportId: string) => {
        try {
            const downloadResult = await downloadSingleReport(reportId);

            if (downloadResult) {
                const { fileName, content, mimeType } = downloadResult;

                const blob = new Blob([content], { type: mimeType });
                const url = URL.createObjectURL(blob);

                const a = document.createElement("a");
                a.href = url;
                a.download = fileName;
                a.click();

                URL.revokeObjectURL(url);
            } else {
                console.error("Error downloading report: No download result");
            }
        } catch (error) {
            console.error("Error downloading report:", error);
        }
    };
    const handleDownloadAllReports = async () => {
        try {
            const zip = new JSZip();

            for (const memo of filteredCreditMemos) {
                const downloadResult = await downloadSingleReport(memo.reportId);

                if (downloadResult) {
                    const { content, fileName } = downloadResult;
                    zip.file(fileName, content, { binary: true });
                } else {
                    console.error("Error downloading report: No download result");
                }
            }

            const zipContent = await zip.generateAsync({ type: "blob" });
            const url = URL.createObjectURL(zipContent);
            const a = document.createElement("a");
            a.href = url;
            a.download = "credit-memos.zip";
            a.click();

            URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading reports:", error);
        }
    };

    const truncateFileName = (reportName:any) => {
        return reportName?.length > 40 ? `${reportName.substring(0, 40)}...` : reportName;
    };

    return (
        <>
            <div className="lg:container mx-auto mt-10">
                <div className="w-full px-4 mt-10 ">
                    <p className="font-bold  text-lg">Credit Memos</p>
                    <div className="flex w-full justify-between mb-4  flex-col self-end ">
                        <div className="flex items-center self-end">
                            <button
                                onClick={handleDownloadAllReports}
                                className={`flex mr-4 items-center w-fit px-4 py-2 ${
                                    isDarkMode
                                        ? "bg-gray-800 border-white text-white"
                                        : "bg-white border-gray-300 text-black dark:bg-gray-800 dark:border-white dark:text-white"
                                } border rounded-md text-sm font-medium hover:bg-black hover:text-white`}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke={isDarkMode ? "white" : "currentColor"}
                                    className="w-6 h-6 mr-2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                    />
                                </svg>
                                {t("credit-memos.download")}
                            </button>
                            <DateFilter
                                filterDates={filterDates}
                                handleDateFilter={handleDateFilter}
                                clearDateFilter={clearDateFilter}
                                openCalendar={openCalendar}
                                closeCalendar={closeCalendar}
                                showCalendar={showCalendar}
                                addMargin={addMargin}
                            />
                            {filterDates[0] && filterDates[1] && (
                                <button
                                    className="ml-2 text-red-700 focus:outline-none flex items-center"
                                    onClick={clearDateFilter}
                                >
                                    <XCircleIcon className="w-5 h-5 mr-1" />
                                    {t("credit-memos.clear-dates")}
                                </button>
                            )}
                        </div>
                        <div className="flex w-full items-center">
                            {fetchState === FetchState.LOADING ? (
                                <p>Loading...</p>
                            ) : fetchState === FetchState.ERROR ? (
                                <p>Error fetching data.</p>
                            ) : (
                                <div className="overflow-x-auto w-full mt-8">
                                    <Table hoverable className="border-0 shadow-sm">
                                        <Table.Head>
                                            <Table.HeadCell className="p-4"></Table.HeadCell>
                                            <Table.HeadCell>{t("credit-memos.attachment")}</Table.HeadCell>
                                            <Table.HeadCell>Month</Table.HeadCell>
                                            <Table.HeadCell>File Name</Table.HeadCell>
                                            <Table.HeadCell>{t("credit-memos.date-of-upload")}</Table.HeadCell>
                                        </Table.Head>
                                        <Table.Body className="divide-y">
                                            {filteredCreditMemos.length === 0 ? (
                                                <Table.Row>
                                                    <Table.Cell className="p-4 text-center" colSpan={4}>
                                                        {t("credit-memos.no-credit-memos-found")}
                                                    </Table.Cell>
                                                </Table.Row>
                                            ) : (
                                                filteredCreditMemos?.map((memo: any, index: number) => (
                                                    <Table.Row
                                                        key={index}
                                                        className="bg-white dark:border-gray-700 dark:bg-gray-800 cursor-pointer"
                                                    >
                                                        <Table.Cell className="p-4"></Table.Cell>
                                                        <Table.Cell>
                                                            {memo?.reportFileContent ? (
                                                                <button
                                                                    onClick={() => handleDownloadReport(memo.reportId)}
                                                                    className={`flex mr-4 items-center w-fit px-4 py-2 ${
                                                                        isDarkMode
                                                                            ? "bg-gray-800 border-white text-white"
                                                                            : "bg-white border-gray-300 text-black dark:bg-gray-800 dark:border-white dark:text-white"
                                                                    } border rounded-md text-sm font-medium hover:bg-black hover:text-white`}
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth={1.5}
                                                                        stroke={isDarkMode ? "white" : "currentColor"}
                                                                        className="w-6 h-6 mr-2"
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M12 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                                                        />
                                                                    </svg>
                                                                    {t("credit-memos.download-attachment")}
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    disabled
                                                                    className={`flex mr-4 items-center w-fit px-4 py-2 ${
                                                                        isDarkMode
                                                                            ? "bg-gray-800 border-white text-white"
                                                                            : "bg-white border-gray-300 text-black dark:bg-gray-800 dark:border-white dark:text-white"
                                                                    } border rounded-md text-sm font-medium hover:bg-black hover:text-white`}
                                                                >
                                                                    {t("credit-memos.no-report")}
                                                                </button>
                                                            )}
                                                        </Table.Cell>
                                                        <Table.Cell>{memo?.month}</Table.Cell>
                                                        <Table.Cell>{truncateFileName(memo?.reportName)}</Table.Cell> {/* Update this line */}
                                                        <Table.Cell>{memo?.dateOfUpload}</Table.Cell>
                                                    </Table.Row>
                                                ))
                                            )}
                                        </Table.Body>
                                    </Table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreditMemos;