import React, { useEffect, useRef } from "react";
import TableComponent from "../../Table";
import { Card, Button, TextInput } from "flowbite-react";
import { MagnifyingGlassIcon, PlusIcon } from "@heroicons/react/20/solid";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import "../../../App.css";
import {getRecallByVINData} from "../../../lib/api-hooks";

export default function VINSearch() {
    const params = queryString.parse(location.search);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isDarkMode = false;
    const [recalls, fetchState, fetchRecalls] = getRecallByVINData(params.vin?.toString() || "");
    const brand = recalls[0]?.vehicle.brand;
    const model = recalls[0]?.vehicle.model;
    const modelYear = recalls[0]?.vehicle.modelYear;
    const mileage = recalls[0]?.vehicle.mileage;
    const recallDescription = recalls[0]?.recallDescription;
    const validationSchema = Yup.object().shape({
        vin: Yup.string()
            .test(
                "len",
                "VIN must be 17 characters.",
                (val) => typeof val === "string" && val !== "" && val.length === 17
            )
            .required("This field is required!"),
    });

    const formRef = useRef<HTMLFormElement | null>(null);
    const searchButtonRef = useRef<HTMLButtonElement | null>(null);

    const handleSearch = () => {
        searchButtonRef.current?.click(); // Trigger the click event of the hidden button
    };

    const onSubmit = (values: { vin: string }) => {
        navigate(`/vin-search/?vin=${values.vin}`);
    };

    const {
        values,
        handleBlur,
        handleChange,
        handleSubmit,
        errors,
        touched,
    } = useFormik({
        initialValues: { vin: params.vin?.toString() || "" },
        validationSchema: validationSchema,
        onSubmit,
    });

    useEffect(() => {
        fetchRecalls();
        onSubmit(values); // Fetch the recalls when VIN is initially present in params
    }, [fetchRecalls]);

    return (
        <Card className="lg:container container mx-auto mt-10">
            <div className="grid grid-cols-2">
                <div>
                    <h5 className="inline text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        {t("vin-search.recalls-search-page")}
                    </h5>
                    <p className="text-sm font-light text-gray-700 dark:text-gray-400">
                        {t("vin-search.keep-track")}
                    </p>
                </div>
            </div>
            <div className="flex">
                <div className="flex justify-between w-full">
                <div className="relative w-full lg:w-1/2">
                    <form ref={formRef} onSubmit={handleSubmit} autoComplete="off">
                        <TextInput
                            shadow
                            id="vin"
                            placeholder={String(t("vin-search.enter-vin"))}
                            sizing="md"
                            type="text"
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.vin || ""}
                            className={errors.vin && touched.vin ? "text-red-500" : ""}
                        />
                        <button type="submit" ref={searchButtonRef} style={{ display: "none" }} />
                    </form>
                    <MagnifyingGlassIcon
                        className="absolute search-button p-1 h-8 w-8 top-1/2 right-2 transform -translate-y-1/2 text-gray-400 cursor-pointer"
                        onClick={handleSearch} // Call the handleSearch function when the search icon is clicked
                    />
                </div>
                    <div className="flex">
                <div>
                    <Link
                        to={{
                            pathname: "/create-claim",
                            search: `?vin=${encodeURIComponent(values.vin || "")}&brand=${encodeURIComponent(brand || "")}&model=${encodeURIComponent(model || "")}&modelYear=${encodeURIComponent(modelYear || "")}&mileage=${encodeURIComponent(mileage ? mileage.toString() : "")}&recallDescription=${encodeURIComponent(recallDescription || "")}`,
                        }}
                        className={`flex mr-4 items-center w-fit px-4 py-2 ${
                        isDarkMode
                            ? "bg-gray-800 border-white text-white "
                            : "bg-black border-gray-300 text-white dark:bg-white dark:border-gray-500 dark:text-black"
                    } border rounded-md text-sm font-medium hover:bg-gray-500  hover:text-white`}
                    >
                    <PlusIcon className="w-4 h-5 mt-1 mr-1" />{" "}
                    {t("vin-search.create-claim")}
                </Link>
                </div>
                <Link to="/claims"
                      className={`flex mr-4 items-center w-fit px-4 py-2 ${
                          isDarkMode
                              ? "bg-gray-800 border-white text-white"
                              : "bg-white border-gray-300 text-black dark:bg-gray-800 dark:border-white dark:text-white"
                      } border rounded-md text-sm font-medium hover:bg-gray-500 hover:text-white`}
                >
                    {t("vin-search.view-claims")}

                </Link></div></div>
            </div>

            <div>
                {errors.vin && touched.vin && (
                    <p className="text-red-500 text-sm">{errors.vin}</p>
                )}
            </div>
            <div>
                <p className="text-lg sm:text-sm font-bold text-gray-700 dark:text-gray-400">
                    VIN{" "}
                    <span className="text-lg sm:text-sm font-light ml-1">
            {params.vin}
          </span>
                </p>
            </div>
            {params.vin && <TableComponent vin={params.vin.toString()} />}
        </Card>
    );
}
