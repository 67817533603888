import React, { ChangeEvent } from "react";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { UsedPart } from "../../../types";
import "../../../App.css";

interface UsedPartComponentProps {
    part: UsedPart;
    partIndex: number;
    handlePartNoChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, partIndex: number) => void;
    handleCountChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, partIndex: number) => void;
    handleRemovePart: (partIndex: number) => void;
}

export const UsedPartComponent: React.FC<UsedPartComponentProps> = ({
                                                                        part,
                                                                        partIndex,
                                                                        handlePartNoChange,
                                                                        handleCountChange,
                                                                        handleRemovePart,
                                                                    }) => {
    return (
        <div className="col-span-2 grid grid-cols-2 gap-4 mt-3">
            <div className="flex justify-between col-span-3">
                <h3 className="text-xl mb-1">Used Parts {partIndex + 1}</h3>
                <button
                    onClick={() => handleRemovePart(partIndex)}
                    className="text-red-500"
                >
                    <XCircleIcon className="w-5 h-5 mr-1" />
                </button>
            </div>
            <div className="col-span-2">
                <label
                    htmlFor={`partNo_${partIndex}`}
                    className="block mb-1 text-gray-700 text-xs dark:text-white"
                >
                    Part Number
                </label>
                <input
                    type="text"
                    id={`partNo_${partIndex}`}
                    name="partNo"
                    value={part.part.partNo}
                    onChange={(e) => handlePartNoChange(e, partIndex)}
                    className="w-full pr-1 border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md dark:text-white dark:bg-gray-900"
                />
            </div>
            <div className="col-span-1">
                <label
                    htmlFor={`count_${partIndex}`}
                    className="block mb-1 text-gray-700 text-xs dark:text-white"
                >
                    Quantity
                </label>
                <input
                    type="number"
                    id={`count_${partIndex}`}
                    name="count"
                    value={part.count}
                    onChange={(e) => handleCountChange(e, partIndex)}
                    className="w-full border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md dark:text-white dark:bg-gray-900"
                />
            </div>
            {/*<div className="col-span-3">*/}
            {/*    <label*/}
            {/*        htmlFor={`partDescription_${partIndex}`}*/}
            {/*        className="block mb-1 text-gray-700 text-xs"*/}
            {/*    >*/}
            {/*        Part Description*/}
            {/*    </label>*/}
            {/*    <textarea*/}
            {/*        id={`partDescription_${partIndex}`}*/}
            {/*        name="partDescription"*/}
            {/*        value={part.partDescription}*/}
            {/*        onChange={(e) => handlePartInputChange(e, partIndex)}*/}
            {/*        className="w-full border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"*/}
            {/*    />*/}
            {/*</div>*/}
        </div>
    );
};
