//
// import axios from "axios";
//
// // Create a new instance of axios
// const api = axios.create({
//     baseURL: "https://dev-env-aec-recall-api-experiment.azuremicroservices.io/",
//     timeout: 5000,
// });
//
// // Set the default Authorization header from local storage
// api.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('bearerToken')}`;
//
// export default api;


import axios from "axios";

// Create a new instance of axios
const api = axios.create({
    baseURL: process.env.REACT_APP_API_HOST_URL,
    timeout: 5000,
});

// Add a request interceptor to update the Authorization header
api.interceptors.request.use(
    config => {
        const jwt = localStorage.getItem("bearerToken");
        if (jwt) {
            config.headers["Authorization"] = `Bearer ${jwt}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default api;


