import React, { ChangeEvent } from "react";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { OrderPart} from "../../../types";
import "../../../App.css";


interface PartComponentProps {
    part: OrderPart; // Ensure this matches your `Part` type definition
    partIndex: number;
    recallIndex: number;
    handlePartInputChange: (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        recallIndex: number,
        partIndex: number
    ) => void;
    handleRemovePart: (recallIndex: number, partIndex: number) => void;
    vin: string;
}


export const PartComponent: React.FC<PartComponentProps> = ({
                                                                part,
                                                                partIndex,
                                                                recallIndex,
                                                                handlePartInputChange,
                                                                handleRemovePart,

                                                                vin,
                                                            }) => {
    return (
        <div className="col-span-2 grid grid-cols-2 gap-4 mt-3">
            <div className="flex justify-between col-span-3">
                <h3 className="text-xl mb-1">Order Parts {partIndex + 1}</h3>
                <button onClick={() => handleRemovePart(recallIndex, partIndex)} className="text-red-500">
                    <XCircleIcon className="w-5 h-5 mr-1" />
                </button>
            </div>
            <div className="col-span-2">
                <label
                    htmlFor={`partNumber_${recallIndex}_${partIndex}`}
                    className="block mb-1 text-gray-700 text-xs dark:text-white"
                >
                    Part Number
                </label>
                <input
                    type="text"
                    id={`partNo_${recallIndex}_${partIndex}`}
                    name="partNo"
                    required={true}
                    value={part.part.partNo}
                    onChange={(e) => handlePartInputChange(e, recallIndex, partIndex)}
                    className="w-full pr-1 border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md dark:text-white dark:bg-gray-900"
                />
            </div>
            <div className="col-span-1">
                <label
                    htmlFor={`quantity_${recallIndex}_${partIndex}`}
                    className="block mb-1 text-gray-700 text-xs dark:text-white"
                >
                    Quantity
                </label>
                <input
                    type="number"
                    id={`count_${recallIndex}_${partIndex}`}
                    name="count"
                    value={part.count}
                    required={true}
                    onChange={(e) => handlePartInputChange(e, recallIndex, partIndex)}
                    className="w-full border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md dark:text-white dark:bg-gray-900"
                />
            </div>
        </div>
    );
};
