import React, { useState, useEffect } from "react";
import { Table } from "flowbite-react";
import "../../../App.css";
import { useFetchClaims } from "../../../lib/api-hooks";
import { FetchState } from "../../../types";
import {EnvelopeIcon, XCircleIcon} from "@heroicons/react/20/solid";
import DateFilter from "../../Layout/DateFilterComponent";
import StatusFilter from "../../Layout/StatusFilterComponent";
import { Claim } from "../../../types";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

export default function ClaimsComponent() {
    const [filterStatus, setFilterStatus] = useState("");
    const [filterDates, setFilterDates] = useState<(Date | null)[]>([null, null]);

    // Retrieve the dealerId from local storage
    const userDataString = localStorage.getItem("userData");
    const userData = userDataString ? JSON.parse(userDataString) : null;
    const dealerId = userData?.username || "Default Dealer User Name";

    // Use the useFetchClaims hook with the retrieved dealerId
    const { claims, fetchState } = useFetchClaims(dealerId);

    const [showStatusOptions, setShowStatusOptions] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        if (fetchState === FetchState.ERROR) {
            console.error("Error fetching claims.");
        }
    }, [fetchState]);


    const handleClaimRowClick = (claim: Claim) => {
        // Check if the claim is editable (status is "DRAFT" or "PARTSREQUIRED")
        if (claim.claimStatus === "DRAFT") {
            // Navigate to the CreateClaimComponent with pre-filled data
            navigate("/create-claim", { state: { claimData: claim } });
        } else if (claim.claimStatus === "PARTSORDERED") {
            // Navigate to the ReimbursementComponent
            navigate("/reimbursement", { state: { claimData: claim } });

        } else {
            // Navigate to the ClaimInfoComponent for other claims
            navigate(`/claim-info/${claim.claimId}`);
        }
    };


    const handleStatusFilter = (status: string) => {
        setFilterStatus(status);
    };

    const handleDateFilter = (ranges: any) => {
        const startDate = ranges.selection.startDate;
        const endDate = ranges.selection.endDate;

        // If the selected start and end dates are the same, increment the end date by one day
        if (startDate && startDate.getTime() === endDate.getTime()) {
            endDate.setDate(endDate.getDate() + 1);
        }

        setFilterDates([startDate, endDate]);
    };

    const clearDateFilter = () => {
        setFilterDates([null, null]);
    };

    const openCalendar = () => {
        setShowCalendar(true);
    };

    const closeCalendar = () => {
        setShowCalendar(false);
    };

    const filteredClaims = claims && claims.filter((claim: Claim) => {
        if (filterStatus && claim.claimStatus !== filterStatus) {
            return false;
        }

        const startDate = filterDates[0];
        const endDate = filterDates[1];

        if (
            startDate &&
            new Date(claim.creationDate)?.getTime() < startDate.getTime()
        ) {
            return false;
        }

        if (
            endDate &&
            new Date(claim.creationDate)?.getTime() >= endDate.getTime() // Use >= here
        ) {
            return false;
    }
    const vinMatch = claim.recalls[0]?.recallId.vin.includes(searchTerm);
    const dealerNameMatch = claim.dealer?.dealerName.toLowerCase().includes(searchTerm.toLowerCase());

    if (searchTerm && !vinMatch && !dealerNameMatch) {
        return false;
    }

    return true;
});

if (fetchState === FetchState.LOADING) {
        return <div>Loading claims...</div>;
    }

// Sort filteredClaims in descending order based on claimId
    if(filteredClaims) {
        filteredClaims.sort((a, b) => {
            const lastModifiedDateA = new Date(a.lastModifiedDate);
            const lastModifiedDateB = new Date(b.lastModifiedDate);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return lastModifiedDateB - lastModifiedDateA;
        });
    }

    return (
        <div className="lg:container container mx-auto mt-10">
            <div className="flex justify-between mb-4">
                <div className="flex items-center">
                    <StatusFilter
                        filterStatus={filterStatus}
                        handleStatusFilter={handleStatusFilter}
                        showStatusOptions={showStatusOptions}
                        setShowStatusOptions={setShowStatusOptions}
                    />
                </div>

                <div className="flex items-start  w-3/4">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder={String(t("credit-memos.search-by-dealer-vin"))}
                        className="border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md px-4 py-2 w-80 w-full dark:text-white dark:bg-gray-900"
                    />
                </div>
                <div className="flex items-center">
                    <DateFilter
                        filterDates={filterDates}
                        handleDateFilter={handleDateFilter}
                        clearDateFilter={clearDateFilter}
                        openCalendar={openCalendar}
                        closeCalendar={closeCalendar}
                        showCalendar={showCalendar}
                    />
                    {filterDates[0] && filterDates[1] && (
                        <button
                            className="ml-2 text-red-700 focus:outline-none flex items-center"
                            onClick={clearDateFilter}
                        >
                            <XCircleIcon className="w-5 h-5 mr-1" />
                            Clear dates
                        </button>
                    )}
                </div>
            </div>
            <div className="overflow-x-auto">
                <Table hoverable className="border-0 shadow-sm">
                    <Table.Head>
                        <Table.HeadCell className="!p-4"></Table.HeadCell>
                        <Table.HeadCell>{t("table.claim")} ID</Table.HeadCell>
                        <Table.HeadCell>Custom {t("table.claim")} ID</Table.HeadCell>
                        <Table.HeadCell>{t("table.recall")} ID</Table.HeadCell>
                        <Table.HeadCell>{t("order-parts.dealer-name")}</Table.HeadCell>
                        <Table.HeadCell>VIN</Table.HeadCell>
                        <Table.HeadCell>{t("table.creation-date")}</Table.HeadCell>
                        <Table.HeadCell>{t("table.last-modified-date")}</Table.HeadCell>
                        <Table.HeadCell>{t("table.status")}</Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {filteredClaims.length === 0 ? (
                            <Table.Row>
                                <Table.Cell colSpan={9}>No claims found.</Table.Cell>
                            </Table.Row>
                        ) : (
                            filteredClaims.map((claim: Claim) => (
                                <Table.Row
                                    key={claim.claimId}
                                    onClick={() => handleClaimRowClick(claim)} // Pass the claim object to the handleClaimRowClick function
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                    <Table.Cell className="!p-4"></Table.Cell>
                                    <Table.Cell>{claim.claimId}</Table.Cell>
                                    <Table.Cell>{claim.customClaimId}</Table.Cell>
                                    <Table.Cell>{claim.recalls[0]?.recallId.recallId}</Table.Cell>
                                    <Table.Cell>{claim.dealer ? claim.dealer.dealerName : "N/A"}</Table.Cell>
                                    <Table.Cell>
                                        {claim.recalls[0] && claim.recalls[0].recallId.vin}
                                    </Table.Cell>
                                    <Table.Cell>{claim.creationDate}</Table.Cell>
                                    <Table.Cell>{claim.lastModifiedDate}</Table.Cell>
                                    <Table.Cell>
                                        {claim.claimStatus === "OPEN" ? (
                                            <span className="inline-flex items-center justify-center bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300 w-22">
      <span className="w-2 h-2 mr-2 bg-green-500 rounded-full"></span>
      Open
    </span>
                                        ) : claim.claimStatus === "INPROGRESS" ? (
                                            <span className="inline-flex items-center justify-center bg-purple-100 text-purple-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-purple-300 w-22">
      <span className="w-2 h-2 mr-2 bg-purple-500 rounded-full"></span>
      In Progress
    </span>
                                        ) : claim.claimStatus === "CLOSED" ? (
                                            <span className="inline-flex items-center justify-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300 w-22">
      <span className="w-2 h-2 mr-2 bg-red-500 rounded-full"></span>
      Closed
    </span>
                                        ) : claim.claimStatus === "DRAFT" ? (
                                            <span className="inline-flex items-center justify-center bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300 w-22">
      <span className="w-2 h-2 mr-2 bg-blue-500 rounded-full"></span>
      Draft
    </span>
                                        ) : claim.claimStatus === "PARTSREQUIRED" ? (
                                                <span className="inline-flex items-center justify-center bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300 w-22">
      <span className="w-2 h-2 mr-2 bg-yellow-500 rounded-full"></span>
      Parts Required
    </span>
                                            )
                                            : claim.claimStatus === "PARTSORDERED" ? (
                                                <span className="inline-flex items-center justify-center bg-orange-100 text-orange-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300 w-22">
      <span className="w-2 h-2 mr-2 bg-yellow-500 rounded-full"></span>
      Parts Ordered
    </span>
                                            ): null}
                                        {claim.communication && claim.communication.length > 0 && claim.claimStatus !== "CLOSED" ? (
                                            <span className="text-blue-500 ml-6 float-right">
                                                <EnvelopeIcon className="w-5 h-5" />
                                             </span>
                                        ) : null}
                                    </Table.Cell>
                                </Table.Row>
                            ))
                        )}
                    </Table.Body>
                </Table>
            </div>
        </div>
    );
}


