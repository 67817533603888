import React, {useState, useEffect} from "react";
import {Table} from "flowbite-react";
import "../../../App.css";
import {downloadClaims, useFetchClaims,downloadReportTSO} from "../../../lib/api-hooks";
import { FetchState } from "../../../types";
import {EnvelopeIcon, XCircleIcon} from "@heroicons/react/20/solid";
import DateFilter from "../../Layout/DateFilterComponent";
import StatusFilter from "../../Layout/StatusFilterComponent";
import { Claim } from "../../../types";
import {useNavigate} from "react-router-dom";
import {t} from "i18next";


export default function TSOClaimsTable() {
    const [filterStatus, setFilterStatus] = useState("");
    const [filterDates, setFilterDates] = useState<(Date | null)[]>([null, null]);
    const { claims, fetchState } = useFetchClaims();
    const [showStatusOptions, setShowStatusOptions] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const isDarkMode = false;

    useEffect(() => {
        if (fetchState === FetchState.ERROR) {
            console.error("Error fetching claims.");
        }
    }, [fetchState]);


    const handleClaimRowClick = (claim: Claim) => {
        navigate(`/tso-claim-view/${claim.claimId}`);
    };

    const handleStatusFilter = (status: string) => {
        setFilterStatus(status);
    };

    const handleDateFilter = (ranges: any) => {
        const startDate = ranges.selection.startDate;
        const endDate = ranges.selection.endDate;
        setFilterDates([startDate, endDate]);
    };

    const clearDateFilter = () => {
        setFilterDates([null, null]);
    };

    const openCalendar = () => {
        setShowCalendar(true);
    };

    const closeCalendar = () => {
        setShowCalendar(false);
    };
    const handleDownloadButtonClick = () => {
        downloadClaims();
    };
    const handleDownloadReport = () =>{
        downloadReportTSO();
    };
    const filteredClaims = claims && claims.filter((claim: Claim) => {
        if (filterStatus && claim.claimStatus !== filterStatus) {
            return false;
        }

        const startDate = filterDates[0];
        const endDate = filterDates[1];

        if (
            startDate &&
            new Date(claim.creationDate)?.getTime() < startDate.getTime()
        ) {
            return false;
        }

        if (
            endDate &&
            new Date(claim.creationDate)?.getTime() > endDate.getTime()
        ) {
            return false;
        }
        const vinMatch = claim.recalls[0]?.recallId.vin.includes(searchTerm);
        const dealerNameMatch = claim.dealer?.dealerName.toLowerCase().includes(searchTerm.toLowerCase());

        if (searchTerm && !vinMatch && !dealerNameMatch) {
            return false;
        }

        return true;
    });

    if (fetchState === FetchState.LOADING) {
        return <div>Loading claims...</div>;
    }

// Sort filteredClaims in descending order based on claimId
    if(filteredClaims) {
        filteredClaims.sort((a, b) => {
            const lastModifiedDateA = new Date(a.lastModifiedDate);
            const lastModifiedDateB = new Date(b.lastModifiedDate);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return lastModifiedDateB - lastModifiedDateA;

        });
    }
    return (
        <div className="lg:container container mx-auto mt-10">
            <div className="flex justify-between mb-4">
                <div className="flex items-center">
                    <StatusFilter
                        filterStatus={filterStatus}
                        handleStatusFilter={handleStatusFilter}
                        showStatusOptions={showStatusOptions}
                        setShowStatusOptions={setShowStatusOptions}
                    />
                </div>
                <div className="flex items-start  w-1/2">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder={String(t("credit-memos.search-by-dealer-vin"))}
                        className="border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md px-4 py-2 w-80 w-full dark:text-white dark:bg-gray-900"
                    />
                </div>
                <div className="flex items-center">
                    <DateFilter
                        filterDates={filterDates}
                        handleDateFilter={handleDateFilter}
                        clearDateFilter={clearDateFilter}
                        openCalendar={openCalendar}
                        closeCalendar={closeCalendar}
                        showCalendar={showCalendar}
                    />
                    {filterDates[0] && filterDates[1] && (
                        <button
                            className="ml-2 text-red-700 focus:outline-none flex items-center"
                            onClick={clearDateFilter}
                        >
                            <XCircleIcon className="w-5 h-5 mr-1" />
                            Clear dates
                        </button>
                    )}
                </div>
                <button
                    className={`flex items-center w-fit px-4 py-2 ${
                        isDarkMode
                            ? "bg-gray-800 border-white text-white"
                            : "bg-white border-gray-300 text-black dark:bg-gray-800 dark:border-white dark:text-white"
                    } border rounded-md text-sm font-medium hover:bg-black hover:text-white`}
                    onClick={handleDownloadButtonClick}
                >

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                         stroke="currentColor" className="w-6 h-6 mr-2">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"/>
                    </svg>
                    Claims Report
                </button>
                <button
                    className={`flex items-center w-fit px-4 py-2 ${
                        isDarkMode
                            ? "bg-gray-800 border-white text-white"
                            : "bg-white border-gray-300 text-black dark:bg-gray-800 dark:border-white dark:text-white"
                    } border rounded-md text-sm font-medium hover:bg-black hover:text-white`}
                    onClick={handleDownloadReport}
                >

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2 ">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z" />
                    </svg>

                    Recalls Report
                </button>
            </div>
            <div className="overflow-x-auto">
                <Table hoverable className="border-0 shadow-sm">
                    <Table.Head>
                        <Table.HeadCell className="!p-4"></Table.HeadCell>
                        <Table.HeadCell>{t("table.claim")} ID</Table.HeadCell>
                        <Table.HeadCell>Custom {t("table.claim")} ID</Table.HeadCell>
                        <Table.HeadCell>{t("order-parts.dealer-name")}</Table.HeadCell>
                        <Table.HeadCell>VIN</Table.HeadCell>
                        <Table.HeadCell>{t("table.creation-date")}</Table.HeadCell>
                        <Table.HeadCell>{t("table.last-modified-date")}</Table.HeadCell>
                        <Table.HeadCell>{t("table.status")}</Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {filteredClaims.length === 0 ? (
                            <Table.Row>
                                <Table.Cell colSpan={9}>No claims found.</Table.Cell>
                            </Table.Row>
                        ) : (
                            filteredClaims.map((claim: Claim) => (
                                <Table.Row
                                    key={claim.claimId}
                                    onClick={() => handleClaimRowClick(claim)} // Pass the claim object to the handleClaimRowClick function
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                    <Table.Cell className="!p-4"></Table.Cell>
                                    <Table.Cell>{claim.claimId}</Table.Cell>
                                    <Table.Cell>{claim.customClaimId}</Table.Cell>
                                    <Table.Cell>{claim.dealer ? claim.dealer.dealerName : "N/A"}</Table.Cell>
                                    <Table.Cell>
                                        {claim.recalls[0] && claim.recalls[0].recallId.vin}
                                    </Table.Cell>
                                    <Table.Cell>{claim.creationDate}</Table.Cell>
                                    <Table.Cell>{claim.lastModifiedDate}</Table.Cell>
                                    <Table.Cell>
                                        {claim.claimStatus === "OPEN" ? (
                                            <span className="inline-flex items-center justify-center bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300 w-22">
      <span className="w-2 h-2 mr-2 bg-green-500 rounded-full"></span>
      Open
    </span>
                                        ) : claim.claimStatus === "INPROGRESS" ? (
                                            <span className="inline-flex items-center justify-center bg-purple-100 text-purple-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-purple-300 w-22">
      <span className="w-2 h-2 mr-2 bg-purple-500 rounded-full"></span>
      In Progress
    </span>
                                        ) : claim.claimStatus === "CLOSED" ? (
                                            <span className="inline-flex items-center justify-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300 w-22">
      <span className="w-2 h-2 mr-2 bg-red-500 rounded-full"></span>
      Closed
    </span>
                                        )  : claim.claimStatus === "PARTSREQUIRED" ? (
                                                <span className="inline-flex items-center justify-center bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300 w-22">
      <span className="w-2 h-2 mr-2 bg-yellow-500 rounded-full"></span>
      Parts Required
    </span>
                                            )
                                            : claim.claimStatus === "PARTSORDERED" ? (
                                                <span className="inline-flex items-center justify-center bg-orange-100 text-orange-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300 w-22">
      <span className="w-2 h-2 mr-2 bg-yellow-500 rounded-full"></span>
      Parts Ordered
    </span>
                                            ): null}
                                        {claim.agentInformation && claim.agentInformation.underReview ? (
                                            <span className="text-red-500 ml-6">🚩</span>
                                        ) : null}
                                        {claim.communication && claim.communication.length > 0 && claim.claimStatus !== "CLOSED" ? (
                                            <span className="text-blue-500 ml-6 float-right">
                                                <EnvelopeIcon className="w-5 h-5" />
                                            </span>
                                        ) : null}
                                    </Table.Cell>
                                </Table.Row>
                            ))
                        )}
                    </Table.Body>
                </Table>
            </div>
        </div>
    );
}



