import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import { PartComponent } from "../PartComponent";
import {Claim, OrderPart, Recall} from "../../../types";
import {InformationCircleIcon, XCircleIcon} from "@heroicons/react/20/solid";
import "../../../App.css";
import { Tooltip } from "flowbite-react";
import {t} from "i18next";
import {getNewRecallByVINData} from "../../../lib/api-hooks";



interface RecallComponentProps {
    // existing properties
    recall: Recall;
    recallIndex: number;
    handleRecallInputChange: (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
        recallIndex: number
    ) => void;
    handleAddPart: (recallIndex: number) => void;
    handleRemoveRecall: (recallIndex: number) => void;
    handlePartInputChange: (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        recallIndex: number,
        partIndex: number
    ) => void;
    handleRemovePart: (recallIndex: number, partIndex: number) => void;
    isFirstRecall: boolean;
    vin: string;
    mileage: string;
    model: string;
    modelYear: string;
    brand: string;
    recallDescription: string;
    lop: string;
    labourCharged: string;
    claimData: Claim | null;
    onLopHourlyListChange: (recallIndex: number, isValid: boolean) => void;
    handleLopAndLabourChargedChange: (recallIndex: number, lop: string, labourCharged: string) => void;
}


export const RecallComponent: React.FC<RecallComponentProps> = ({
                                                                    recall,
                                                                    recallIndex,
                                                                    handleRecallInputChange,
                                                                    handleAddPart,
                                                                    handleRemoveRecall,
                                                                    handlePartInputChange,
                                                                    handleRemovePart,
                                                                    isFirstRecall,
                                                                    vin:initialVin,
                                                                    mileage:initialMileage, // Add mileage prop
                                                                    model: initialModel,
                                                                    brand: initialBrand,
                                                                    modelYear: initialModelYear,
                                                                    recallDescription:initialRecallDescription,
                                                                    lop: initialLop,
                                                                    labourCharged: initiallabourCharged,
                                                                    claimData,
                                                                    handleLopAndLabourChargedChange,

                                                                }) => {
    const [vin, setVin] = useState(initialVin);
    const [model, setModel] = useState(initialModel);
    const [brand, setBrand] = useState(initialBrand);
    const [modelYear, setModelYear] = useState(initialModelYear);
    const [mileage, setMileage] = useState(initialMileage);
    const [recallDescription, setRecallDescription] = useState(initialRecallDescription);
    const [lop, setLop] = useState(initialLop);
    const [labourCharged, setLabourCharged] = useState(initiallabourCharged);
    const [recalls, fetchState, fetchRecalls] = getNewRecallByVINData(vin?.toString() || "");
    const [lopHourlyList, setLopHourlyList] = useState([{ lop: initialLop, labourCharged: initiallabourCharged }]);
    const [concatenatedLopCodes, setConcatenatedLopCodes] = useState("");
    const [totalHourlyAllowance, setTotalHourlyAllowance] = useState(0);
    useEffect(() => {
        const updatedConcatenatedLopCodes = lopHourlyList.map(item => item.lop).join(",");
        const updatedTotalHourlyAllowance = lopHourlyList.reduce((sum, item) => sum + Number(item.labourCharged || 0), 0);
        setLop(updatedConcatenatedLopCodes);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setLabourCharged(updatedTotalHourlyAllowance);
    }, [lopHourlyList]);
    const previousValues = useRef({ lop: initialLop, labourCharged: initiallabourCharged });

    useEffect(() => {
        if (lop !== previousValues.current.lop || labourCharged !== previousValues.current.labourCharged) {
            handleLopAndLabourChargedChange(recallIndex, lop, labourCharged);
            previousValues.current = { lop, labourCharged };
        }
    }, [lop, labourCharged, recallIndex]);
    useEffect(() => {
        console.log(`VIN: ${vin}, Model: ${model}, Brand: ${brand}, Model Year: ${modelYear}, Mileage: ${mileage}, Recall Description: ${recallDescription}, LOP: ${lop}, Labour Charged: ${labourCharged}`);
    }, [vin, model, brand, modelYear, mileage, recallDescription, lop, labourCharged, lopHourlyList, concatenatedLopCodes, totalHourlyAllowance]);

    const removeLopHourly = (indexToRemove: number) => {
        if (lopHourlyList.length > 1) {
            setLopHourlyList(lopHourlyList.filter((_, index) => index !== indexToRemove));
        }
        setLopHourlyList(lopHourlyList.filter((_, index) => index !== indexToRemove));
    };

    const handleLopHourlyChange = (e: { target: { value: any; }; }, index: string | number, field: string | number) => {
        const newList = [...lopHourlyList];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newList[index][field] = e.target.value;
        setLopHourlyList(newList);
    };
    const addLopHourly = () => {
        setLopHourlyList([...lopHourlyList, { lop: "", labourCharged: "" }]);
    };


    const handleVINChange = (e: ChangeEvent<HTMLInputElement>) => {
        setVin(e.target.value);
        handleRecallInputChange(e, recallIndex);
    };
    const handleModelChange = (e: ChangeEvent<HTMLInputElement>) => {
        setModel(e.target.value);
        handleRecallInputChange(e, recallIndex);
    };

    const handleBrandChange = (e: ChangeEvent<HTMLInputElement>) => {
        setBrand(e.target.value);
        handleRecallInputChange(e, recallIndex);
    };
    const handleModelYearChange = (e: ChangeEvent<HTMLInputElement>) => {
        setModelYear(e.target.value);
        handleRecallInputChange(e, recallIndex);
    };
    const handleMileageChange = (e: ChangeEvent<HTMLInputElement>) => {
        setMileage(e.target.value);
        handleRecallInputChange(e, recallIndex);
    };
    const handleRecallDescriptionChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRecallDescription(e.target.value);
        handleRecallInputChange(e, recallIndex);
    };

    const handleLopChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newLop = e.target.value;
        console.log("Updated LOP:", newLop);
        setLop(newLop);
        handleRecallInputChange(e, recallIndex);
    };


    const handleLabourChargedChange = (e: ChangeEvent<HTMLInputElement>) => {
        setLabourCharged(e.target.value);
        handleRecallInputChange(e, recallIndex);
    };


    useEffect(() => {
        fetchRecalls();
    }, [fetchRecalls]);
    useEffect(() => {
        if (claimData?.claimStatus === "DRAFT" && claimData?.recalls?.length > 0) {
            const firstRecall = claimData.recalls[0];
            setVin(firstRecall.recallId.vin);
            setModelYear(firstRecall.vehicle.modelYear);
            setMileage(firstRecall.vehicle.mileage.toString());
            setModel(firstRecall.vehicle.model);
            setBrand(firstRecall.vehicle.brand);
            setRecallDescription(firstRecall.recallDescription);
        }
        else {
            setVin(initialVin);
            setModelYear(initialModelYear);
            setMileage(initialMileage);
            setModel(initialModel);
            setBrand(initialBrand);
            setRecallDescription(initialRecallDescription);
        }
    }, [
        initialVin, initialModel, initialBrand, initialModelYear, initialMileage,
        initialRecallDescription, claimData
    ]);

    return (
        <div className="mb-4 mt-4 px-4">
            <div className="flex justify-between">
                <h2 className="text-2xl mb-2">Recall {recallIndex + 1}</h2>
                {recallIndex !== 0 && (
                    <button onClick={() => handleRemoveRecall(recallIndex)} className="text-red-500">
                        <XCircleIcon className="w-5 h-5 mr-1" />
                    </button>
                )}
            </div>
            <div className="grid grid-cols-2 gap-4">
                {isFirstRecall && (
                    <>
                        <div className="col-span-1">
                            <label htmlFor={`dealerName_${recallIndex}`} className="block mb-1 text-gray-700 text-xs dark:text-white">
                                {t("order-parts.dealer-name")}
                            </label>
                            <input
                                type="text"
                                id={`dealerName_${recallIndex}`}
                                name="dealerName"
                                value={recall.dealerName}
                                onChange={(e) => handleRecallInputChange(e, recallIndex)}
                                className="w-full border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md dark:text-white dark:bg-gray-900"
                            />
                        </div>
                        <div className="col-span-1">
                            <label htmlFor={`dealerAddress_${recallIndex}`} className="block mb-1 text-gray-700 text-xs dark:text-white">
                                {t("order-parts.dealer-address")}
                            </label>
                            <input
                                type="text"
                                id={`dealerAddress_${recallIndex}`}
                                name="dealerAddress"
                                value={recall.address}
                                onChange={(e) => handleRecallInputChange(e, recallIndex)}
                                className="w-full border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md dark:text-white dark:bg-gray-900"
                            />
                        </div>

                        <div className="col-span-1">
                            <label htmlFor={`vin_${recallIndex}`} className="block mb-1 text-gray-700 text-xs dark:text-white">
                                {t("claim-info.vin-number")}

                            </label>
                            <input
                                type="text"
                                id={`vin_${recallIndex}`}
                                name="vin"
                                value={vin}
                                required={true}
                                onChange={handleVINChange}
                                className="w-full border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md dark:text-white dark:bg-gray-900"
                            />
                        </div>

                        <div className="col-span-1">
                            <label htmlFor={`modelYear_${recallIndex}`} className="block mb-1 text-gray-700 text-xs dark:text-white">
                                {t("claim-info.model-year")}
                            </label>
                            <input
                                type="text"
                                id={`modelYear_${recallIndex}`}
                                name="modelYear"
                                value={modelYear}
                                required={true}
                                onChange={handleModelYearChange}
                                className="w-full border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md dark:text-white dark:bg-gray-900"
                            />
                        </div>

                        <div className="col-span-1 relative">
                            <label htmlFor={`mileage_${recallIndex}`} className="block mb-1 text-gray-700 text-xs dark:text-white">
                                {t("claim-info.millage")} (KM)
                            </label>
                            <input
                                type="text"
                                id={`mileage_${recallIndex}`}
                                name="mileage"
                                value={mileage} // Use the mileage prop here
                                required={true}
                                onChange={handleMileageChange}
                                className="w-full border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md dark:text-white dark:bg-gray-900"
                            />
                            <div className="text-gray-500 top-7 right-2 absolute">
                                <Tooltip className="text-center tooltip-content-wide"  content="In km">
                                    <InformationCircleIcon className="w-5 h-5 mr-1" />
                                </Tooltip>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <label htmlFor={`model_${recallIndex}`} className="block mb-1 text-gray-700 text-xs dark:text-white">
                                {t("claim-info.model")}
                            </label>
                            <input
                                type="text"
                                id={`model_${recallIndex}`}
                                name="model"
                                value={model} // Use the model prop here
                                required={true}
                                onChange={handleModelChange}
                                className="w-full border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md dark:text-white dark:bg-gray-900"
                            />
                        </div>
                        <div className="col-span-1">
                            <label htmlFor={`brand_${recallIndex}`} className="block mb-1 text-gray-700 text-xs dark:text-white">
                                {t("claim-info.brand-name")}
                            </label>
                            <input
                                type="text"
                                id={`brand_${recallIndex}`}
                                name="brand"
                                value={brand}
                                required={true}
                                onChange={handleBrandChange}
                                className="w-full border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md dark:text-white dark:bg-gray-900"
                            />
                        </div>


                    </>
                )}
                <div className="col-span-1 relative">
                    <label htmlFor={`recallID_${recallIndex}`} className="block mb-1 text-gray-700 text-xs dark:text-white">
                        {t("table.recall")} ID
                    </label>
                    {claimData?.claimStatus === "DRAFT" && claimData?.recalls?.length > 0 ? (
                        <input
                            type="text"
                            id={`recallID_${recallIndex}`}
                            name="recallId.recallId"
                            value={recall.recallId.recallId}
                            readOnly
                            className="w-full border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md dark:text-white dark:bg-gray-900"
                        />
                    ) : (
                        <select
                            id={`recallID_${recallIndex}`}
                            name={"recallId.recallId"}
                            value={recall.recallId.recallId}
                            required={true}
                            onChange={(e) => handleRecallInputChange(e, recallIndex)}
                            className="w-full border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md dark:text-white dark:bg-gray-900"
                        >
                            <option value="">Select Recall ID</option>
                            {recalls.map((recallItem, index) => (
                                <option key={index} value={recallItem.recallId.recallId}>
                                    {recallItem.recallId.recallId}
                                </option>
                            ))}
                        </select>
                    )}
                </div>
                <div className="col-span-2 relative">
                    <label htmlFor={`recallDescription_${recallIndex}`} className="block mb-1 text-gray-700 text-xs dark:text-white">
                        {t("claim-info.recall-desc")}
                    </label>
                    <textarea
                        id={`recallDescription_${recallIndex}`}
                        name="recallDescription"
                        value={recall.recallDescription}
                        required={true}
                        onChange={handleRecallDescriptionChange}
                        className="w-full border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md dark:text-white dark:bg-gray-900"
                    />
                    <div className="text-gray-500 top-10 right-2 absolute">
                        <Tooltip className="text-center tooltip-content-wide"  content="Please locate this data in the recall instructions">
                            <InformationCircleIcon className="w-5 h-5 mr-1" />
                        </Tooltip>
                    </div>
                </div>
            </div>
            <div>
                <div className="grid grid-cols-2 gap-4">
                    {lopHourlyList.map((item, index) => (
                        <React.Fragment key={index}>
                            {/* LOP Code Input */}
                            <div className="col-span-1">
                                <label htmlFor={`lop_${index}`} className="block text-sm font-medium text-gray-700">LOP Code</label>
                                <input
                                    type="text"
                                    id={`lop_${index}`}
                                    name={`lop_${index}`}
                                    value={item.lop}
                                    onChange={(e) => handleLopHourlyChange(e, index, "lop")}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>
                            {/* Hourly Allowance Input */}
                            <div className="col-span-1">
                                <div className="flex justify-between">
                                    <label htmlFor={`labourCharged_${index}`} className="block text-sm font-medium text-gray-700">Hourly Allowance</label>
                                    {lopHourlyList.length > 1 && (
                                        <button onClick={() => removeLopHourly(index)} className="text-red-500">
                                            <XCircleIcon className="w-5 h-5 mr-1" />
                                        </button>
                                    )}
                                </div>
                                <input
                                    type="text"
                                    id={`labourCharged_${index}`}
                                    name={`labourCharged_${index}`}
                                    value={item.labourCharged}
                                    onChange={(e) => handleLopHourlyChange(e, index, "labourCharged")}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>
                        </React.Fragment>
                    ))}
                </div>
                <button onClick={addLopHourly} className="mt-3 ...">Add LOP/Hourly Allowance</button>
                <div className=" mt-3 flex">
                    <button onClick={addLopHourly} className=" flex items-center text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="w-3 h-3 mr-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                        Add LOP/Hourly Allowance</button>
                </div>
            </div>
            {recall.order.orderPart.map((part, partIndex) => (
                <PartComponent
                    key={part.id}
                    part={{
                        id: part.id,
                        count: part.count,
                        part: {
                            partNo: part.part.partNo,
                            partDescription: part.part.partDescription,
                        }
                    }}
                    partIndex={partIndex}
                    recallIndex={recallIndex}
                    handlePartInputChange={handlePartInputChange}
                    handleRemovePart={handleRemovePart}
                    vin={recall.vin}
                />
            ))}



            <button
                onClick={() => handleAddPart(recallIndex)}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 mt-5"
            >
                {t("order-parts.orderParts")}
            </button>
        </div>
    );
};