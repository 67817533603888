import React, { useState } from "react";
import { ArrowLongDownIcon, ArrowLongUpIcon } from "@heroicons/react/20/solid";
import { StatusFilterProps } from "../../../types";

const StatusFilter: React.FC<StatusFilterProps> = ({
                                                       filterStatus,
                                                       handleStatusFilter,
                                                       showStatusOptions,
                                                       setShowStatusOptions,
                                                   }) => {
    const userData = JSON.parse(localStorage.getItem("userData") || "{}");
    const userRole = userData.role; // Replace 'role' with the actual key for the user's role in userData

    const handleStatusOptionClick = (status: string) => {
        handleStatusFilter(status);
        setShowStatusOptions(false); // Close the status options
    };

    // Function to render the "Draft" button based on user role
    const renderDraftButton = () => {
        if (userRole === "autoport") {
            return null; // Don't render the button for 'autoport' role
        }
        return (
            <button
                className={`w-full text-left block px-4 py-2 text-sm dark:bg-gray-900 dark:text-blue-500 ${
                    filterStatus === "DRAFT"
                        ? "bg-blue-100 text-blue-800"
                        : "hover:bg-blue-100 hover:text-blue-800"
                }`}
                onClick={() => handleStatusOptionClick("DRAFT")}
            >
        <span className="inline-flex items-center mr-2">
          <span className="w-2 h-2 mr-2 bg-blue-500 rounded-full"></span>
          Draft
        </span>
            </button>
        );
    };

    return (
        <div className="relative inline-block text-left">
            <button
                className="text-white bg-black hover:bg-red-700 focus:ring-1 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={() => setShowStatusOptions(!showStatusOptions)}
            >
                Status
                {showStatusOptions ? (
                    <span className="ml-1">
            <ArrowLongUpIcon className="w-5 h-5" />
          </span>
                ) : (
                    <span className="ml-1">
            <ArrowLongDownIcon className="w-5 h-5" />
          </span>
                )}
            </button>
            {showStatusOptions && (
                <div className="origin-top-right absolute inset-x-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20 dark:bg-gray-900">
                    <div
                        className="py-1 dark:bg-gray-900"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                    >
                        <button
                            className={`w-full text-left block px-4 py-2 text-sm dark:text-gray-500 dark:bg-gray-900 ${
                                filterStatus === ""
                                    ? "bg-gray-100 text-gray-800 "
                                    : "hover:bg-gray-100 hover:text-gray-800"
                            }`}
                            onClick={() => handleStatusOptionClick("")}
                        >
              <span className="inline-flex items-center mr-2">
                <span className="w-2 h-2 mr-2 bg-gray-500 rounded-full"></span>
                All
              </span>
                        </button>
                        <button
                            className={`w-full text-left block px-4 py-2 text-sm dark:text-green-500 dark:bg-gray-900 ${
                                filterStatus === "OPEN"
                                    ? "bg-green-100 text-green-800"
                                    : "hover:bg-green-100 hover:text-green-800"
                            }`}
                            onClick={() => handleStatusOptionClick("OPEN")}
                        >
              <span className="inline-flex items-center mr-2">
                <span className="w-2 h-2 mr-2 bg-green-500 rounded-full"></span>
                Open
              </span>
                        </button>
                        <button
                            className={`w-full text-left block px-4 py-2 text-sm dark:text-red-500 dark:bg-gray-900 ${
                                filterStatus === "CLOSED"
                                    ? "bg-red-100 text-red-800"
                                    : "hover:bg-red-100 hover:text-red-800"
                            }`}
                            onClick={() => handleStatusOptionClick("CLOSED")}
                        >
              <span className="inline-flex items-center mr-2">
                <span className="w-2 h-2 mr-2 bg-red-500 rounded-full"></span>
                Closed
              </span>
                        </button>
                        <button
                            className={`w-full text-left block px-4 py-2 text-sm dark:bg-gray-900 dark:text-red-500 ${
                                filterStatus === "INPROGRESS"
                                    ? "bg-purple-100 text-purple-800"
                                    : "hover:bg-purple-100 hover:text-purple-800"
                            }`}
                            onClick={() => handleStatusOptionClick("INPROGRESS")}
                        >
              <span className="inline-flex items-center mr-2">
                <span className="w-2 h-2 mr-2 bg-purple-500 rounded-full"></span>
                In Progress
              </span>
                        </button>
                        <button
                            className={`w-full text-left block px-4 py-2 text-sm dark:bg-gray-900 dark:text-blue-500 ${
                                filterStatus === "PARTSREQUIRED"
                                    ? "bg-blue-100 text-yellow-800"
                                    : "hover:bg-yellow-100 hover:text-yellow-800"
                            }`}
                            onClick={() => handleStatusOptionClick("PARTSREQUIRED")}
                        >
              <span className="inline-flex items-center mr-2">
                <span className="w-2 h-2 mr-2 bg-yellow-500 rounded-full"></span>
                Parts Required
              </span>
                        </button>
                        <button
                            className={`w-full text-left block px-4 py-2 text-sm dark:bg-gray-900 dark:text-blue-500 ${
                                filterStatus === "PARTSORDERED"
                                    ? "bg-blue-100 text-orange-800"
                                    : "hover:bg-yellow-100 hover:text-orange-800"
                            }`}
                            onClick={() => handleStatusOptionClick("PARTSORDERED")}
                        >
              <span className="inline-flex items-center mr-2">
                <span className="w-2 h-2 mr-2 bg-orange-500 rounded-full"></span>
                Parts Ordered
              </span>
                        </button>

                        {/* Render the Draft button conditionally */}
                        {renderDraftButton()}
                    </div>
                </div>
            )}
        </div>
    );
};

export default StatusFilter;
