import React from "react";
import { useParams } from "react-router-dom";
import  ClaimPagePartOrderTabComponent  from "../../Layout/ClaimPagePartOrderTabComponent";
import  ClaimPageInfoTabComponent  from "../../Layout/ClaimPageInfoTabComponent";
const ClaimInfo = () => {
    return (
        <>
            <div className="container lg:container mx-auto mt-10 dark:bg-gray-800 flex flex-wrap">
                <div className="w-full p-8">
                    <ClaimPageInfoTabComponent/>
                </div>
            </div>
        </>
    );
};
export default ClaimInfo;


