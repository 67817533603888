import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Navbar = (props: any) => {
    const [navbar, setNavbar] = useState(false);

    const { t } = useTranslation();
    // Retrieve user data from local storage
    const userData = JSON.parse(localStorage.getItem("userData") || "{}");
    const userRole = userData.role || ""; // Get the user's role from user data
    const handleLogout = () => {
        // Remove the JWT token from local storage
        localStorage.removeItem("bearerToken");

        // Remove the user data from local storage
        localStorage.removeItem("userData");

        // Redirect to https://autoport24.net/
        window.location.href = "https://autoport24.net/";
    };

    return (
        <nav
            className={`bg-white sticky top-0 p-2 flex justify-evenly shadow-md title-primary w-full grid grid-cols-2 ${
                props.darkMode ? "dark" : ""
            }`}
        >
            <div className="sm:hidden xs:hidden md:hidden lg:flex xl:flex 2xl:flex float-left items-center lg:col-start-1 lg:col-end-2 xl:col-start-1 xl:col-end-2">
                <Link to="/" className="text-white ml-4">
                    <img
                        alt="AEC-logo"
                        src="/assets/images/AEC_LOGO_NEW.png"
                        className="sm:hidden xs:hidden md:hidden lg:w-48 xl:w-48 2xl:w-28 lg:inline xl:inline 2xl:inline"
                    />
                </Link>
            </div>
            <div className="lg:col-start-3 lg:col-end-3 xl:col-start-3 xl:col-end-3 2xl:col-start-3 2xl:col-end-3 lg:flex xl:flex 2xl:flex float-right items-center md:hidden sm:hidden xs:hidden">
                <div className="rounded-lg px-3 py-2 text-black hover:text-red-500 text-sm mr-4 transition duration-500 ease-in-out">
                    <Link to="/" className="rounded-lg px-3 py-2 text-black hover:text-red-500 text-sm mr-4 transition duration-500 ease-in-out">
                        {t("menu.home")}
                    </Link>
                    {userRole === "dealerport" && (
                        <Link to="/claims" className="rounded-lg px-3 py-2 text-black hover:text-red-500 text-sm mr-4 transition duration-500 ease-in-out">
                            {t("vin-search.view-claims")}
                        </Link>
                    )}
                    {userRole === "dealerport" && (
                        <Link to="/vin-search" className="rounded-lg px-3 py-2 text-black hover:text-red-500 text-sm mr-4 transition duration-500 ease-in-out">
                            {t("menu.vin-search")}
                        </Link>
                    )}
                    {userRole === "dealerport" && (
                        <Link to="/credit-memos" className="rounded-lg px-3 py-2 text-black hover:text-red-500 text-sm mr-4 transition duration-500 ease-in-out">
                            {t("menu.credit-memos")}
                        </Link>
                    )}
                    {userRole === "autoport" && (
                        <Link to="/tso-claims-table" className="rounded-lg px-3 py-2 text-black hover:text-red-500 text-sm mr-4 transition duration-500 ease-in-out">
                            {t("menu.tso-claims-table")}
                        </Link>
                    )}
                    {userRole === "autoport" && (
                        <Link to="/tso-credit-memos-page" className="rounded-lg px-3 py-2 text-black hover:text-red-500 text-sm mr-4 transition duration-500 ease-in-out">
                            {t("menu.tso-credit-memos")}
                        </Link>
                    )}

                    {(userRole === "dealerport" || userRole === "autoport") && (

                            <button
                                className="rounded-lg px-3 py-2 text-black hover:text-red-500 text-sm mr-4 transition duration-500 ease-in-out"
                                onClick={handleLogout}
                            >
                                {t("menu.log-out")}
                            </button>

                    )}
                </div>
                <img
                    alt="toggleIcon"
                    src={
                        props.darkMode
                            ? "/assets/images/toggle-dark.png"
                            : "/assets/images/toggle-light.png"
                    }
                    className="pr-4 w-10 items-center transition duration-500 ease-in-out"
                    style={{
                        cursor: "pointer",
                        objectFit: "contain",
                    }}
                    onClick={props.toggleDarkMode}
                ></img>
            </div>
            {!navbar && (
                <div className="lg:hidden xl:hidden 2xl:hidden sm:inline xs:inline md:inline sm:flex xs:flex md:flex items-center sm:col-start-1 sm:col-end-2 xs:col-start-1 xs:col-end-2 md:col-start-1 md:col-end-2">
                    <Link to="/" className="text-white">
                        <img
                            alt="AEC-logo"
                            src="/assets/images/AEC_LOGO_NEW.png"
                            className="sm:inline xs:inline md:inline md:w-56 sm:w-max xs:w-max lg:hidden xl:hidden 2xl:hidden"
                        />
                    </Link>
                </div>
            )}
            <div
                className={`flex-1 lg:hidden xl:hidden 2xl:hidden justify-center sm:col-start-1 sm:col-end-2 xs:col-start-1 xs:col-end-2 md:col-start-1 md:col-end-2 ml-6 ${
                    navbar ? "block" : "hidden"
                }`}
            >
                <ul className="items-center justify-center space-y-4 my-2">
                    <>
                        <li className="rounded-lg py-2 text-black hover:text-red-500 font-medium mr-4 transition duration-500 ease-in-out">
                            <a href="/">{t("menu.home")}</a>
                        </li>
                        <li className="rounded-lg py-2 text-black hover:text-red-500 font-medium mr-4 transition duration-500 ease-in-out">
                            <a href="/vin-search">{t("menu.vin-search")}</a>
                        </li>
                        <li className="rounded-lg py-2 text-black hover:text-red-500 font-medium mr-4 transition duration-500 ease-in-out">
                            <a href="/credit-memos">{t("menu.credit-memos")}</a>
                        </li>
                    </>
                </ul>
            </div>
            <div className="lg:hidden xl:hidden 2xl:hidden sm:items-center md:items-center xs:items-center sm:ml-16 xs:ml-16 md:ml-48 sm:col-start-3 sm:col-end-3 xs:col-start-3 xs:col-end-3 md:col-start-3 md:col-end-3">
                <button
                    className="p-3 text-gray-800 rounded-md outline-none focus:border-gray-400 focus:border"
                    onClick={() => setNavbar(!navbar)}
                >
                    {navbar ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    )}
                </button>
                <img
                    alt="toggleIcon"
                    src={
                        props.darkMode
                            ? "/assets/images/toggle-dark.png"
                            : "/assets/images/toggle-light.png"
                    }
                    className="pb-3 pl-2 w-8 transition duration-500 ease-in-out inline"
                    onClick={props.toggleDarkMode}
                ></img>
            </div>
        </nav>
    );
};

export default Navbar;

