import React, {useEffect, useState} from "react";
import { Table } from "flowbite-react";
import { CreditMemosDetails, FetchState } from "../../../types";
import { useLocation, useParams } from "react-router-dom";
import { TSOCreditMemoDetails } from "../../../lib/api-hooks";
import api from "../../../api"; // Import useParams from React Router
import "../../../App.css";
import {t} from "i18next";
import JSZip from "jszip";

const TSOClaimDetails = () => {
    const isDarkMode = false;
    const { dealerId } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const dealerName = queryParams.get("dealerName");
    const totalReimbursementAmount = parseFloat(
        queryParams.get("totalReimbursementAmount") ?? "0"
    );
    const [isReportSent, setIsReportSent] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const { creditMemoDetails, fetchState } = TSOCreditMemoDetails(); // Call the function

    // Update the state to manage a single file
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [truncatedFileName, setTruncatedFileName] = useState<string>("");

    const [selectedMonth, setSelectedMonth] = useState(""); // State to store the selected month
    const [isMessageVisible, setIsMessageVisible] = useState(true);
    const [filteredCreditMemoDetails, setFilteredCreditMemoDetails] = useState<CreditMemosDetails[]>([]);
    useEffect(() => {
        setFilteredCreditMemoDetails(creditMemoDetails ?? []);
    }, [creditMemoDetails]);
    const handleFilterMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const monthYear = event.target.value;
        setSelectedMonth(monthYear);

        if (!monthYear) {
            setFilteredCreditMemoDetails(creditMemoDetails ?? []);
        } else {
            const [year, month] = monthYear.split("-").map(Number);
            const filteredDetails = creditMemoDetails?.filter((detail) => {
                const dateOfCreation = new Date(detail.dateOfCreation);
                const detailYear = dateOfCreation.getFullYear();
                const detailMonth = dateOfCreation.getMonth() + 1;
                return detailYear === year && detailMonth === month;
            });
            setFilteredCreditMemoDetails(filteredDetails ?? []);
        }
    };

    const closeMessage = () => {
        setIsMessageVisible(false);
    };
    const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const month = event.target.value; // Get the selected month
        setSelectedMonth(month); // Update the selected month in state
    };

    const months = [
        "JANUARY",
        "FEBRUARY",
        "MARCH",
        "APRIL",
        "MAY",
        "JUNE",
        "JULY",
        "AUGUST",
        "SEPTEMBER",
        "OCTOBER",
        "NOVEMBER",
        "DECEMBER",
    ];

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files?.length ? Array.from(event.target.files) : [];
        setSelectedFiles(files);
    };
    const handleUpload = async () => {
        if (selectedFiles.length === 0 || !selectedMonth) {
            console.error("No files selected for upload or month not selected.");
            return;
        }
        const formData = new FormData();
        selectedFiles.forEach((file) => {
            formData.append("multipartFiles", file, file.name); // Note the use of "multipartFiles" to match your backend expectation
        });

        selectedFiles.forEach(file => {
            console.log(`File: ${file.name}, size: ${file.size}`);
        });

        const apiUrl = `/report/upload/${dealerId}/${selectedMonth}`;
        try {
            const response = await api.put(apiUrl, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log("Files upload response:", response.data);
            setIsReportSent(true);

            setTimeout(() => {
                setIsReportSent(false);
            }, 3000);
        } catch (error) {
            console.error("An error occurred while uploading the files:", error);
        }
    };
    
    return (
        <div className="lg:container container mx-auto mt-10 dark:bg-gray-800 flex flex-wrap">
            <p className="font-bold text-xl">{t("credit-memos.credit-memo-details")}</p>
            <div className="flex w-full justify-between mb-4  flex-col self-end ">
                <div className="flex items-center self-end mb-5">
                    <div className="relative flex-grow">
                        <select
                            id="dropdownInformationButton"
                            className={`flex mr-4 items-center w-fit px-5 py-2.5 ${
                                isDarkMode
                                    ? "bg-gray-800 border-white text-white hover:bg-black hover:text-white"
                                    : "bg-white border-gray-300 text-black dark:bg-gray-800 dark:border-white dark:text-white"
                            } border rounded-md text-sm font-medium `}

                            onChange={handleMonthChange}
                            value={selectedMonth}
                            required
                        >
                            <option value="">{t("credit-memos.choose-month")}</option>
                            {months.map((month, index) => (
                                <option key={index} value={month}>
                                    {month}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-span-2 ml-3 flex-grow">
                        <label
                            htmlFor="attachment"
                            className={`flex items-center w-fit px-4 py-2.5 cursor-pointer ${
                                isDarkMode
                                    ? "bg-gray-800 border-white text-white"
                                    : "bg-white border-gray-300 text-black dark:bg-gray-800 dark:border-white dark:text-white"
                            } border rounded-md text-sm font-medium hover:bg-black hover:text-white`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke={isDarkMode ? "white" : "currentColor"}
                                className="w-6 h-6 mr-2"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                />
                            </svg>
                            <label
                                htmlFor="attachment"
                            >
                                Attachment
                            </label>
                            <input
                                type="file"
                                id="attachment"
                                name="attachment"
                                onChange={handleFileChange}
                                className="hidden"
                                multiple // Allow multiple file selection
                            />
                        </label>

                    </div>
                    <button
                        onClick={handleUpload}
                        className={`px-4 py-2.5 m-2 rounded-md hover:bg-blue-700 ${
                            !selectedMonth || !selectedFiles
                                ? "bg-blue-500 text-white cursor-not-allowed"
                                : "bg-blue-700 text-white hover:bg-blue-900"
                        }`}
                        disabled={!selectedMonth || !selectedFiles}
                    >
                        {t("claim-info.save")}
                    </button>
                    {isReportSent && (
                        <div className="overlay">
                            <div className="mb-4 bg-white border-l-4 border-green-500 text-green-700 p-4 text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                <p className="font-bold text-xl">The file is successfully sent</p>
                                <p className="text-lg">
                                    The file was uploaded and has been sent successfully.
                                </p>
                            </div>
                        </div>
                    )}
                </div>

                <div>
                    <div className="overflow-x-auto mt-5">
                        {isMessageVisible && (
                            <div className="dark:text-white dark:bg-gray-900 dark:focus:bg-gray-800 dark:border-gray-600 dark:ring-gray-600 mt-7 mb-7 bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative"
                                 role="alert">
                                <button
                                    className="absolute top-0 right-0 px-2 py-1"
                                    onClick={closeMessage}
                                >
                                    <svg className="fill-current h-6 w-6 text-blue-500" role="button" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 20 20"><title>Close</title><path
                                        d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                                </button>
                                <p className="font-bold">{t("credit-memos.info-msg")}</p>
                                <p className="text-sm">{t("credit-memos.info-long-msg")}</p>
                            </div>
                        )}
                        <div className="mt-5 flex justify-between mb-5 text-lg ">
                            <div>
                            <div className="mt-5 flex">
                                <p>{t("credit-memos.dealer")}: </p>
                                <p className="font-semibold ml-3">{dealerName}</p>
                            </div>
                            <div className="mb-8 flex ">
                                <p>{t("credit-memos.total-amount-reimbursement")}:</p>
                                <p className="font-semibold ml-3">{totalReimbursementAmount}</p>
                            </div></div>
                            <div className="self-center relative month flex-end">
                                <input
                                    type="month"
                                    value={selectedMonth}
                                    onChange={handleFilterMonthChange}
                                    className={`flex mr-4 items-center w-fit px-4 py-2 ${
                                        isDarkMode
                                            ? "bg-gray-800 border-white text-white hover:bg-black hover:text-white"
                                            : "bg-white border-gray-300 text-black dark:bg-gray-800 dark:border-white dark:text-white"
                                    } border rounded-md text-sm font-medium `}
                                />
                        </div>
                        </div>
                        <Table hoverable className="border-0 shadow-sm">
                            <Table.Head>
                                <Table.HeadCell className="!p-4"></Table.HeadCell>
                                <Table.HeadCell className="text-center">VIN</Table.HeadCell>
                                <Table.HeadCell className="text-center">{t("table.recall")} ID</Table.HeadCell>
                                <Table.HeadCell className="text-center">
                                    {t("credit-memos.amount-of-reimbursemenet")}
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center">{t("credit-memos.date-of-creation")}</Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                                {fetchState === FetchState.LOADING ? (
                                    <Table.Row>
                                        <Table.Cell colSpan={9}>Loading...</Table.Cell>
                                    </Table.Row>
                                ) : fetchState === FetchState.ERROR ? (
                                    <Table.Row>
                                        <Table.Cell colSpan={9}>Error fetching data.</Table.Cell>
                                    </Table.Row>
                                ) : filteredCreditMemoDetails.length > 0 ? (
                                    filteredCreditMemoDetails.map((creditMemo: CreditMemosDetails) => (
                                        <Table.Row
                                            key={creditMemo.claimId}
                                            className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                        >
                                            <Table.Cell className="!p-4"></Table.Cell>
                                            <Table.Cell className="p-0 text-center">{creditMemo.vin}</Table.Cell>
                                            <Table.Cell className="p-0 text-center">{creditMemo.recallId}</Table.Cell>
                                            <Table.Cell className="p-0 text-center">
                                                {creditMemo.reimbursementAmount}
                                            </Table.Cell>
                                            <Table.Cell className="p-0 text-center">
                                                {creditMemo.dateOfCreation}
                                            </Table.Cell>
                                        </Table.Row>
                                    ))
                                ) : (
                                    <Table.Row>
                                        <Table.Cell colSpan={9}>No claims found for the selected month.</Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>

                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TSOClaimDetails;
